import GroupRecursiveTicketNotification from "@/components/online/admin/ticketNotifications/dialog/GroupRecursiveTicketNotification.vue"
import eventBus from "@/eventBus";

export default {
    setup() {
    },

    components: {
        GroupRecursiveTicketNotification
    },

    props: {
        dialog: {
            default: false
        },
        group: {
            default: null
        },
        availableGroups: Array
    },

    watch: {
        "$i18n.locale": function () {
            this.headers = [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: '', key: 'data-table-expand' },
                { title: this.$t('datatable.header["Nom du groupe"]'), key: 'label' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ];
        },
    },

    data() {
        return {
            loading: false,
            cardWidth: 800,

            headers: [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: '', key: 'data-table-expand' },
                { title: this.$t('datatable.header["Nom du groupe"]'), key: 'label' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ],
            expanded: [],
            singleExpand: false,
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
        eventBus.$on('groupForNotif', (item) => {
            this.selectGroupForNotif(item)
        })
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 800;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        selectGroupForNotif(item) {
            this.$emit('groupForNotif', item)
            this.$emit('update:dialog', false)
        },
    },
};