<template>
  <div style="margin-top: -5px" class="">
    <DeleteDialog
      v-model:dialog="dialogDelete"
      :title="$t(`admin[&quot;Supprimer un template&quot;]`)"
      :text="
        $t(`admin[&quot;Voulez-vous vraiment supprimer ce template&quot;]`)
      "
      :loading="loadingDelete"
      @confirmDelete="deleteHandler"
    />

    <TicketTemplateFieldDialog
      v-model:dialog="dialogDetail"
      :field="selectedField"
      :isDisabled="isDisabledBtn"
      @updateOneField="updateOneField"
    />

    <TicketTemplateGroupDialog
      v-model:dialog="dialogGroup"
      :group="group"
      :isDisabled="isDisabledBtn"
      @refresh="retrieveSingle"
    />

    <TicketTemplateHideShowDialog
      v-if="!loading"
      v-model:dialog="dialogShowHide"
      :demandeForm="demandeForm"
      :annexeForm="annexeForm"
      :isDisabled="isDisabledBtn"
      @cancel="cancel"
      @updateField="updateField"
    />

    <TicketTemplateFieldOrderDialog
      v-if="!loading"
      v-model:dialog="dialogFieldOrder"
      :demandeForm="demandeForm"
      :annexeForm="annexeForm"
      :isDisabled="isDisabledBtn"
      @cancel="cancel"
      @updateField="updateField"
    />

    <v-row class="bg-brown-lighten-5 pt-2 px-4">
      <v-col class="d-flex align-center flex-wrap">
        <v-icon size="large" class="mr-4" color="teal-darken-1" @click="goBack">
          mdi-backspace
        </v-icon>

        <div v-if="frontInfo != null">
          <v-btn
            color="red"
            class="ma-1 ml-lg-3"
            size="small"
            @click="dialogDelete = !dialogDelete"
            :disabled="loading"
          >
            <v-icon class="mr-1">mdi-delete</v-icon>
            {{ $t('form.btn["Supprimer"]') }}
          </v-btn>
        </div>
      </v-col>
      <v-col class="d-flex justify-lg-end justify-md-end align-center">
        <v-btn
          v-if="frontInfo && !loading"
          size="small"
          color="teal-darken-1"
          @click="enableEdition"
          class="mx-5"
        >
          <div v-if="isDisabledBtn">
            <v-icon size="20" class="mr-1">mdi-pencil </v-icon>
            {{ $t('form.btn["Modifier"]') }}
          </div>
          <div v-else>
            <v-icon size="20" class="mr-1">mdi-close </v-icon>
            {{ $t('form.btn["Annuler"]') }}
          </div>
        </v-btn>
        <v-btn
          size="small"
          color="teal-darken-1"
          :loading="loading"
          :disabled="isDisabledBtn"
          @click="submitHandler"
        >
          <v-icon size="25" class="mr-2">mdi-content-save</v-icon>
          {{ $t('form.btn["Sauvegarder"]') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-3"></v-divider>

    <div class="py-lg-4 px-lg-8 py-md-4 px-md-5 pa-4">
      <v-row no-gutters>
        <v-col cols="8" lg="3" md="5" sm="7" class="d-flex align-center pr-2">
          <h2 v-if="!loading">
            {{ frontInfo == null ? title : frontInfo }}
          </h2>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" class="d-flex">
          <v-btn
            size="small"
            class="ma-2 d-flex"
            color="blue-grey"
            @click="dialogGroup = true"
          >
            <v-icon class="mr-1" v-if="!isDisabledBtn">mdi-pencil</v-icon>
            {{ $t('admin["Groupe"]') }}
          </v-btn>
          <v-btn
            size="small"
            class="ma-2 d-flex"
            color="blue-grey"
            @click="dialogShowHide = true"
          >
            <v-icon class="mr-1" v-if="!isDisabledBtn">mdi-pencil</v-icon>
            {{ $t('admin["Visibilité"]') }}
          </v-btn>
          <v-btn
            size="small"
            class="ma-2 d-flex"
            color="blue-grey"
            @click="dialogFieldOrder = true"
          >
            <v-icon class="mr-1" v-if="!isDisabledBtn">mdi-pencil</v-icon>
            {{ $t('admin["Ordonnancement"]') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-divider></v-divider>

    <div style="" class="ticket__form_height" v-if="!loading">
      <div class="py-lg-1 px-lg-8 py-md-2 px-md-5 pa-4">
        <v-row no-gutters>
          <v-col cols="12" lg="" md="" sm="12">
            <h3 class="font-weight-medium text-body-1 mt-1">
              {{ $t('ticket["Demandeur"]') }}
            </h3>
            <p class="text-caption mb-3 mt-1">
              {{ $t('ticket["Les informations du demandeur"]') }}
            </p>
            <v-row
              class="my-2"
              no-gutters
              v-for="(field, index) in demandeForm"
              :key="index"
            >
              <v-col cols="12" lg="10" md="10">
                <div class="d-flex justify-space-between" v-if="field.visible">
                  <label class="font-weight-medium text-caption">
                    {{ $t(`fields["${field.label}"]`) }}
                    <span v-if="field.properties.required">*</span>
                  </label>
                  <v-icon
                    v-if="!isDisabledBtn"
                    size="small"
                    @click="showDetail(field, index)"
                    >mdi-eye</v-icon
                  >
                </div>

                <v-text-field
                  v-if="field.type == 'text' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-text-field>

                <v-text-field
                  v-if="field.type == 'tel' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  type="tel"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-text-field>

                <v-text-field
                  v-if="field.type == 'email' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  type="email"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-text-field>

                <v-text-field
                  v-if="field.type == 'url' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  type="url"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-text-field>

                <v-text-field
                  v-if="field.type == 'date' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  type="date"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-text-field>

                <v-textarea
                  v-if="field.type == 'textarea' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-textarea>

                <v-select
                  v-if="field.type == 'select' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :multiple="field.properties.multiple"
                  :items="field.values"
                  :item-title="field.properties.title"
                  :item-value="field.properties.value"
                  :return-object="field.properties.object"
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-select>

                <v-checkbox
                  v-if="field.type == 'checkbox' && field.visible"
                  :label="field.properties.text"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-checkbox>

                <v-radio-group
                  v-if="field.type == 'radio' && field.visible"
                  :inline="field.properties.inline"
                  v-model="field.model"
                >
                  <v-radio
                    v-for="(radio, i) in field.values"
                    :key="i"
                    :label="radio.label"
                    :value="radio.value"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="" md="" sm="12">
            <h3 class="font-weight-medium text-body-1 mt-1">
              {{ $t('ticket["Générales"]') }}
            </h3>
            <p class="text-caption mb-3 mt-1">
              {{
                $t(
                  'ticket["Les informations générales nécessaires à la vie du ticket"]'
                )
              }}
            </p>

            <v-row
              class="my-2"
              no-gutters
              v-for="(field, index) in annexeForm"
              :key="index"
            >
              <v-col cols="12" lg="10" md="10">
                <label
                  class="font-weight-medium text-caption"
                  v-if="field.visible"
                >
                  {{ $t(`ticket["${field.label}"]`) }}
                  <span v-if="field.properties.required">*</span>
                </label>

                <div
                  v-if="field.type == 'moods' && field.visible"
                  class="d-flex justify-center border rounded mt-2 px-2 py-1"
                >
                  <v-tooltip
                    v-for="(item, i) in field.values"
                    :key="i"
                    :text="$t(`fields.humor[${i}]`)"
                    location="bottom"
                  >
                    <template v-slot:activator="{ props }">
                      <v-chip
                        v-bind="props"
                        :color="
                          field.model != null && item.value == field.model.value
                            ? 'teal-darken-1'
                            : 'white'
                        "
                        class="ma-1"
                        style="font-size: 30px"
                        size="large"
                        :disabled="isDisabled"
                        @click="updateMoodEmot(field, item)"
                      >
                        {{ item.value }}
                        <v-icon
                          size="18"
                          v-if="
                            field.model != null &&
                            item.value == field.model.value
                          "
                          >mdi-check</v-icon
                        >
                      </v-chip>
                    </template>
                  </v-tooltip>
                </div>

                <v-select
                  v-if="field.type == 'select' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`ticket[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :multiple="field.properties.multiple"
                  :items="field.values"
                  :item-title="field.properties.title"
                  :item-value="field.properties.value"
                  :return-object="field.properties.object"
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                  :menu-props="
                    field.properties.multiple
                      ? null
                      : {
                          closeOnClick: true,
                          closeOnContentClick: true,
                        }
                  "
                >
                  <template v-slot:selection="{ item }">
                    {{
                      field.label == "Tags" || field.label == "tags"
                        ? item.title
                        : $t(`fields["${item.title}"]`)
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <div
                      v-if="field.properties.multiple"
                      class="pl-2 py-1"
                      style="cursor: pointer"
                      @click="
                        setModelValue(
                          field.properties.multiple,
                          field,
                          item.value
                        )
                      "
                    >
                      <v-icon
                        v-if="
                          field.model !== null &&
                          field.model.includes(item.value)
                        "
                        color="primary"
                        class="mr-3"
                      >
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else class="mr-3">
                        mdi-checkbox-blank-outline
                      </v-icon>
                      {{
                        field.label == "Tags" || field.label == "tags"
                          ? item.title
                          : $t(`fields["${item.title}"]`)
                      }}
                    </div>
                    <div v-else>
                      <v-list-item
                        :title="$t(`fields[&quot;${item.title}&quot;]`)"
                        density="compact"
                        @click="
                          setModelValue(
                            field.properties.multiple,
                            field,
                            item.value
                          )
                        "
                      >
                      </v-list-item>
                    </div>
                  </template>
                </v-select>

                <v-textarea
                  v-if="field.type == 'textarea' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <div style="height: 55vh" class="d-flex justify-center align-center" v-else>
      <LoadingComponent />
    </div>
  </div>
</template>

<script src="./js/ticketTemplateBuilder.js"></script>