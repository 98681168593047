<template>
  <div class="variant_width_height d-flex justify-center align-center">
    <v-card class="pa-5" width="400">
      <h2 class="text-center mb-4">{{ $t('auth["Se connecter"]') }}</h2>
      <v-divider class="mb-5"></v-divider>

      <LoginForm />
      <div class="d-flex justify-center mt-4">
        <v-btn
          variant="text"
          size="small"
          color="grey"
          @click="goToPasswordReset"
          >{{ $t('form.btn["Mot de passe oublié"]') }}</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script src="./js/loginView.js"></script>