import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        isDisabled: {
            default: false
        },
        agents: {
            default: () => {
                return  [];
            }
        }
    },

    watch: {
        dialog(val) {
            if (val && !this.isDisabled) this.retrieveAgents();
        },
    },

    data() {
        return {
            cardWidth: 600,
            loading: false,

            search: null,
            agentsEdit: [],
            availableAgents: []
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
        this.agentsEdit = this.agents;
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        identityToString(item) {
            return `${item.lastname} ${item.firstname} - ${item.email}`
        },

        close() {
            this.$emit('update:dialog', false)
        },

        async actionHandler() {
            this.loading = true
            try {
                let sendAgents = [];
                this.agentsEdit.forEach(element => {
                    sendAgents.push(element.id)
                });

                const request = await apiService.put(`/groups/${this.$route.params.id}`, { agents: sendAgents });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, {message: response.message,isError: false,})
                
                this.$emit("refresh")
                this.$emit('update:dialog', false)
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },

        async retrieveAgents() {
            this.loading = true
            try {
                const request = await apiService.get("/agents");
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                response.data.forEach(el => {
                    let removeElementIndex = this.agentsEdit.findIndex(agent => agent.email == el.email);
                    if(removeElementIndex != -1) {
                        this.agentsEdit.splice(removeElementIndex, 1);
                        this.agentsEdit.push(el)
                    }
                })
                this.availableAgents = response.data;
            } catch (error) {
                this.availableAgents = [];
            } finally {
                this.loading = false
            }
        },
    },
};