import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { AVAILABLE_TIMES } from "@/utils/contants";

export default {
    components: {},

    props: {
        refreshList: {
            defaul: false,
        }
    },

    watch: {
        search(val) {
            if (val != null && val.trim() != "") {
                this.$router.push({ name: this.$route.name, query: { search: val } })
            } else {
                this.$router.push({ name: this.$route.name })
            }
        },
        refreshList(val) {
            if (val) {
                this.retrieveData();
            }
        },
        "$i18n.locale": function () {
            this.headers = [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: this.$t('datatable.header["Groupe"]'), key: 'group.label' },
                { title: this.$t('datatable.header["Fréquence"]'), key: 'frequency' },
                { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ];
        }
    },

    data() {
        return {
            loading: false,
            items: [],
            search: this.$route.query.search ?? null,

            headers: [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: this.$t('datatable.header["Groupe"]'), key: 'group.label' },
                { title: this.$t('datatable.header["Fréquence"]'), key: 'frequency' },
                { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ],
            btns: [
                { label: 'Nouveau délai', icon: 'mdi-plus', type: 'route', value: 'notificationNew' },
            ]
        };
    },

    created() {
        if (!authTokenService.getToken()) return;
        this.retrieveData();
    },

    mounted() { },

    methods: {
        actionHandler(type, value) {
            switch (type) {
                case "route":
                    if (value == '') return;
                    this.$router.push({ name: value })
                    break;
                case "import":
                    this.dialogImport = value
                    break;
                default:
                    break;
            }
        },

        editItem(item) {
            this.$router.push({ name: "notificationDetail", params: { id: item.id } })
        },

        convertToTimeToString(value) {
            // Calculate the number of days
            const days = Math.floor(value / 1440);
            // Calculate the number of hours
            const hours = Math.floor((value % 1440) / 60);
            // Calculate the number of minutes
            const remainingMinutes = value % 60;
            return `${days} ${this.$t('admin["jours"]')}, ${hours} ${this.$t('admin["heures"]')}, ${remainingMinutes} ${this.$t('admin["minutes"]')}`;
        },


        displayRecursiveParent(target, group) {
            if(group != null) {
                target.label += ` | ${group.label} (ID: ${group.id})`;
                this.displayRecursiveParent(target, group.parent)
            }
        },

        async retrieveData() {
            this.loading = true;
            try {
                const request = await apiService.get(`/ticket-notifications`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                response.data.forEach(el => {
                    el.frequency = AVAILABLE_TIMES.find(time => time.value == el.frequency).label
                    if (el.group.parent != null) {
                        el.group.label = `${el.group.label} (ID: ${el.group.id})`
                        this.displayRecursiveParent(el.group, el.group.parent)
                    }
                })

                this.items = response.data;
                this.$emit('stopRefresh');
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
    },
};