<template>
  <div>
    <TicketDrawer
      v-if="displayType != 'form' && displayType != 'histories'"
      @refreshList="refreshList"
    />
    <TicketForm v-if="displayType == 'form'" />
    <TicketList
      v-if="displayType == 'list'"
      @stopRefresh="stopRefresh"
      :refreshList="refreshAllList"
    />
    <TicketOpenList
      v-if="displayType == 'opened'"
      @stopRefresh="stopRefresh"
      :refreshList="refreshOpenList"
    />
    <TicketFinishedList
      v-if="displayType == 'finished'"
      @stopRefresh="stopRefresh"
      :refreshList="refreshFinishedList"
    />

  </div>
</template>

<script src="./js/ticketView.js"></script>