import SwitchLanguage from "@/components/general/language/SwitchLanguage.vue"

export default {
    components: {
        SwitchLanguage
    },

    props: {},

    watch: {},
    
    data() {
        return {};
    },

    created() { },

    mounted() { },

    methods: {},
};