<template>
  <v-form ref="form">
    <div>
      <label for="email" class="font-weight-bold">
        {{ $t("form.label['Adresse email']") }}
      </label>
      <v-text-field
        id="email"
        v-model="email"
        required
        variant="outlined"
        density="compact"
        class="my-2"
        placeholder="example.email.com"
        :rules="[rules.required, rules.email]"
      ></v-text-field>
    </div>
    <div class="px-2 mt-5">
      <v-btn
        block
        color="teal-darken-1"
        :loading="loading"
        :disabled="loading"
        @click="submitHandler"
      >
        {{ $t("form.btn['Valider']") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script src="./js/passwordResetAskForm.js"></script>