import GroupStateNodeTree from "../GroupStateNodeTree.vue";

export default {
    setup() {

    },

    components: {
        GroupStateNodeTree
    },

    props: {
        title: {
            default: ''
        },
        groups: {
            default: () => {
                return []
            }
        },
    },

    watch: {
        "$i18n.locale": function() {
            this.headers = [
                { title: '', key: 'data-table-expand' },
                { title: this.$t('datatable.header["Nom du groupe"]'), key: 'label' },
                { title: this.$t('datatable.header["Temps de traitement (min)"]'), key: 'processing' },
                { title: this.$t('datatable.header["Total de tickets"]'), key: 'tickets' },
            ];
        }
    },

    data() {
        return {
            expanded: [],
            singleExpand: false,

            search: null,
            headers: [
                { title: '', key: 'data-table-expand' },
                { title: this.$t('datatable.header["Nom du groupe"]'), key: 'label' },
                { title: this.$t('datatable.header["Temps de traitement (min)"]'), key: 'processing' },
                { title: this.$t('datatable.header["Total de tickets"]'), key: 'tickets' },
            ],
            groupStats: []
        };
    },

    created() {
        this.setGroupTree(this.groups)
    },

    mounted() { },

    methods: {
        recursiveGroupTree(data, parentGroup, rootParent) {
            parentGroup.forEach(parent => {
                let child = data.filter(child => child.parent != null && child.parent.id == parent.id)
                parent.showChildren = false;
                parent.children = child;

                if(parent.tickets == 0) {
                    parent.tickets = 0;
                    parent.processing = 0;
                    child.forEach(childInfo => {
                        parent.tickets += childInfo.tickets
                        parent.processing += childInfo.processing
                    })

                    rootParent.tickets += parent.tickets
                    rootParent.processing += parent.processing
                }

                this.recursiveGroupTree(data, child, rootParent, parent)
            });
        },

        setGroupTree(groupInfos) {
            const parentGroup = groupInfos.filter(el => el.parent == null);

            parentGroup.forEach(rootParent => {
                let firstChild = groupInfos.filter(child => child.parent != null && child.parent.id == rootParent.id)
                rootParent.showChildren = true;
                rootParent.children = firstChild;

                if (rootParent.tickets == 0) {
                    rootParent.tickets = 0;
                    rootParent.processing = 0;
                    firstChild.forEach(childInfo => {
                        rootParent.tickets += childInfo.tickets
                        rootParent.processing += childInfo.processing
                    })
                }

                this.recursiveGroupTree(groupInfos, firstChild, rootParent, null)
            });

            this.groupStats = parentGroup;
        }
    },
};