<template>
  <div class="py-lg-6 px-lg-8 py-md-4 px-md-5 pa-4">
    <ImportSourcesDialog
      v-model:dialog="dialogImport"
      @refresh="retrieveData"
    />

    <v-row>
      <v-col cols="5" class="d-flex align-center">
        <h2 class="font-weight-regular mr-2">
          {{ $t('admin["Toutes les sources"]') }}
        </h2>
      </v-col>
      <v-col>
        <div class="d-flex justify-lg-end">
          <v-btn
            size="small"
            class="mx-2 text-caption"
            color="teal-darken-1"
            v-for="(btn, i) in btns"
            :key="i"
            @click="actionHandler(btn.type, btn.value)"
          >
            <v-icon size="18" v-if="btn.icon != ''" class="mr-1">
              {{ btn.icon }}
            </v-icon>
            {{ btn.label }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" lg="6" md="7">
        <v-text-field
          v-model="search"
          :placeholder="searchPlaceholder"
          :hint="searchHint"
          variant="underlined"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          color="teal-darken-2"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-if="!loading"
      :search="search"
      :headers="headers"
      :items="sources"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.label`]="{ item }">
        {{ $t(`fields["${item.raw.label}"]`) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon size="large" @click="editItem(item.raw)"> mdi-eye </v-icon>
      </template>
    </v-data-table>

    <div v-else class="d-flex justify-center align-center" style="height: 40vh">
      <v-progress-circular
        :size="45"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script src="./js/sourcesList.js"></script>