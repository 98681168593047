<template>
  <div>
    <SettingTimesDrawer @refreshList="refreshList" />

    <ProcessingTimeList
      v-if="displayType == 'processingList'"
      :refreshList="refreshProcessings"
      @stopRefresh="stopRefresh"
    />

    <ProcessingTimeForm v-if="displayType == 'processingForm'" />

    <TicketNotificationList
      v-if="displayType == 'notificationList'"
      :refreshList="refreshNotifications"
      @stopRefresh="stopRefresh"
    />

    <TicketNotificationForm v-if="displayType == 'notificationForm'" />
  </div>
</template>

<script src="./js/settingTimesView.js"></script>