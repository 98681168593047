import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import piniaPersist from 'pinia-plugin-persist'

import GeneralLayout from './layouts/GeneralLayout.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import enJson from "./i18n/languages/en.json"
import frJson from "./i18n/languages/fr.json"

const pinia = createPinia()
pinia.use(piniaPersist)

loadFonts()

const messages = {
  en: enJson,
  fr: frJson
}

const i18n = createI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  warnHtmlInMessage: 'off',
  silentTranslationWarn: true,
  messages, // set locale messages
})

createApp(GeneralLayout)
  .use(router)
  .use(pinia)
  .use(vuetify)
  .use(i18n)
  .mount('#app')