import router from "@/router";

const routeService = {
    goBack() {
        return router.go(-1)
    },

    goToRoute(routeName) {
        let routeObject = { name: routeName };
        if(routeName == "home") routeObject = {name: routeName, replace: true };
        if(routeName == "dashboard") routeObject = {name: routeName, replace: true };
        if(router.currentRoute.value.name != routeName) return router.push(routeObject);
        return;
    },

    goToSingle(routeName, params, query = null) {
        let routeObject = {name: routeName, params};
        if(query != null) routeObject = {name: routeName, params, query};
        return router.push(routeObject);
    }
}

export default routeService;