export const TOKEN_NAME = "swilk_token";
// export const API_URL = "http://localhost:8000";
export const API_URL = "https://tenant-01-api.andgo.re";

export const OPEN_SNACKBAR = "openSnackbar";

export const AVAILABLE_TIMES = [
    { label: "Tous les jours", value: 1440 },
    { label: "Tous les 2 jours", value: 2 * 1440 },
    { label: "Tous les 3 jours", value: 3 * 1440 },
    { label: "Tous les 4 jours", value: 4 * 1440 },
    { label: "Tous les 5 jours", value: 5 * 1440 },
    { label: "Tous les 6 jours", value: 6 * 1440 },
    { label: "Tous les 7 jours", value: 7 * 1440 },
    { label: "Tous les 8 jours", value: 8 * 1440 },
    { label: "Tous les 9 jours", value: 9 * 1440 },
    { label: "Tous les 10 jours", value: 10 * 1440 },
    { label: "Tous les 11 jours", value: 11 * 1440 },
    { label: "Tous les 12 jours", value: 12 * 1440 },
    { label: "Tous les 13 jours", value: 13 * 1440 },
    { label: "Tous les 14 jours", value: 14 * 1440 },
    { label: "Tous les 15 jours", value: 15 * 1440 },
    { label: "Tous les 16 jours", value: 16 * 1440 },
    { label: "Tous les 17 jours", value: 17 * 1440 },
    { label: "Tous les 18 jours", value: 18 * 1440 },
    { label: "Tous les 19 jours", value: 19 * 1440 },
    { label: "Tous les 20 jours", value: 20 * 1440 },
    { label: "Tous les 21 jours", value: 21 * 1440 },
    { label: "Tous les 22 jours", value: 22 * 1440 },
    { label: "Tous les 23 jours", value: 23 * 1440 },
    { label: "Tous les 24 jours", value: 24 * 1440 },
    { label: "Tous les 25 jours", value: 25 * 1440 },
]


export const DASHBOARD_STRUCTURE = [
    {
        row: [
            {
                column: [
                    {
                        row: [
                            { title: 'Temps de traitement moyen', show: true, type: 0, data: null, },
                            { title: 'Tickets ouvert aujourd\'hui', show: true, type: 0, data: null, },
                            { title: 'Tickets prioritaires', show: true, type: 0, data: null, },

                        ]
                    },
                    {
                        row: [
                            { title: 'Tickets en attente', show: true, type: 0, data: null, },
                            { title: 'Tickets en cours', show: true, type: 0, data: null, },
                            { title: 'Tickets terminés', show: true, type: 0, data: null, },

                        ]
                    }
                ]
            },
            {
                column: [
                    {
                        title: 'Tickets lus et non lus', show: true,
                        type: 2,
                        data: {
                            labels: [],
                            datasets: [
                                {
                                    backgroundColor: [],
                                    data: [],
                                }
                            ]
                        },
                    },
                ]
            }
        ]
    },
    {
        row: [
            {
                column: [
                    {
                        title: 'Tickets par priorités', show: true,
                        type: 2,
                        data: {
                            labels: [],
                            datasets: [
                                {
                                    backgroundColor: [],
                                    data: [],
                                }
                            ]
                        },
                    },
                    {
                        title: 'Tickets par complexités', show: true,
                        type: 2,
                        data: {
                            labels: [],
                            datasets: [
                                {
                                    backgroundColor: [],
                                    data: [],
                                }
                            ]
                        },
                    },
                    {
                        title: 'Tickets par sources', show: true,
                        type: 3,
                        data: {
                            labels: [],
                            datasets: [
                                {
                                    backgroundColor: [],
                                    data: [],
                                }
                            ]
                        },
                    },
                ]
            }
        ]
    },
    {
        row: [
            {
                column: [
                    { title: 'Tickets par groupes', show: true, type: 1, data: [], },
                ]
            }
        ]
    },
];