import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    components: {},

    props: {
        dialog: {
            default: false
        },
        isDisabled: {
            default: false,
        },
        isActive: {
            default: false
        }
    },

    watch: {

    },

    data() {
        return {
            loading: false,
            cardWidth: 600,
            isActiveEdit: false
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
        this.isActiveEdit = this.isActive
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.isActiveEdit = this.isActive;
            this.$emit('update:dialog', false)
        },

        async actionHandler() {
            this.loading = true
            try {
                const request = await apiService.put(`/agents/${this.$route.params.id}?action=disable`, {isEnable: this.isActiveEdit});
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, {message: response.message,isError: false,})
                this.$emit("refresh")
                this.$emit('update:dialog', false)
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },
    },
};