<template>
  <div class="full_width_height d-flex justify-center align-center">
    <v-progress-circular
      :size="70"
      :width="4"
      color="grey"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script src="./js/loadingComponent.js"></script>