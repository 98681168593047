import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";
import TicketColorsDialog from "@/components/online/manager/ticketsColors/dialogs/TicketColorsDialog.vue"
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        TicketColorsDialog,
    },

    props: {},

    watch: {

    },

    data() {
        return {
            loading: false,
            dialogColors: false,
            title: "Couleur d'une ligne d'un ticket",
            selectedColor: null,
            selectedPriority: null,
            availableData: {
                availableColors: [],
                priorityColors: {
                    high: "",
                    medium: "",
                    low: "",
                    "very low": "",
                }
            }
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.retrieveTicketsColors();
    },

    mounted() { },

    methods: {
        openDialog(priority) {
            this.selectedPriority = priority;
            this.selectedColor = this.availableData.priorityColors[priority];
            this.dialogColors = true;
        },

        updateColor({ priority, color }) {
            this.availableData.priorityColors[priority] = color;
        },

        async retrieveTicketsColors() {
            this.loading = true;
            try {
                const request = await apiService.get("/interfaces");
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableData = response.data;
            } catch (error) {
                this.availableColors = [];
            } finally {
                this.loading = false;
            }
        },

        async submitHandler() {
            this.loading = true;
            try {
                const request = await apiService.post("/interfaces", { priorityColors: JSON.stringify(this.availableData.priorityColors) });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false })
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        },
    },
};