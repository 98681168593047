<template>
  <div style="margin-top: -5px" class="">
    <TicketHistoryDrawer
      :rail="rail"
      @close="toggleHistory"
      :histories="histories"
      v-if="ticketNumber"
    />

    <TicketTransfertDialog v-model:dialog="dialogTransfert" />

    <TicketMailDialog
      v-model:dialog="dialogEmail"
      @refreshSingle="retrieveSingle"
    />

    <v-row class="bg-brown-lighten-5 pt-2 px-4">
      <v-col class="d-flex align-center flex-wrap" v-if="ticketNumber">
        <v-icon size="large" class="mr-4" color="teal-darken-1" @click="goBack"
          >mdi-backspace</v-icon
        >
        <v-card
          width="fit-content"
          elevation="0"
          border=""
          class="py-2 px-4 d-flex align-center"
        >
          <v-row no-gutters v-if="!loading">
            <v-col class="d-flex align-center justify-center">
              <v-chip
                label
                density="compact"
                color="red"
                class="mr-2 px-2 py-3"
              >
                {{ $t(`ticket["${ticketStatus}"]`) }}
              </v-chip>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <p class="text-caption font-weight-medium">
                {{ `Ticket #${ticketNumber}` }}
              </p>
            </v-col>
          </v-row>
          <div v-else>
            <v-progress-circular
              :size="15"
              :width="2"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
        <div>
          <v-btn
            color="grey-darken-1"
            class="ma-1 ml-lg-3"
            size="small"
            @click="toggleHistory"
            :disabled="loading"
          >
            <v-icon class="mr-1">mdi-history</v-icon>
            {{ $t('ticket["Historique"]') }}
          </v-btn>
          <v-btn
            color="grey-darken-1"
            class="ma-1"
            size="small"
            @click="this.dialogEmail = !this.dialogEmail"
            :disabled="loading"
          >
            <v-icon class="mr-1">mdi-email</v-icon>
            {{ $t('ticket["Envoyer un email"]') }}
          </v-btn>
          <v-btn
            v-if="ticketStatus != 'finished'"
            color="grey-darken-1"
            class="ma-1"
            size="small"
            @click="openTransfertDialog"
            :disabled="loading"
          >
            <v-icon class="mr-1" size="18">mdi-swap-horizontal</v-icon>
            {{ $t('ticket["Transférer"]') }}
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-if="ticketStatus != 'finished'"
        class="d-flex justify-lg-end justify-md-end align-center"
      >
        <v-btn
          v-if="ticketNumber && !loading"
          size="small"
          color="teal-darken-1"
          @click="enableEdition"
          class="mx-5"
        >
          <div v-if="isDisabledBtn">
            <v-icon size="20" class="mr-1">mdi-pencil </v-icon>
            {{ $t('form.btn["Modifier"]') }}
          </div>
          <div v-else>
            <v-icon size="20" class="mr-1">mdi-close </v-icon>
            {{ $t('form.btn["Annuler"]') }}
          </div>
        </v-btn>
        <v-btn
          size="small"
          color="teal-darken-1"
          :loading="loading"
          :disabled="isDisabledBtn"
          @click="submitHandler"
        >
          <v-icon size="25" class="mr-2">mdi-content-save</v-icon>
          {{ $t('form.btn["Sauvegarder"]') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-3"></v-divider>
    <div class="py-lg-4 px-lg-8 py-md-4 px-md-5 pa-4">
      <v-row no-gutters>
        <v-col cols="8" lg="3" md="5" sm="7" class="d-flex align-center pr-2">
          <h2>{{ title }} {{ ticketNumber ? `#${ticketNumber}` : "" }}</h2>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="5"
          v-if="ticketNumber"
          class="d-flex"
        >
          <v-btn
            v-for="(item, i) in statusBtns"
            :key="i"
            size="small"
            class="ma-2 d-flex"
            :color="item.color"
            @click="updateStatusHandler(item.status)"
          >
            <v-icon size="20" class="mr-2">{{ item.icon }}</v-icon>
            <p>{{ item.label }}</p>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-divider></v-divider>

    <v-form ref="form" class="ticket__form_height" v-if="!loading">
      <div class="py-lg-1 px-lg-8 py-md-2 px-md-5 pa-4">
        <v-row no-gutters>
          <v-col cols="12" lg="" md="" sm="12">
            <h3 class="font-weight-medium text-body-1 mt-1">
              {{ $t('ticket["Demandeur"]') }}
            </h3>
            <p class="text-caption mb-3 mt-1">
              {{ $t('ticket["Les informations du demandeur"]') }}
            </p>

            <v-row class="my-2" no-gutters>
              <v-col cols="12" lg="10" md="10">
                <label for="group" class="font-weight-medium text-caption">
                  {{ getTitle() }}
                </label>
                <v-select
                  id="group"
                  v-model="group"
                  :items="groups"
                  variant="outlined"
                  density="compact"
                  placeholder=""
                  hide-details=""
                  class="mt-1"
                  item-title="label"
                  item-value="id"
                  return-object
                  :rules="[rules.required]"
                  :disabled="disableGroupSelection"
                  clearable
                ></v-select>
              </v-col>
            </v-row>

            <v-row
              class="my-2"
              no-gutters
              v-for="(field, index) in demandeForm"
              :key="index"
            >
              <v-col cols="12" lg="10" md="10">
                <label
                  class="font-weight-medium text-caption"
                  v-if="field.visible"
                >
                  {{ $t(`fields["${field.label}"]`) }}
                  <span v-if="field.properties.required && !field.values"
                    >*</span
                  >
                  <span
                    v-if="
                      field.properties.required &&
                      field.values &&
                      field.values.length > 0
                    "
                    >*</span
                  >
                </label>

                <v-text-field
                  v-if="field.type == 'text' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-text-field>

                <v-text-field
                  v-if="field.type == 'tel' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  type="tel"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="
                    field.properties.required
                      ? [rules.required, rules.phone]
                      : []
                  "
                ></v-text-field>

                <v-text-field
                  v-if="field.type == 'email' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  type="email"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="
                    field.properties.required
                      ? [rules.required, rules.email]
                      : []
                  "
                ></v-text-field>

                <v-text-field
                  v-if="field.type == 'url' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  type="url"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-text-field>

                <v-text-field
                  v-if="field.type == 'date' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  type="date"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-text-field>

                <v-textarea
                  v-if="field.type == 'textarea' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-textarea>

                <v-select
                  v-if="field.type == 'select' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :multiple="field.properties.multiple"
                  :items="field.values"
                  :item-title="field.properties.title"
                  :item-value="field.properties.value"
                  :return-object="field.properties.object"
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                  :menu-props="
                    field.properties.multiple
                      ? null
                      : {
                          closeOnClick: true,
                          closeOnContentClick: true,
                        }
                  "
                >
                  <template v-slot:selection="{ item }">
                    {{
                      $t(`fields[&quot;${item.title}&quot;]`).includes("fields")
                        ? item.title
                        : $t(`fields["${item.title}"]`)
                    }}
                  </template>

                  <template v-slot:item="{ item }">
                    <div
                      v-if="field.properties.multiple"
                      class="pl-2 py-1"
                      style="cursor: pointer"
                      @click="
                        setModelValue(
                          field.properties.multiple,
                          field,
                          item.value,
                          true
                        )
                      "
                    >
                      <v-icon
                        v-if="
                          field.model !== null &&
                          field.model.includes(item.value)
                        "
                        color="primary"
                        class="mr-3"
                      >
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else class="mr-3">
                        mdi-checkbox-blank-outline
                      </v-icon>
                      {{
                        $t(`fields[&quot;${item.title}&quot;]`).includes(
                          "fields"
                        )
                          ? item.title
                          : $t(`fields["${item.title}"]`)
                      }}
                    </div>
                    <div v-else>
                      <v-list-item
                        :title="
                          $t(`fields[&quot;${item.title}&quot;]`).includes(
                            'fields'
                          )
                            ? item.title
                            : $t(`fields[&quot;${item.title}&quot;]`)
                        "
                        density="compact"
                        @click="
                          setModelValue(
                            field.properties.multiple,
                            field,
                            item.value,
                            true
                          )
                        "
                      >
                      </v-list-item>
                    </div>
                  </template>
                </v-select>

                <v-checkbox
                  v-if="field.type == 'checkbox' && field.visible"
                  :label="field.properties.text"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-checkbox>

                <v-radio-group
                  v-if="field.type == 'radio' && field.visible"
                  :inline="field.properties.inline"
                  v-model="field.model"
                >
                  <v-radio
                    v-for="(radio, i) in field.values"
                    :key="i"
                    :label="radio.label"
                    :value="radio.value"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="" md="" sm="12">
            <h3 class="font-weight-medium text-body-1 mt-1">
              {{ $t('ticket["Générales"]') }}
            </h3>
            <p class="text-caption mb-3 mt-1">
              {{
                $t(
                  'ticket["Les informations générales nécessaires à la vie du ticket"]'
                )
              }}
            </p>

            <v-row
              class="my-2"
              no-gutters
              v-for="(field, index) in annexeForm"
              :key="index"
            >
              <v-col cols="12" lg="10" md="10">
                <label
                  class="font-weight-medium text-caption"
                  v-if="field.visible"
                >
                  {{ $t(`ticket["${field.label}"]`) }}
                  <span v-if="field.properties.required">*</span>
                </label>

                <div
                  v-if="field.type == 'moods' && field.visible"
                  class="d-flex justify-center border rounded mt-2 px-2 py-1"
                >
                  <v-tooltip
                    v-for="(item, i) in field.values"
                    :key="i"
                    :text="$t(`fields.humor[${i}]`)"
                    location="bottom"
                  >
                    <template v-slot:activator="{ props }">
                      <v-chip
                        v-bind="props"
                        :color="
                          field.model != null && item.value == field.model.value
                            ? 'teal-darken-1'
                            : 'white'
                        "
                        class="ma-1"
                        style="font-size: 30px"
                        size="large"
                        :disabled="isDisabled"
                        @click="updateMoodEmot(field, item)"
                      >
                        {{ item.value }}
                        <v-icon
                          size="18"
                          v-if="
                            field.model != null &&
                            item.value == field.model.value
                          "
                          >mdi-check</v-icon
                        >
                      </v-chip>
                    </template>
                  </v-tooltip>
                </div>

                <v-select
                  v-if="field.type == 'select' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`ticket[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :multiple="field.properties.multiple"
                  :items="field.values"
                  :item-title="field.properties.title"
                  :item-value="field.properties.value"
                  :return-object="field.properties.object"
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                  :menu-props="
                    field.properties.multiple
                      ? null
                      : {
                          closeOnClick: true,
                          closeOnContentClick: true,
                        }
                  "
                >
                  <template v-slot:selection="{ item }">
                    {{
                      field.label == "Tags" || field.label == "tags"
                        ? item.title
                        : $t(`fields["${item.title}"]`)
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <div
                      v-if="field.properties.multiple"
                      class="pl-2 py-1"
                      style="cursor: pointer"
                      @click="
                        setModelValue(
                          field.properties.multiple,
                          field,
                          item.value,
                          false
                        )
                      "
                    >
                      <v-icon
                        v-if="
                          field.model !== null &&
                          field.model.includes(item.value)
                        "
                        color="primary"
                        class="mr-3"
                      >
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else class="mr-3">
                        mdi-checkbox-blank-outline
                      </v-icon>
                      {{
                        field.label == "Tags" || field.label == "tags"
                          ? item.title
                          : $t(`fields["${item.title}"]`)
                      }}
                    </div>
                    <div v-else>
                      <v-list-item
                        :title="$t(`fields[&quot;${item.title}&quot;]`)"
                        density="compact"
                        @click="
                          setModelValue(
                            field.properties.multiple,
                            field,
                            item.value,
                            false
                          )
                        "
                      >
                      </v-list-item>
                    </div>
                  </template>
                </v-select>

                <v-textarea
                  v-if="field.type == 'textarea' && field.visible"
                  v-model="field.model"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :placeholder="
                    $t(`fields[&quot;${field.properties.placeholder}&quot;]`)
                  "
                  :disabled="isDisabled"
                  :rules="field.properties.required ? [rules.required] : []"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-form>

    <div
      style="height: 55vh"
      class="d-flex justify-center align-center"
      v-if="loading"
    >
      <LoadingComponent />
    </div>
  </div>
</template>

<script src="./js/ticketForm.js"></script>