<template>
  <div>
    <v-progress-linear
      v-model="defaultScore.width"
      :color="defaultScore.color"
      height="10"
    ></v-progress-linear>
    <p class="ma-0 pa-0 text-caption font-weight-bold">{{ $t(`passwordScore.label[${currentScore}]`) }}</p>
  </div>
</template>

<script src="./js/passwordScore.js"></script>