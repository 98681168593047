<template>
  <div>
    <div class="d-flex">
      <v-row no-gutters>
        <v-col
          cols="8"
          class="d-flex"
          :style="node.children.length > 0 ? 'font-weight: bold' : ''"
        >
          <v-row no-gutters>
            <v-col cols="12" sm="" md="8" lg="5" class="d-flex pr-lg-5">
              <p>{{ node.label }}</p>
              <v-icon
                v-if="node.children.length > 0"
                size="large"
                @click="clickHandler"
              >
                {{
                  currentNode.showChildren
                    ? "mdi-chevron-down"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-col>

            <v-col cols="12" sm="6" lg="" md="6" class="d-flex">
              <p> {{ $t('text["Traitement"]') }} : {{ node.processing }} min</p>
            </v-col>

            <v-col cols="12" sm="6" lg="" md="6" class="d-flex">
              <p>Tickets : {{ node.tickets }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div
      v-if="node.children && node.children.length && currentNode.showChildren"
    >
      <GroupStateNodeTree
        v-for="(child, index) in node.children"
        :key="index"
        :node="child"
        :v-show="currentNode.showChildren"
      ></GroupStateNodeTree>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupStateNodeTree",
  props: {
    node: Object,
    show: Boolean,
  },
  data() {
    return {
      currentNode: { showChildren: false },
    };
  },
  created() {
    this.currentNode = this.node;
  },
  methods: {
    clickHandler() {
      this.currentNode.showChildren = !this.currentNode.showChildren;
    },
  },
};
</script>
