export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        isDisabled: {
            default: false,
        },
        workInfos: {
            default: () => {
                return [];
            }
        }
    },

    watch: {
        "$i18n.locale": function () {
            this.workInfosEdit[0].label = this.$t('manager.userManagement["Lundi"]')
            this.workInfosEdit[1].label = this.$t('manager.userManagement["Mardi"]')
            this.workInfosEdit[2].label = this.$t('manager.userManagement["Mercredi"]')
            this.workInfosEdit[3].label = this.$t('manager.userManagement["Jeudi"]')
            this.workInfosEdit[4].label = this.$t('manager.userManagement["Vendredi"]')
            this.workInfosEdit[5].label = this.$t('manager.userManagement["Samedi"]')
            this.workInfosEdit[6].label = this.$t('manager.userManagement["Dimanche"]')
        },
        dialog(val) {
            if (val) {
                this.workInfosEdit = this.workInfos;
            }
        },
    },

    data() {
        return {
            cardWidth: 600,
            workInfosEdit: [
                { label: 'Lundi', value: null, value2: null },
                { label: 'Mardi', value: null, value2: null },
                { label: 'Mercredi', value: null, value2: null },
                { label: 'Jeudi', value: null, value2: null },
                { label: 'Vendredi', value: null, value2: null },
                { label: 'Samedi', value: null, value2: null },
                { label: 'Dimanche', value: null, value2: null },
            ]
        };
    },

    created() {
        this.setCardWidth()
        this.workInfosEdit = this.workInfos;
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        actionHandler() {
            this.$emit("updateField", { fieldName: "workInfos", value: this.workInfosEdit })
            this.$emit('update:dialog', false)
        }
    },
};