<template>
  <div class="my-1">
    <div class="d-flex">
      <v-row no-gutters>
        <v-col
          cols="8"
          class="d-flex"
          :style="node.children.length > 0 ? 'font-weight: bold' : ''"
        >
          <v-row no-gutters>
            <v-col cols="" md="3" lg="3" class="d-flex justify-lg-end pr-lg-5">
              <p>{{ node.id }}</p>
            </v-col>

            <v-col cols="" class="d-flex">
              <p>{{ node.label }}</p>
              <v-icon
                v-if="node.children.length > 0"
                size="large"
                @click="clickHandler"
              >
                {{
                  currentNode.showChildren
                    ? "mdi-chevron-down"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="1"
          class="d-flex align-end"
          v-if="node.children.length == 0"
        >
          <v-btn
            rounded=""
            size="small"
            class="text-caption px-10 py-0 my-0"
            color="teal"
            @click="selectGroupForNotif(node)"
          >
            {{ $t('form.btn["Choisir"]') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>

  <div v-if="node.children && node.children.length && currentNode.showChildren">
    <GroupRecursiveTicketNotification
      v-for="(child, index) in node.children"
      :key="index"
      :node="child"
      :v-show="currentNode.showChildren"
    ></GroupRecursiveTicketNotification>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
export default {
  name: "GroupRecursiveTicketNotification",
  props: {
    node: Object,
    show: Boolean,
  },
  data() {
    return {
      currentNode: { showChildren: false },
    };
  },
  created() {
    this.currentNode = this.node;
  },
  methods: {
    clickHandler() {
      this.currentNode.showChildren = !this.currentNode.showChildren;
    },
    selectGroupForNotif(item) {
      eventBus.$emit('groupForNotif', item)
    }
  },
};
</script>
