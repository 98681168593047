import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { AVAILABLE_TIMES, OPEN_SNACKBAR } from "@/utils/contants";

import DeleteDialog from "@/components/online/manager/accessManagement/dialogs/DeleteDialog.vue";
import ProcessingTimeActionsDialog from "@/components/online/admin/processingTimes/dialog/ProcessingTimeActionsDialog.vue"

export default {
    components: {
        DeleteDialog,
        ProcessingTimeActionsDialog,
    },

    props: {

    },

    watch: {
        '$route'(to) {
            this.title = to.params.id ? "" : "Ajout d'un temps de traitement";
            this.frontInfo = null;

            if (to.name == 'processingNew') {
                this.$refs.form.reset();
                this.isDisabled = false;
                this.isDisabledBtn = false;
            } else if (to.name == 'processingDetail') {
                this.isDisabled = true;
                this.isDisabledBtn = true;
                this.retrieveSingle();
            }
        },
        "$i18n.locale": function () {
            this.applicationCases = [
                { value: 0, label: this.$t(`admin["Ticket plus vieux que"]`) },
                { value: 1, label: this.$t(`admin["Changement de statut du ticket plus ancien que"]`), },
                { value: 2, label: this.$t(`admin["Ticket avec une échéance dans moins de"]`), },
                { value: 3, label: this.$t(`admin["Ticket en retard de plus de"]`), },
            ]
            this.rules = { required: v => !!v || this.$t('form.validation.required'), }
        }
    },

    data() {
        return {
            loading: false,
            isDisabled: false,
            isDisabledBtn: false,
            loadingDelete: false,
            dialogDelete: false,
            dialogActions: false,

            title: this.$route.params.id ? "" : "Ajout d'un temps de traitement",
            frontInfo: null,

            note: null,
            name: null,
            frequency: null,
            selectedStatus: null,
            selectedApplicationCase: null,
            frequencies: AVAILABLE_TIMES,
            status: null,
            actions: [
                { enable: false, label: "Priorité", model: null, values: ["Haute", "Moyenne", "Basse", "Faible"] },
                { enable: false, label: "Fermer" },
                { enable: false, label: "Participants", A: null, CC: [], CCI: [], group: null },
                { enable: false, label: "Email", De: null, A: null, CC: [], CCI: [], subject: null, body: null },
            ],

            applicationCases: [
                { value: 0, label: this.$t(`admin["Ticket plus vieux que"]`) },
                { value: 1, label: this.$t(`admin["Changement de statut du ticket plus ancien que"]`), },
                { value: 2, label: this.$t(`admin["Ticket avec une échéance dans moins de"]`), },
                { value: 3, label: this.$t(`admin["Ticket en retard de plus de"]`), },
            ],
            rules: { required: v => !!v || this.$t('form.validation.required'), }
        };
    },

    created() {
        if (!authTokenService.getToken()) return;

        if (!this.$route.params.id) {
            this.isDisabledBtn = false;
        }

        if (this.$route.params.id) {
            this.isDisabled = true;
            this.isDisabledBtn = true;
            this.retrieveSingle();
        }
    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.push({ name: 'processings', replace: true });
        },

        enableEdition() {
            this.isDisabled = !this.isDisabled;
            this.isDisabledBtn = !this.isDisabledBtn;
        },

        updateActions(actionsEdited) {
            this.actions = actionsEdited
        },

        async retrieveSingle() {
            this.loading = true;
            try {
                const request = await apiService.get(`/processing-times/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.frontInfo = response.data.name;
                this.note = response.data.note;
                this.name = response.data.name;
                this.frequency = response.data.processingTime;
                this.selectedApplicationCase = this.applicationCases.find(el => el.value == response.data.applicationCase);
                this.actions = response.data.actions;

                const allStatus = [
                    { label: 'Activer', value: 'actif', icon: 'mdi-play', color: 'blue' },
                    { label: 'Désactiver', value: 'inactif', icon: 'mdi-stop', color: 'blue-grey' },
                ];

                if (response.data.status == 'actif') {
                    this.status = allStatus[1]
                } else {
                    this.status = allStatus[0]
                }

                this.selectedStatus = allStatus.find(el => el.value == response.data.status);
            } catch (error) {
                this.$router.push({ name: "processings", replace: true });
            } finally {
                this.loading = false;
            }
        },

        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');

                const url = this.$route.params.id ? `/processing-times/${this.$route.params.id}` : `/processing-times`;
                const request = await apiService.post(url, {
                    name: this.name,
                    note: this.note,
                    processingTime: this.frequency,
                    actions: JSON.stringify(this.actions),
                    applicationCase: this.selectedApplicationCase.value,
                });
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$router.push({ name: 'processingDetail', params: { id: response.infos.id }, replace: true })
                } else {
                    this.frontInfo = "";
                    this.retrieveSingle();
                }
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
                if (this.$route.params.id) {
                    this.isDisabledBtn = true;
                    this.isDisabled = true;
                } else {
                    this.isDisabledBtn = false;
                }
            }
        },

        async updateStatusHandler(status) {
            this.isDisabled = true;
            this.isDisabledBtn = true;

            try {
                const request = await apiService.put(`/processing-times/${this.$route.params.id}`, { status });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.retrieveSingle();
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },

        async deleteHandler() {
            this.loadingDelete = true;
            try {
                const request = await apiService.delete(`/processing-times/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.$router.push({ name: "processings", replace: true });
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};