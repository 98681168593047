import DashboardView from "@/views/online/generals/DashboardView.vue"
import ProfilView from "@/views/online/profil/ProfilView.vue"

const loggedRoutes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
        meta: { requiredAuth: true, roles: ['manager', 'admin'] }
    },
    {
        path: '/profil',
        name: 'profil',
        component: ProfilView,
        meta: { requiredAuth: true }
    },
];

export default loggedRoutes;