import apiService from "@/services/apiService";
import ImportSourcesDialog from "@/components/online/admin/dialogs/ImportSourcesDialog.vue"
import authTokenService from "@/services/authTokenService";

export default {
    components: {
        ImportSourcesDialog,
    },

    props: {
        refreshList: {
            defaul: false,
        }
    },

    watch: {
        search(val) {
            if (val != null && val.trim() != "") {
                this.$router.push({ name: this.$route.name, query: { search: val } })
            } else {
                this.$router.push({ name: this.$route.name })
            }
        },
        refreshList(val) {
            if (val) {
                this.retrieveData();
            }
        },
        "$i18n.locale": function () {
            this.searchPlaceholder = this.$t(`admin["Rechercher une source"]`)
            this.searchHint = this.$t(`admin["Rechercher une source à partir des mots clés"]`)
            this.headers = [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: this.$t('datatable.header["Libellé"]'), key: 'label' },
                { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ];
            this.btns = [
                { label: this.$t('admin["Importer"]'), icon: 'mdi-inbox-arrow-down', type: 'import', value: true },
                { label: this.$t('admin["Nouvelle source"]'), icon: 'mdi-plus', type: 'route', value: 'sourceNew' },
            ]
        }
    },

    data() {
        return {
            loading: false,
            dialogImport: false,

            sources: [],
            search: this.$route.query.search ?? null,
            searchPlaceholder: this.$t(`admin["Rechercher une source"]`),
            searchHint: this.$t(`admin["Rechercher une source à partir des mots clés"]`),
            headers: [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: this.$t('datatable.header["Libellé"]'), key: 'label' },
                { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ],
            btns: [
                { label: this.$t('admin["Importer"]'), icon: 'mdi-inbox-arrow-down', type: 'import', value: true },
                { label: this.$t('admin["Nouvelle source"]'), icon: 'mdi-plus', type: 'route', value: 'sourceNew' },
            ]
        };
    },

    created() {
        if (!authTokenService.getToken()) return;
        this.retrieveData();
    },

    mounted() { },

    methods: {
        actionHandler(type, value) {
            switch (type) {
                case "route":
                    if (value == '') return;
                    this.$router.push({ name: value })
                    break;
                case "import":
                    this.dialogImport = value
                    break;
                default:
                    break;
            }
        },

        editItem(item) {
            this.$router.push({ name: "sourceDetail", params: { id: item.id } })
        },

        async retrieveData() {
            this.loading = true;
            try {
                const request = await apiService.get(`/sources`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.sources = response.data;
                this.$emit('stopRefresh');
            } catch (error) {
                this.sources = [];
            } finally {
                this.loading = false;
            }
        },
    },
};