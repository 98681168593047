import { useTicketListConfigStore } from "@/stores/TicketListConfigStore";

export default {
    setup() {
        const ticketListConfigStore = useTicketListConfigStore();
        return { ticketListConfigStore };
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {},

    data() {
        return {
            cardWidth: 500,
            columns: this.ticketListConfigStore.ticketListConfig.filter(element => element.show),
            copyColumns: this.ticketListConfigStore.ticketListConfig.filter(element => element.show),
            column: 'Colonne n°'
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 500;
        },

        close() {
            this.ticketListConfigStore.ticketListConfig
            this.$emit('update:dialog', false)
        },

        decreasePosition(item, i) {
            let decreaseElement = this.columns[i - 1];
            if(decreaseElement) {
                this.columns.splice(i, 1, decreaseElement)
                this.columns.splice(i - 1, 1, item)
            }
        },

        increasePosition(item, i) {
            let increaseElement = this.columns[i + 1];
            if(increaseElement) {
                this.columns.splice(i, 1, increaseElement)
                this.columns.splice(i + 1, 1, item)
            }
        },

        actionHandler() {
            this.ticketListConfigStore.ticketListConfig = this.columns;
            this.$emit("confimUpdateTicketColumns", this.columns)
            this.$emit('update:dialog', false)
        }
    },
};