import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {

    },

    components: {

    },

    props: {},

    watch: {
        "$i18n.locale": function () {
            this.rules = {
                required: v => !!v || this.$t('form.validation.required'),
                email: v => /.+@.+\..+/.test(v) || this.$t('form.validation.email'),
            }
        }
    },

    data() {
        return {
            loading: false,
            email: null,
            rules: {
                required: v => !!v || this.$t('form.validation.required'),
                email: v => /.+@.+\..+/.test(v) || this.$t('form.validation.email'),
            }
        };
    },

    created() {

    },

    mounted() { },

    methods: {
        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) return;

                const request = await apiService.post("/reset?action=code", { email: this.email });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
};