import DeleteDialog from "@/components/online/manager/accessManagement/dialogs/DeleteDialog.vue";
import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { AVAILABLE_TIMES, OPEN_SNACKBAR } from "@/utils/contants";

import GroupTicketNotificationDialog from "@/components/online/admin/ticketNotifications/dialog/GroupTicketNotificationDialog.vue"

export default {
    components: {
        DeleteDialog,
        GroupTicketNotificationDialog
    },

    props: {

    },

    watch: {
        '$route'(to) {
            this.title = to.params.id ? "" : "Ajout d'un délai de notification";
            this.frontInfo = null;

            if (to.name == 'notificationNew') {
                this.$refs.form.reset();
                this.isDisabled = false;
                this.isDisabledBtn = false;
            } else if (to.name == 'notificationDetail') {
                this.isDisabled = true;
                this.isDisabledBtn = true;
                this.retrieveSingle();
            }
        },
        "$i18n.locale": function () {
            this.rules = { required: v => !!v || this.$t('form.validation.required'), }
        }
    },

    data() {
        return {
            loading: false,
            isDisabled: false,
            isDisabledBtn: false,
            loadingDelete: false,
            dialogDelete: false,
            dialogGroup: false,

            title: this.$route.params.id ? "" : "Ajout d'un délai de notification",
            frontInfo: null,

            group: null,
            availableGroups: [],

            frequency: null,
            frequencies: AVAILABLE_TIMES,
            rules: { required: v => !!v || this.$t('form.validation.required'), }
        };
    },

    created() {
        if (!authTokenService.getToken()) return;

        this.retrieveGroups();

        if (!this.$route.params.id) {
            this.isDisabledBtn = false;
        }

        if (this.$route.params.id) {
            this.isDisabled = true;
            this.isDisabledBtn = true;
            this.retrieveSingle();
        }
    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.push({ name: 'notifications', replace: true });
        },

        enableEdition() {
            this.isDisabled = !this.isDisabled;
            this.isDisabledBtn = !this.isDisabledBtn;
        },

        groupForNotif(item) {
            this.group = item
        },

        displayRecursiveParent(target, group) {
            if (group != null) {
                target.label += ` | ${group.label} (ID: ${group.id})`;
                this.displayRecursiveParent(target, group.parent)
            }
        },

        groupSelectionTitle(item) {
            if (item.parent != null) {
                item.label = `${item.label} (ID: ${item.id})`
                this.displayRecursiveParent(item, item.parent)
            }

            return `${item.label}`;
        },

        setModelValue(value) {
            this.frequency = value
        },

        recursiveGroupTree(data, parentGroup) {
            parentGroup.forEach(element => {
                let child = data.filter(child => child.parent != null && child.parent.id == element.id)
                element.showChildren = false;
                element.children = child;
                this.recursiveGroupTree(data, child)
            });
        },

        async retrieveSingle() {
            this.loading = true;
            try {
                const request = await apiService.get(`/ticket-notifications/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.frontInfo = `Notification #${response.data.id}`;

                this.group = response.data.group;
                this.frequency = AVAILABLE_TIMES.find(el => el.value == response.data.frequency);
            } catch (error) {
                this.$router.push({ name: "notifications", replace: true });
            } finally {
                this.loading = false;
            }
        },

        async retrieveGroups() {
            this.loading = true;
            try {
                const request = await apiService.get(`/backoffice/groups`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                const parentGroup = response.data.filter(el => el.parent == null);

                parentGroup.forEach(element => {
                    let firstChild = response.data.filter(child => child.parent != null && child.parent.id == element.id)
                    element.showChildren = true;
                    element.children = firstChild;
                    this.recursiveGroupTree(response.data, firstChild)
                });

                this.availableGroups = parentGroup;
            } catch (error) {
                this.availableGroups = []
            } finally {
                this.loading = false;
            }
        },

        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');

                const url = this.$route.params.id ? `/ticket-notifications/${this.$route.params.id}` : `/ticket-notifications`;
                const request = await apiService.post(url, { frequency: this.frequency.value, group: this.group.id });
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$router.push({ name: 'notificationDetail', params: { id: response.infos.id }, replace: true })
                } else {
                    this.frontInfo = "";
                    this.retrieveSingle();
                }
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
                if (this.$route.params.id) {
                    this.isDisabledBtn = true;
                    this.isDisabled = true;
                } else {
                    this.isDisabledBtn = false;
                }
            }
        },

        async deleteHandler() {
            this.loadingDelete = true;
            try {
                const request = await apiService.delete(`/ticket-notifications/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.$router.push({ name: "notifications", replace: true });
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};