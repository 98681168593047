<template>
  <div class="py-lg-6 px-lg-8 py-md-4 px-md-5 pa-4">
    <v-row>
      <v-col cols="5" class="d-flex align-center">
        <h1 class="font-weight-bold mr-2">{{ $t('text["Annuaire"]') }}</h1>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" lg="6" md="7">
        <v-text-field
          v-model="search"
          :placeholder="$t(`text[&quot;Rechercher un agent&quot;]`)"
          :hint="$t(`text[&quot;Rechercher à partir des mots clés&quot;]`)"
          variant="underlined"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          color="teal-darken-2"
          clearable
        ></v-text-field>
        <div class="mb-2" v-if="tagFilters.length > 0">
          <template v-for="(tag, index) in tagFilters" :key="index">
            <v-chip color="teal" class="ma-1" v-if="tag.type == 'role'" closable @click="removeTag(index)">
              {{ $t(`text["${tag.label}"]`) }}
            </v-chip>

            <v-chip color="blue" class="ma-1" v-if="tag.type == 'userJob'" closable @click="removeTag(index)">
              {{ tag.label }}
            </v-chip>

            <v-chip color="red" class="ma-1" v-if="tag.type == 'group'" closable @click="removeTag(index)">
              {{ tag.label }}
            </v-chip>
          </template>
        </div>
      </v-col>
    </v-row>

    <div
      v-if="!loading"
      class="d-flex flex-wrap"
      style="max-height: 680px; overflow-y: scroll"
    >
      <v-card
        :width="cardWidth"
        v-for="(user, index) in infos"
        :key="index"
        class="ma-2 pb-3"
      >
        <div class="d-flex justify-center mt-3 mb-5">
          <v-avatar class="mr-4" size="80" color="grey">
            <v-img
              :src="getFilePath(user.avatar, true)"
              alt="avatar"
              cover
            ></v-img>
          </v-avatar>
        </div>
        <p class="text-center font-weight-medium">
          {{ user.lastname }} {{ user.firstname }}
        </p>
        <p class="text-center">{{ user.email }}</p>

        <div class="px-2 pb-2">
          <div class="px-2 my-2">
            <v-divider></v-divider>
          </div>
        </div>

        <div
          class="d-flex flex-wrap justify-center px-2"
          style="overflow: scroll; max-height: 90px"
        >
          <v-chip
          color="teal"
            class="ma-1"
            @click="setTagFilter('role', { id: 0, label: user.role })"
          >
            {{ $t(`text["${user.role}"]`) }}
          </v-chip>

          <v-chip
          color="blue"
            class="ma-1"
            v-if="user.userJob != null"
            @click="
              setTagFilter('userJob', { id: user.userJob.id, label: user.userJob.label })
            "
          >
            {{ user.userJob.label }}
          </v-chip>

          <template v-if="user.groups.length > 0">
            <ContactBooksGroup
              v-for="(groupInfo, i) in user.groups"
              :key="i"
              :node="groupInfo.group"
            />
          </template>
        </div>
      </v-card>
    </div>

    <div v-else class="d-flex justify-center align-center" style="height: 40vh">
      <v-progress-circular
        :size="45"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>


<script src="./js/contactBookView.js"></script>