import authTokenService from "@/services/authTokenService";
import routeService from "@/services/routeService";

export default {
    components: {},

    props: {

    },

    watch: {
        '$route'(to) {
            this.setActiveLink(to.name)
        },
        "$i18n.locale": function() {
            this.menu[0].title = this.$t('drawer["Les temps de traitement"]')
            this.menu[1].title = this.$t('drawer["Les délais de notifications"]')
        }
    },

    data() {
        return {
            rail: false,
            drawer: true,
            menu: [
                { title: this.$t('drawer["Les temps de traitement"]'), active: false, route: 'processings' },
                { title: this.$t('drawer["Les délais de notifications"]'), active: false, route: 'notifications' },
            ],
        };
    },

    created() {
        if(!authTokenService.getToken()) return;

        this.setActiveLink(this.$route.name)
    },

    mounted() { },

    methods: {
        setActiveLink(routeName) {
            this.menu.forEach(element => {
                element.active = false;
            });

            let processingArray = ['processings', 'processingNew', 'processingDetail',]

            if (processingArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'processings')
                element.active = true;
                return;
            }

            let notificationArray = ['notifications', 'notificationNew', 'notificationDetail',]

            if (notificationArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'notifications')
                element.active = true;
                return;
            }

            let managementElement = this.menu.find(element => element.route == routeName)

            if (managementElement) {
                managementElement.active = true;
                return;
            }
        },

        actionHandler(routeName) {
            routeService.goToRoute(routeName)
        },

        refreshHandler() {
            this.$emit('refreshList', this.$route.name)
        }
    },
};