<template>
  <v-navigation-drawer
    v-if="!rail"
    v-model="drawer"
    :rail="rail"
    location="right"
    @click="close"
    permanent
    class="py-0 my-0"
  >
    <div :class="!rail ? 'mx-2 mt-2' : 'mt-2'">
      <v-btn
        variant="text"
        :icon="!rail ? 'mdi-chevron-right' : ''"
        @click.stop="close"
        class="mx-1"
      ></v-btn>
    </div>

    <v-divider v-if="!rail" class="mb-5"></v-divider>

    <h3 v-if="!rail" class="text-center">
      {{ $t('ticket["Historique"]') }}
    </h3>

    <div class="mt-8" style="max-height: 730px; overflow-y: scroll">
      <div v-for="(history, i) in histories" :key="i" class="border-b pa-1 text-caption">
        <p class="my-1 font-weight-medium">{{ history.createdAt }}</p>
        <p>{{ history.subject }}</p>
        <p>{{ history.detail }}</p>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script src="./js/ticketHistoryDrawer.js"></script>