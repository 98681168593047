<template>
  <v-navigation-drawer
    v-model="drawer"
    :rail="rail"
    permanent
    @click="rail = false"
  >
    <v-row :class="!rail ? 'mx-2 mt-2' : 'mt-2'">
      <v-col :class="!rail ? 'd-flex align-center' : 'd-none'">
        <h4>{{ $t('drawer["Vues"]') }}</h4>
      </v-col>
      <v-col class="d-flex justify-end align-center">
        <v-icon
          size="18"
          :class="!rail ? 'd-flex align-center' : 'd-none'"
          @click="refreshHandler"
          >mdi-refresh</v-icon
        >
        <v-btn
          variant="text"
          :icon="!rail ? 'mdi-chevron-left' : 'mdi-chevron-right'"
          @click.stop="rail = !rail"
          class="mx-1"
        ></v-btn>
      </v-col>
    </v-row>

    <v-divider v-if="!rail" class="mb-5"></v-divider>

    <v-list density="compact" nav v-if="!rail" class="px-5">
      <v-list-item
        v-for="(item, i) in menu"
        :key="i"
        :title="item.title"
        :active="item.active"
        active-color="teal-darken-1"
        @click="actionHandler(item.route)"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script src="./js/settingAppsDrawer.js"></script>