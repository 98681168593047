import authTokenService from "@/services/authTokenService";
import ConfirmAccountForm from "@/components/offline/confirmAccount/ConfirmAccountForm.vue"

export default {
    setup() {

    },

    components: {
        ConfirmAccountForm
    },

    props: {},

    watch: {

    },

    data() {
        return {

        };
    },

    created() {
        if (authTokenService.getToken()) {
            return this.$router.push({ name: 'dashboard', replace: true })
        }
    },

    mounted() { },

    methods: {
        goToLogin() {
            this.$router.push({ name: "home", replace: true })
        }
    },
};