import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/AuthStore";
import { API_URL, OPEN_SNACKBAR } from "@/utils/contants";
import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { logout } from "@/services/logoutService";
import PasswordScore from "@/components/notifications/PasswordScore.vue";

export default {
    setup() {
        const authStore = useAuthStore();

        const {
            avatar,
            lastname,
            firstname,
            email,
            phone1,
            phone2,
            comment,
        } = storeToRefs(authStore)

        return {
            authStore, avatar,
            lastname,
            firstname,
            email,
            phone1,
            phone2,
            comment,
        };
    },

    components: {
        PasswordScore,
    },

    props: {},

    watch: {
        "$i18n.locale": function() {
            this.rules = {
                required: v => !!v || this.$t("form.validation.required"),
                email: v => /.+@.+\..+/.test(v) || this.$t("form.validation.email"),
            }
        }
    },

    data() {
        return {
            loading: false,
            loadingDelete: false,
            passwordScoreValid: false,

            tab: null,
            avatar: this.avatar,
            lastname: this.lastname,
            firstname: this.firstname,
            uploadImage: null,

            oldPassword: null,
            password: null,
            passwordConfirm: null,

            show1: false,
            show2: false,
            show3: false,

            rules: {
                required: v => !!v || this.$t("form.validation.required"),
                email: v => /.+@.+\..+/.test(v) || this.$t("form.validation.email"),
            }
        };
    },

    created() {

    },

    mounted() { },

    methods: {
        getFilePath(file, isRemote = false) {
            if (file == null && isRemote) return `${API_URL}/images/default.jpg`
            if (file != null && isRemote) return `${API_URL}/images/${file}`
            return `${location.origin}/images/${file}`
        },

        preview() {
            return URL.createObjectURL(this.uploadImage);
        },

        createDynamicInputFile() {
            let imageTag = document.createElement('input');
            imageTag.type = "file";
            imageTag.accept = ".jpg, .png, .jpeg";
            imageTag.classList.add("d-none");
            document.body.appendChild(imageTag);
            imageTag.click();

            imageTag.addEventListener("change", function () {
                const fileList = [...this.files];
                setUploadImage(fileList[0])
            })

            const setUploadImage = (file) => {
                this.uploadImage = file;
                imageTag.remove();
            };
        },

        passwordScoreHandler({ score }) {
            if (score < 2) this.passwordScoreValid = false;
            else this.passwordScoreValid = true;
        },

        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) return;

                let dataSend = new FormData();

                if (this.password != null) {
                    if (this.passwordConfirm != this.password) throw new Error("Les mots de passe ne sont pas identiques");
                    if (this.oldPassword == null) throw new Error('Vous devez indiquer votre mot de passe actuel');
                    if (!this.passwordScoreValid) throw new Error('Votre mot de passe n\'est pas assez robuste');

                    dataSend.append("password", this.password == null ? "" : this.password);
                    dataSend.append("passwordConfirm", this.passwordConfirm == null ? "" : this.passwordConfirm);
                    dataSend.append("oldPassword", this.oldPassword == null ? "" : this.oldPassword);
                }

                if (this.uploadImage != null) dataSend.append("avatar", this.uploadImage);
                dataSend.append("lastname", this.lastname == null ? "" : this.lastname);
                dataSend.append("firstname", this.firstname == null ? "" : this.firstname);

                const request = await apiService.postFile("/account", dataSend);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.authStore.avatar = response.info.avatar;
                this.authStore.lastname = response.info.lastname;
                this.authStore.firstname = response.info.firstname;
                this.authStore.role = response.info.role;

                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false });
                if (this.password != null && this.passwordConfirm != "" && this.oldPassword != null) {
                    logout();
                }
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
                this.uploadImage = null;
            } finally {
                this.loading = false;
            }
        },
    },
};