<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="pt-1">
              {{ $t('manager.ticketColor["Choix de la couleur"]') }}
            </h2>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="35" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div class="pa-5 d-flex flex-wrap">
        <div
          v-for="(item, i) in availableColors"
          :key="i"
          :class="`bg-${item.label}`"
          class="rounded-lg py-1 px-2 ma-2"
          style="cursor: pointer"
          @click="toggleSelected(item)"
        >
          <v-icon v-if="item.selected" size="15">mdi-check</v-icon>
          {{ item.label }}
        </div>
      </div>

      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="default"
          class="mr-4 px-5"
          color="grey"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="default"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script src="./js/ticketColorsDialog.js"></script>