<template>
  <div style="margin-top: -5px" class="">
    <TicketColorsDialog
      v-model:dialog="dialogColors"
      :colors="availableData.availableColors"
      :selectedColor="selectedColor"
      :type="selectedPriority"
      @updateColor="updateColor"
    />

    <v-row class="bg-brown-lighten-5 pt-2 px-4">
      <v-col class="d-flex justify-end align-center">
        <v-btn
          size="default"
          color="teal-darken-1"
          :loading="loading"
          :disabled="loading"
          @click="submitHandler"
        >
          <v-icon size="25" class="mr-2">mdi-content-save</v-icon>
          {{ $t(`form.btn["Sauvegarder"]`) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-3"></v-divider>
    <div class="py-lg-6 px-lg-8 py-md-4 px-md-5 pa-4">
      <v-row no-gutters>
        <v-col cols="12" lg="4" md="6" sm="8" class="d-flex align-center">
          <h2>{{ $t(`manager.ticketColor["${title}"]`) }}</h2>
        </v-col>
      </v-row>
      <v-divider class="my-4"></v-divider>
      <v-form ref="form">
        <div style="max-height: 700px; overflow-y: scroll">
          <h3 class="font-weight-medium text-body-1 mt-3">
            {{ $t('manager.ticketColor["Configurez vos couleurs"]') }}
          </h3>
          <p class="text-caption mb-4 mt-1">
            {{
              $t(
                'manager.ticketColor["Ci-dessous, vous pouvez configurer une couleur pour chaque niveau de priorité d\'un ticket"]'
              )
            }}
          </p>

          <div
            v-if="loading"
            class="d-flex justify-center align-center"
            style="height: 40vh"
          >
            <v-progress-circular
              :size="45"
              :width="2"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </div>

          <v-row v-else no-gutters>
            <v-col cols="12" lg="6" md="8">
              <v-card elevation="0" border="" class="pa-3 mt-2">
                <v-row class="mb-1">
                  <v-col>
                    <p class="font-weight-medium">
                      {{ $t('manager.ticketColor["Haute priorité"]') }}
                    </p>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      size="small"
                      color="teal-darken-1"
                      @click="openDialog('high')"
                    >
                      <v-icon class="mr-2">mdi-pencil</v-icon>
                      {{ $t('form.btn["Modifier"]') }}
                    </v-btn>
                  </v-col>
                </v-row>

                <div
                  v-if="availableData.priorityColors.high != ''"
                  :class="`bg-${availableData.priorityColors.high}`"
                  class="rounded-lg py-1 px-2"
                  style="max-width: 150px"
                >
                  <v-icon size="15">mdi-check</v-icon>
                  {{ availableData.priorityColors.high }}
                </div>
                <div v-else>
                  <p class="text-caption">
                    {{ $t('manager.ticketColor["Aucune couleur choisie"]') }}
                  </p>
                </div>
              </v-card>

              <v-card elevation="0" border="" class="pa-3 mt-2">
                <v-row class="mb-1">
                  <v-col>
                    <p class="font-weight-medium">
                      {{ $t('manager.ticketColor["Moyenne priorité"]') }}
                    </p>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      size="small"
                      color="teal-darken-1"
                      @click="openDialog('medium')"
                    >
                      <v-icon class="mr-2">mdi-pencil</v-icon>
                      {{ $t('form.btn["Modifier"]') }}
                    </v-btn>
                  </v-col>
                </v-row>

                <div
                  v-if="availableData.priorityColors.medium != ''"
                  :class="`bg-${availableData.priorityColors.medium}`"
                  class="rounded-lg py-1 px-2"
                  style="max-width: 150px"
                >
                  <v-icon size="15">mdi-check</v-icon>
                  {{ availableData.priorityColors.medium }}
                </div>
                <div v-else>
                  <p class="text-caption">
                    {{ $t('manager.ticketColor["Aucune couleur choisie"]') }}
                  </p>
                </div>
              </v-card>

              <v-card elevation="0" border="" class="pa-3 mt-2">
                <v-row class="mb-1">
                  <v-col>
                    <p class="font-weight-medium">
                      {{ $t('manager.ticketColor["Basse priorité"]') }}
                    </p>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      size="small"
                      color="teal-darken-1"
                      @click="openDialog('low')"
                    >
                      <v-icon class="mr-2">mdi-pencil</v-icon>
                      {{ $t('form.btn["Modifier"]') }}
                    </v-btn>
                  </v-col>
                </v-row>

                <div
                  v-if="availableData.priorityColors.low != ''"
                  :class="`bg-${availableData.priorityColors.low}`"
                  class="rounded-lg py-1 px-2"
                  style="max-width: 150px"
                >
                  <v-icon size="15">mdi-check</v-icon>
                  {{ availableData.priorityColors.low }}
                </div>
                <div v-else>
                  <p class="text-caption">
                    {{ $t('manager.ticketColor["Aucune couleur choisie"]') }}
                  </p>
                </div>
              </v-card>

              <v-card elevation="0" border="" class="pa-3 mt-2">
                <v-row class="mb-1">
                  <v-col>
                    <p class="font-weight-medium">
                      {{ $t('manager.ticketColor["Faible priorité"]') }}
                    </p>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      size="small"
                      color="teal-darken-1"
                      @click="openDialog('very low')"
                    >
                      <v-icon class="mr-2">mdi-pencil</v-icon>
                      {{ $t('form.btn["Modifier"]') }}
                    </v-btn>
                  </v-col>
                </v-row>

                <div
                  v-if="availableData.priorityColors['very low'] != ''"
                  :class="`bg-${availableData.priorityColors['very low']}`"
                  class="rounded-lg py-1 px-2"
                  style="max-width: 150px"
                >
                  <v-icon size="15">mdi-check</v-icon>
                  {{ availableData.priorityColors["very low"] }}
                </div>
                <div v-else>
                  <p class="text-caption">
                    {{ $t('manager.ticketColor["Aucune couleur choisie"]') }}
                  </p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script src="./js/ticketRowColorsSettingForm.js"></script>