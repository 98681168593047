import authTokenService from "@/services/authTokenService";
import routeService from "@/services/routeService";

export default {
    components: {},

    props: {

    },

    watch: {
        '$route'(to) {
            this.setActiveLink(to.name)
        },
    },

    data() {
        return {
            rail: false,
            drawer: true,
            menu: [
                { title: 'Toutes les fonctions', active: false, route: 'jobsManagement' },
                { title: 'Tous les utilisateurs', active: false, route: 'usersManagement' },
                { title: 'Tous les groupes', active: false, route: 'groupsManagement' },
                { title: 'Vue agrégé des groupes', active: false, route: 'groupsManagementAggregated' },
            ],
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.setActiveLink(this.$route.name)
    },

    mounted() { },

    methods: {
        setActiveLink(routeName) {
            this.menu.forEach(element => {
                element.active = false;
            });

            let jobsArray = ['jobsManagement', 'jobsManagementDetail', 'jobsManagementNew']

            if (jobsArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'jobsManagement')
                element.active = true;
                return;
            }

            let groupArray = ['groupsManagement', 'groupsManagementDetail', 'groupsManagementNew']

            if (groupArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'groupsManagement')
                element.active = true;
                return;
            }

            let userArray = ['usersManagementDetail', 'usersManagement', 'usersManagementNew']

            if (userArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'usersManagement')
                element.active = true;
                return;
            }

            let managementElement = this.menu.find(element => element.route == routeName)

            if (managementElement) {
                managementElement.active = true;
                return;
            }
        },

        actionHandler(routeName) {
            routeService.goToRoute(routeName)
        },

        refreshHandler() {
            this.$emit('refreshList', this.$route.name)
        }
    },
};