import { TOKEN_NAME } from "@/utils/contants";

const authTokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_NAME);
    },
    setToken(token) {
        localStorage.setItem(TOKEN_NAME, token);
    },
    clearAuth() {
        localStorage.clear();
    }
}

export default authTokenService;