import routeService from "@/services/routeService";

export default {
    components: {},

    props: {

    },

    watch: {
        '$route'(to) {
            this.setActiveLink(to.name)
        },
        "$i18n.locale": function() {
            this.menu[0].title = this.$t('drawer["Template builder"]')
        }
    },

    data() {
        return {
            rail: false,
            drawer: true,
            menu: [
                { title: this.$t('drawer["Template builder"]'), active: false, route: 'templates' },
            ],
        };
    },

    created() {
        this.setActiveLink(this.$route.name)
    },

    mounted() { },

    methods: {
        setActiveLink(routeName) {
            this.menu.forEach(element => {
                element.active = false;
            });

            let templateArray = ['templates', 'templatesNew', 'templatesDetail',]

            if (templateArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'templates')
                element.active = true;
                return;
            }

            let managementElement = this.menu.find(element => element.route == routeName)

            if (managementElement) {
                managementElement.active = true;
                return;
            }
        },

        actionHandler(routeName) {
            routeService.goToRoute(routeName)
        },

        refreshHandler() {
            this.$emit('refreshList', this.$route.name)
        }
    },
};