<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">
              {{ $t('dialog["Importer des templates"]') }}
            </h3>
            <p class="text-caption">
              {{
                $t(
                  `dialog["Cette interface vous permet d'importer des templates suivant divers paramètres."]`
                )
              }}
            </p>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>
      <v-form ref="form" class="px-5">
        <v-row no-gutters>
          <v-col
            cols="12"
            lg=""
            md=""
            sm="6"
            class="d-flex align-center justify-center"
          >
            <v-file-input
              v-model="importFile"
              :rules="[rules.required]"
              variant="outlined"
              density="compact"
              hide-details=""
              prepend-icon=""
              prepend-inner-icon="mdi-upload"
              clearable
              :label="$t(`form.label[&quot;Importer le template&quot;]`)"
              accept="application/json"
            ></v-file-input>
          </v-col>
          <v-col
            cols="12"
            lg=""
            md=""
            sm="6"
            class="d-flex align-center justify-center"
          >
            <v-btn
              size="small"
              href="/template/example_template_import.json"
              download
              color="grey"
              class="my-2"
            >
              <v-icon>mdi-download</v-icon>
              {{ $t('form.btn["Télécharger le template"]') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          :disabled="loading"
          :loading="loading"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/importTicketTemplateDialog.js"></script>