import DeleteDialog from "@/components/online/manager/accessManagement/dialogs/DeleteDialog.vue";
import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    components: {
        DeleteDialog,
    },

    props: {

    },

    watch: {
        '$route'(to) {
            this.title = to.params.id ? "" : "Ajout d'un grade";
            this.frontInfo = null;

            if (to.name == 'gradeNew') {
                this.$refs.form.reset();
                this.isDisabled = false;
                this.isDisabledBtn = false;
            } else if (to.name == 'gradeDetail') {
                this.isDisabled = true;
                this.isDisabledBtn = true;
                this.retrieveSingle();
            }
        },
        "$i18n.locale": function() {
            this.rules = { required: v => !!v || this.$t('form.validation.required'), }
        }
    },

    data() {
        return {
            loading: false,
            isDisabled: false,
            isDisabledBtn: false,
            loadingDelete: false,
            dialogDelete: false,

            title: this.$route.params.id ? "" : "Ajout d'un grade",
            frontInfo: null,
   
            label: null,
            rules: { required: v => !!v || this.$t('form.validation.required'), }
        };
    },

    created() {
        if(!authTokenService.getToken()) return;

        if (!this.$route.params.id) {
            this.isDisabledBtn = false;
        }

        if (this.$route.params.id) {
            this.isDisabled = true;
            this.isDisabledBtn = true;
            this.retrieveSingle();
        }
    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.push({ name: 'grades', replace: true });
        },

        enableEdition() {
            this.isDisabled = !this.isDisabled;
            this.isDisabledBtn = !this.isDisabledBtn;
        },

        async retrieveSingle() {
            this.loading = true;
            try {
                const request = await apiService.get(`/grades/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.frontInfo = response.data.label;
                this.label = response.data.label;
            } catch (error) {
                this.$router.push({ name: "grades", replace: true });
            } finally {
                this.loading = false;
            }
        },

        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');

                const url = this.$route.params.id ? `/grades/${this.$route.params.id}` : `/grades`;
                const request = await apiService.post(url, { label: this.label });
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$router.push({ name: 'gradeDetail', params: { id: response.infos.id }, replace: true })
                } else {
                    this.frontInfo = "";
                    this.retrieveSingle();
                }
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
                if (this.$route.params.id) {
                    this.isDisabledBtn = true;
                    this.isDisabled = true;
                } else {
                    this.isDisabledBtn = false;
                }
            }
        },

        async deleteHandler() {
            this.loadingDelete = true;
            try {
                const request = await apiService.delete(`/grades/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.$router.push({ name: "grades", replace: true });
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};