import { defineStore } from "pinia"

export const useAuthStore = defineStore("AuthStore", {
    state: () => {
        return {
            avatar: null,
            lastname: null,
            firstname: null,
            email: null,
            role: null,
            organisation: null,
        }
    },

    getters: {},

    actions: {},

    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage,
                paths: [
                    'avatar', 'lastname', 'firstname', 'email', "role", "organisation"
                ]
            },
        ],
    }
})