import { useAuthStore } from "@/stores/AuthStore";
import routeService from "@/services/routeService";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        const authStore = useAuthStore();
        return { authStore };
    },

    components: {},

    props: {

    },

    watch: {
        '$route'(to) {
            this.setActiveLink(to.name)
        },
        "$i18n.locale": function () {
            this.setCurrentMenu()
            this.setActiveLink(this.$route.name)
        }
    },

    data() {
        return {
            rail: false,
            drawer: true,
            menu: [],
        };
    },

    created() {
        if (!authTokenService.getToken()) return;
        this.setCurrentMenu()
        this.setActiveLink(this.$route.name)
    },

    mounted() { },

    methods: {
        setCurrentMenu() {
            switch (this.authStore.role) {
                case "admin":
                    this.menu = [
                        { title: this.$t('drawer["Tous les motifs"]'), active: false, route: 'reasons' },
                        { title: this.$t('drawer["Tous les tags"]'), active: false, route: 'tags' },
                        { title: this.$t('drawer["Tous les grades"]'), active: false, route: 'grades' },
                        { title: this.$t('drawer["Toutes les sources"]'), active: false, route: 'sources' },
                        { title: this.$t('drawer["Intégrations"]'), active: false, route: 'integrations' },
                    ];
                    break;
                case "manager":
                    this.menu = [
                        { title: this.$t('drawer["Couleur par priorité"]'), active: false, route: 'ticketSettings' },
                        { title: this.$t('drawer["Tous les motifs"]'), active: false, route: 'reasons' },
                        { title: this.$t('drawer["Intégrations"]'), active: false, route: 'integrations' },
                    ];
                    break;
                default:
                    break;
            }
        },

        setActiveLink(routeName) {
            this.menu.forEach(element => {
                element.active = false;
            });

            let sourceArray = ['sources', 'sourceNew', 'sourceDetail',]

            if (sourceArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'sources')
                element.active = true;
                return;
            }

            let reasonArray = ['reasons', 'reasonNew', 'reasonDetail',]

            if (reasonArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'reasons')
                element.active = true;
                return;
            }

            let tagArray = ['tags', 'tagNew', 'tagDetail',]

            if (tagArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'tags')
                element.active = true;
                return;
            }

            let gradeArray = ['grades', 'gradeNew', 'gradeDetail',]

            if (gradeArray.includes(routeName)) {
                let element = this.menu.find(element => element.route == 'grades')
                element.active = true;
                return;
            }

            let managementElement = this.menu.find(element => element.route == routeName)

            if (managementElement) {
                managementElement.active = true;
                return;
            }
        },

        actionHandler(routeName) {
            routeService.goToRoute(routeName)
        },

        refreshHandler() {
            this.$emit('refreshList', this.$route.name)
        }
    },
};