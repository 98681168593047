
import TicketTemplateBuilderList from "@/components/online/admin/builder/lists/TicketTemplateBuilderList.vue";
import TicketTemplateBuilder from "@/components/online/admin/builder/forms/TicketTemplateBuilder.vue";
import SettingTemplateDrawer from "@/components/online/admin/drawer/SettingTemplateDrawer.vue";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        SettingTemplateDrawer,
        TicketTemplateBuilderList,
        TicketTemplateBuilder,
    },

    props: {},

    watch: {
        '$route'(to) {
            this.setCurrentView(to.meta)
        },
    },

    data() {
        return {
            displayType: 'templates',
            refreshTemplates: false,
            refreshBuilders: false,
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.setCurrentView(this.$route.meta)
    },

    mounted() { },

    methods: {
        setCurrentView({ displayType }) {
            this.displayType = displayType
        },

        stopRefresh() {
            this.refreshTemplates = false;
            this.refreshBuilders = false;
        },

        refreshList(routeName) {
            switch (routeName) {
                case "templates":
                    this.refreshTemplates = true;
                    break;
                case "builders":
                    this.refreshBuilders = true;
                    break;
                default:
                    break;
            }
        }
    },
};