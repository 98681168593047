import LoginView from "@/views/offline/LoginView.vue"
import PasswordResetView from "@/views/offline/PasswordResetView.vue"
import ConfirmAccountView from "@/views/offline/ConfirmAccountView.vue"

const defaultRoutes = [
    {
        path: '/',
        name: 'home',
        component: LoginView
    },
    {
        path: '/reset',
        name: 'passwordReset',
        component: PasswordResetView
    },
    {
        path: '/confirmation/:token',
        name: 'confirmAccount',
        component: ConfirmAccountView
    },
];

export default defaultRoutes;