export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        field: {
            default: null
        },
        isDisabled: {
            default: null
        }
    },

    watch: {
        dialog(val) {
            if(val) this.fieldEdit = this.field
        }
    },

    data() {
        return {
            cardWidth: 500,
            fieldEdit: null,
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 500;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        actionHandler() {
            this.$emit('updateOneField', this.fieldEdit)
            this.$emit('update:dialog', false)
        }
    },
};