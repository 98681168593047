import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {
        "$i18n.locale": function() {
            this.rules = { required: v => !!v || this.$t('form.validation.required') }
        }
    },

    data() {
        return {
            cardWidth: 500,
            loadingSubmit: false,
            content: null,
            rules: { required: v => !!v || this.$t('form.validation.required') }
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 500;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        async submitHandler() {
            this.loadingSubmit = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');
                if(this.content.trim() == "") throw new Error('Des champs sont manquants');
                const request = await apiService.post(`/tickets/${this.$route.params.id}/email`, { body: this.content.trim() });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false,})
                this.$refs.form.reset();
                this.$emit("refreshSingle");
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingSubmit = false;
            }
        },
    },
};