import { defineStore } from "pinia"

export const useTicketListConfigStore = defineStore("TicketListConfigStore", {
    state: () => {
        return {
            ticketListConfig: []
        }
    },

    getters: {},

    actions: {},

    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage,
                paths: [
                    'ticketListConfig',
                ]
            },
        ],
    }
})