import authTokenService from "./authTokenService";
import { API_URL } from "@/utils/contants";

const headers = () => {
    const authHeaders = authTokenService.getToken() 
    ? { Authorization: "Bearer " + authTokenService.getToken() }
    : {}
    return {
        ...authHeaders,
        "Content-Type": "application/json"
    }
}

const headersFile = () => {
    const authHeaders = authTokenService.getToken() 
    ? { Authorization: "Bearer " + authTokenService.getToken() }
    : {}
    return { ...authHeaders }
}

const apiService = {
    get(url) {
        return fetch(API_URL + url, {
            method: "GET",
            headers: headers(),
        })
    },

    post(url, data = {}) {
        return fetch(API_URL + url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify(data)
        })
    },

    postFile(url, data) {
        return fetch(API_URL + url, {
            method: "POST",
            headers: headersFile(),
            body: data
        })
    },

    put(url, data = {}) {
        return fetch(API_URL + url, {
            method: "PUT",
            headers: headers(),
            body: JSON.stringify(data)
        })
    },

    delete(url) {
        return fetch(API_URL + url, {
            method: "DELETE",
            headers: headers(),
        })
    },
}

export default apiService;