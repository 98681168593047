<template>
  <div>
    <AccessManagementDrawer @refreshList="refreshList" />

    <UserAccessManagement
      v-if="displayType == 'userAccessList'"
      :refreshList="refreshUsers"
      @stopRefresh="stopRefresh"
    />

    <UserManagementForm v-if="displayType == 'userAccessForm'" />

    <GroupAccessManagement
      v-if="displayType == 'groupAccessList'"
      :refreshList="refreshGroups"
      @stopRefresh="stopRefresh"
    />

    <GroupManagementForm v-if="displayType == 'groupAccessForm'" />

    <UserJobsList
      v-if="displayType == 'jobAccessList'"
      :refreshList="refreshJobs"
      @stopRefresh="stopRefresh"
    />

    <UserJobsForm v-if="displayType == 'jobAccessForm'" />

    <GroupAccessManagementAggregate v-if="displayType == 'groupAccessListAggregated'" />
  </div>
</template>

<script src="./js/accessManagementView.js"></script>