import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { useAuthStore } from "@/stores/AuthStore";
import { useTicketListConfigStore } from "@/stores/TicketListConfigStore";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {

    },

    components: {
        
    },

    props: {},

    watch: {
        "$i18n.locale": function() {
            this.rules = { required: v => !!v || this.$t('form.validation.required') }
        }
    },

    data() {
        return {
            loading: false,
            login: null,
            password: null,
            rules: { required: v => !!v || this.$t('form.validation.required') }
        };
    },

    created() {

    },

    mounted() { },

    methods: {
        async submitHandler() {
            const authStore = useAuthStore();
            const ticketConfigStore = useTicketListConfigStore();

            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) return;

                const request = await apiService.post("/login", {
                    login: this.login,
                    password: this.password,
                });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                authStore.avatar = response.info.avatar;
                authStore.lastname = response.info.lastname;
                authStore.firstname = response.info.firstname;
                authStore.email = response.info.email;
                authStore.role = response.info.role;
                authStore.organisation = response.organisation;
                ticketConfigStore.ticketListConfig = response.info.dynamicData.ticketListConfig;

                authTokenService.setToken(response.token);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false });
                eventBus.$emit('updateLayout', 'loading-connection');
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
};