import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
    setup() {

    },

    components: {
        Bar
    },

    props: {
        title: {
            default: ''
        },
        dataChart: {
            default: () => {
                return {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: [],
                            data: [],
                        }
                    ]
                }
            }
        },
    },

    watch: {
        "$i18n.locale": function() {
            this.translateDataChart();
        }
    },

    data() {
        return {
            frLabels: [],
            dataChartEl: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: [],
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            }
        }
    },

    created() {
        this.dataChartEl = this.dataChart
        this.translateDataChart();
    },

    mounted() { },

    methods: {
        translateDataChart() {
            if (this.$i18n.locale == "en") {
                if (this.frLabels.length == 0) {
                    this.frLabels = this.dataChart.labels;
                } else {
                    this.dataChartEl.labels = this.frLabels;
                }

                let translateLabel = [];

                this.dataChart.labels.forEach(label => {
                    translateLabel.push(this.$t(`fields["${label}"]`))
                })

                this.dataChartEl = {
                    labels: translateLabel,
                    datasets: this.dataChart.datasets
                }

            } else {
                this.dataChartEl = {
                    labels: this.dataChart.labels,
                    datasets: this.dataChart.datasets
                }
            }
        },
    },
};