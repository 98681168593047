<template>
  <div>
    <div style="width: 80px">
      <v-btn
        v-for="(lang, index) in langs"
        :key="index"
        elevation="0"
        height="30"
        class="pa-0"
        size="small"
        icon=""
        @click="switchLang(lang.code)"
      >
        <img
          :src="getFilePath(lang.flag)"
          :alt="lang.flag"
          style="object-fit: cover"
          width="30"
          height="30"
        />
        <v-tooltip activator="parent" location="bottom">
          {{ $t(`tooltips.language[${index}]`) }}
        </v-tooltip>
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: "switch-language",
  data() {
    return {
      langs: [
        { code: "en", text: "anglais", flag: "english_flag.png" },
        { code: "fr", text: "français", flag: "french_flag.png" },
      ],
    };
  },
  methods: {
    getFilePath(file) {
      return `${location.origin}/images/${file}`;
    },
    switchLang(language) {
      this.$i18n.locale = language;
      localStorage.setItem("lang", this.$i18n.locale);
    },
  },
};
</script>