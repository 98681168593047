import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        group: {
            default: null,
        },
        isDisabled: {
            default: false
        }
    },

    watch: {
        dialog(val) {
            if (val) this.retrieveGroups();
        },
        "$i18n.locale": function() {
            this.rules = { required: v => !!v || this.$t('form.validation.required') }
        }
    },

    data() {
        return {
            loading: false,
            cardWidth: 500,
            availableGroups: [],
            groupEdit: null,
            rules: { required: v => !!v || this.$t('form.validation.required') }
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 500;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        async actionHandler() {
            try {
                const request = await apiService.put(`/form-templates/${this.$route.params.id}`, { group: this.groupEdit.id });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.$emit("refresh")
                this.$emit('update:dialog', false)
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },

        async retrieveGroups() {
            this.loading = true;
            try {
                const request = await apiService.get(`/backoffice/groups`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableGroups = response.data;
                this.groupEdit = this.group;
            } catch (error) {
                this.availableGroups = []
            } finally {
                this.loading = false;
            }
        },
    },
};