<template>
  <div class="py-lg-4 px-lg-8 py-md-2 px-md-5 pa-4">
    <HideShowReportingDialog
      v-if="!loading"
      v-model:dialog="dialogHideShow"
      :stats="stats"
      @updateDashboard="updateDashboard"
      @cancel="retrieveDashboard"
    />

    <ExportReportingDialog v-if="!loading" v-model:dialog="dialogExport" />

    <MoreFiltersDialog
      v-if="!loading"
      v-model:dialog="dialogMoreFilters"
      :agent="agentFilter"
      :source="sourceFilter"
      :complexity="complexityFilter"
      :priority="priorityFilter"
      @updateFilter="updateFilter"
    />

    <v-card
      v-if="generalProcessing != null && !hideAlert"
      :color="alertProcessing.color"
    >
      <div class="px-4 py-2 d-flex justify-space-between">
        <div>
          <v-icon class="mr-4">{{ alertProcessing.icon }}</v-icon>
          <span class="text-caption">{{
            $t(`text["${alertProcessing.label}"]`)
          }}</span>
        </div>
        <div>
          <v-icon @click="hideAlert = true">mdi-close</v-icon>
        </div>
      </div>
    </v-card>

    <v-row no-gutters class="mb-10 mt-3">
      <v-col cols="12" lg="" md="" class="d-flex flex-column">
        <h2 class="font-weight-regular mr-2 mb-4" style="width: 250px">
          {{ $t('text["Tableau de bord"]') }}
          <v-icon
            v-if="!loading"
            color="teal-darken-1"
            size="22"
            @click="retrieveDashboard"
            >mdi-refresh</v-icon
          >
        </h2>
        <v-row no-gutters class="mt-2" style="max-width: 400px" v-if="!loading">
          <v-col class="d-flex align-center">
            <v-icon size="30" class="mr-2">mdi-calendar-month</v-icon>
            <p class="mr-2">{{ $t('text["Du"]') }}</p>
            <input v-model="startDate" type="date" class="border px-2 py-1" />
            <p class="mx-2">{{ $t('text["au"]') }}</p>
            <input v-model="endDate" type="date" class="border px-2 py-1" />
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-2" style="max-width: 400px" v-if="!loading">
          <v-col class="d-flex align-center">
            <v-icon size="30" class="mr-2">mdi-filter</v-icon>
            <v-select
              v-model="selectedReason"
              :items="availableReasons"
              item-title="label"
              item-value="label"
              density="compact"
              hide-details=""
              variant="outlined"
            ></v-select>
          </v-col>
        </v-row>

        <div style="max-width: 400px" class="d-flex justify-end mt-2 flex-wrap">
          <v-btn
            v-if="!loading"
            size="small"
            color="teal-darken-1"
            class="ma-1"
            @click="dialogMoreFilters = true"
          >
            <v-icon>mdi-filter</v-icon>
            {{ $t('text["Plus de filtres"]') }}</v-btn
          >
          <v-btn
            v-if="!loading"
            size="small"
            color="teal-darken-1"
            class="ma-1"
            @click="retrieveDashboard"
            >{{ $t('text["Rechercher"]') }}</v-btn
          >
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-lg-end mt-3" v-if="!loading">
          <v-btn
            v-for="(item, i) in btnActions"
            :key="i"
            @click="actionHandler(item.type, item.value)"
            size="small"
            class="mx-lg-2 mx-md-2 text-caption"
            color="teal-darken-1"
            :class="i == 0 ? 'mr-2' : ''"
          >
            {{ $t(`text["${item.title}"]`) }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <div style="height: 40vh" v-if="loading">
      <LoadingComponent />
    </div>

    <div v-if="!loading">
      <div v-for="(stat, index) in stats" :key="index" class="px-1">
        <v-row class="px-1 my-1">
          <v-col
            v-for="(row1, rowIndex1) in stat.row"
            :key="rowIndex1"
            cols="12"
            lg=""
            :md="rowIndex1 == 1 ? '12' : ''"
            :class="setClasses(row1)"
          >
            <template v-if="!row1.column[0].row">
              <template v-if="row1.column.length > 1">
                <v-row class="px-1">
                  <v-col
                    cols="12"
                    lg=""
                    md="12"
                    class="border my-1"
                    v-for="(row2, rowIndex2) in row1.column"
                    :key="rowIndex2"
                    :class="row2.show ? 'mx-lg-1' : 'd-none'"
                  >
                    <DisplayStatType
                      v-if="row2.show"
                      :title="row2.title"
                      :type="row2.type"
                      :data="row2.data"
                      :options="row2.options"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <DisplayStatType
                  v-if="row1.column[0].show"
                  :title="row1.column[0].title"
                  :type="row1.column[0].type"
                  :data="row1.column[0].data"
                  :options="row1.column[0].options"
                />
              </template>
            </template>

            <v-row
              v-else
              v-for="(row3, rowIndex3) in row1.column"
              :key="rowIndex3"
            >
              <v-col
                cols="12"
                lg=""
                md=""
                class="border ma-1"
                v-for="(row4, rowIndex4) in row3.row"
                :key="rowIndex4"
                :class="row4.show ? '' : 'd-none'"
              >
                <DisplayStatType
                  v-if="row4.show"
                  :title="row4.title"
                  :type="row4.type"
                  :data="row4.data"
                  :options="row4.options"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script src="./js/dashboardView.js"></script>