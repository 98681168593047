<template>
  <v-app-bar
    color="white"
    density="compact"
    elevation="0"
    border=""
    class="py-1"
  >
    <template v-slot:prepend>
      <div class="mr-lg-16 pr-lg-16 mr-md-16 pr-md-16 mr-sm-16 pr-sm-16">
        <h4 class="mx-5 text-teal-darken-1">SWILK</h4>
      </div>

      <v-btn
        size="small"
        class="mx-2 border"
        v-for="(item, i) in items"
        :key="i"
        @click="actionHandler(item.type, item.value)"
      >
        <v-icon class="mr-1">{{ item.icon }} </v-icon>
        {{ $t(`text["${item.title}"]`) }}
      </v-btn>
    </template>

    <template v-slot:append>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <SwitchLanguage />
          <v-avatar
            v-bind="props"
            class="mx-4"
            size="40"
            color="grey"
            style="cursor: pointer"
          >
            <v-img :src="getFilePath(avatar, true)" alt="avatar" cover></v-img>
          </v-avatar>
        </template>

        <v-card min-width="300">
          <div class="d-flex align-center">
            <div class="py-3">
              <v-avatar class="ml-4" size="50" color="grey">
                <v-img
                  :src="getFilePath(avatar, true)"
                  alt="avatar"
                  cover
                ></v-img>
              </v-avatar>
            </div>
            <div class="pl-4">
              <p class="ma-0 font-weight-bold">
                {{ `${firstname ?? "--"} ${lastname ?? "--"}` }}
              </p>
              <v-btn
                class="px-0"
                variant="text"
                color="black"
                size="x-small"
                @click="actionHandler('route', 'profil')"
                >{{ $t('text["Afficher le profil"]') }}</v-btn
              >
            </div>
          </div>

          <v-divider></v-divider>

          <v-list density="compact" class="my-0 py-0">
            <v-list-item @click="logoutHandler">
              <v-list-item-title style="font-size: 15px">
                {{ $t('text["Déconnexion"]') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script src="./js/appBarComponent.js"></script>