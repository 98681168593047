<template>
  <v-form ref="form">
    <div>
      <label for="login" class="font-weight-bold">{{ $t('form.label["Identifiant"]') }}</label>
      <v-text-field
        id="login"
        v-model="login"
        required
        variant="outlined"
        density="compact"
        class="my-2"
        placeholder="example"
        :rules="[rules.required]"
      ></v-text-field>
    </div>
    <div>
      <label for="password" class="font-weight-bold">{{ $t('form.label["Mot de passe"]') }}</label>
      <v-text-field
        id="password"
        v-model="password"
        required
        variant="outlined"
        density="compact"
        class="my-2"
        placeholder="*****"
        :rules="[rules.required]"
        type="password"
      ></v-text-field>
    </div>
    <div class="px-2 mt-5">
      <v-btn
        block
        color="teal-darken-1"
        :loading="loading"
        :disabled="loading"
        @click="submitHandler"
        >{{ $t('form.btn["Connexion"]') }}</v-btn
      >
    </div>
  </v-form>
</template>

<script src="./js/loginForm.js"></script>