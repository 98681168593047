export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        loading: {
            default: false,
        },
        title: {
            default: ''
        },
        text: {
            default: ''
        }
    },

    watch: {},

    data() {
        return {
            cardWidth: 500,
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 500;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        actionHandler() {
            this.$emit("confirmDelete")
        }
    },
};