import eventBus from "@/eventBus";
import apiService from "./apiService";
import authTokenService from "./authTokenService";

export const logout = async() => {
    try {
        eventBus.$emit('updateLayout', 'loading-disconnection');
        await apiService.get("/logout");
    } catch (error) {
        eventBus.$emit('updateLayout', 'loading-disconnection');
        authTokenService.clearAuth();
    } 
}