import LoadingComponent from "@/components/general/loading/LoadingComponent.vue"

export default {
    components: {
        LoadingComponent,
    },

    props: {},

    watch: {

    },

    data() {
        return {

        };
    },

    created() {
        setTimeout(() => {
            this.$router.go(-1)
        }, 2000)
    },

    mounted() { },

    methods: {

    },
};