import UserAccessManagement from "@/components/online/manager/accessManagement/list/UserAccessManagement.vue";
import GroupAccessManagement from "@/components/online/manager/accessManagement/list/GroupAccessManagement.vue";
import GroupAccessManagementAggregate from "@/components/online/manager/accessManagement/list/GroupAccessManagementAggregate.vue";
import AccessManagementDrawer from "@/components/online/manager/accessManagement/drawer/AccessManagementDrawer.vue";
import GroupManagementForm from "@/components/online/manager/accessManagement/forms/GroupManagementForm.vue";
import UserManagementForm from "@/components/online/manager/accessManagement/forms/UserManagementForm.vue";
import UserJobsList from "@/components/online/admin/userJobs/list/UserJobsList.vue";
import UserJobsForm from "@/components/online/admin/userJobs/form/UserJobsForm.vue";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        UserAccessManagement,
        GroupAccessManagementAggregate,
        GroupAccessManagement,
        AccessManagementDrawer,
        GroupManagementForm,
        UserManagementForm,
        UserJobsList,
        UserJobsForm,
    },

    props: {},

    watch: {
        '$route'(to) {
            this.setCurrentView(to.meta)
        },
    },

    data() {
        return {
            displayType: 'userAccessList',
            refreshUsers: false,
            refreshGroups: false,
            refreshJobs: false,
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.setCurrentView(this.$route.meta)
    },

    mounted() { },

    methods: {
        setCurrentView({ displayType }) {
            this.displayType = displayType
        },

        stopRefresh() {
            this.refreshUsers = false;
            this.refreshGroups = false;
            this.refreshJobs = false;
        },

        refreshList(routeName) {
            switch (routeName) {
                case "usersManagement":
                    this.refreshUsers = true;
                    break;
                case "groupsManagement":
                    this.refreshGroups = true;
                    break;
                case "jobsManagement":
                    this.refreshJobs = true;
                    break;
                default:
                    break;
            }
        }
    },
};