
import ProcessingTimeList from "@/components/online/admin/processingTimes/list/ProcessingTimeList.vue";
import ProcessingTimeForm from "@/components/online/admin/processingTimes/form/ProcessingTimeForm.vue";
import TicketNotificationList from "@/components/online/admin/ticketNotifications/list/TicketNotificationList.vue";
import TicketNotificationForm from "@/components/online/admin/ticketNotifications/form/TicketNotificationForm.vue";
import SettingTimesDrawer from "@/components/online/admin/drawer/SettingTimesDrawer.vue";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        SettingTimesDrawer,
        ProcessingTimeList,
        ProcessingTimeForm,
        TicketNotificationList,
        TicketNotificationForm,
    },

    props: {},

    watch: {
        '$route'(to) {
            this.setCurrentView(to.meta)
        },
    },

    data() {
        return {
            displayType: 'reasonsList',
            refreshProcessings: false,
            refreshNotifications: false,
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.setCurrentView(this.$route.meta)
    },

    mounted() { },

    methods: {
        setCurrentView({ displayType }) {
            this.displayType = displayType
        },

        stopRefresh() {
            this.refreshProcessings = false;
            this.refreshNotifications = false;
        },

        refreshList(routeName) {
            switch (routeName) {
                case "processings":
                    this.refreshProcessings = true;
                    break;
                case "notifications":
                    this.refreshNotifications = true;
                    break;
                default:
                    break;
            }
        }
    },
};