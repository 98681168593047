export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {

    },

    data() {
        return {
            cardWidth: 600,
            localUrl: location.origin
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.$emit('update:dialog', false)
        },
    },
};