import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";
import { OPEN_SNACKBAR } from "@/utils/contants";

import TransfertGroupRecursive from "@/components/online/tickets/dialogs/TransfertGroupRecursive.vue"

export default {
    setup() {
        const authStore = useAuthStore();
        return { authStore };
    },

    components: {
        TransfertGroupRecursive
    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {
        dialog(val) {
            this.availableGroups = [];
            this.availableAgents = [];

            if (val) {
                this.retrieveGroups()
            }
        },
        isGroup(val) {
            if (val) {
                this.retrieveGroups()
            } else {
                this.retrieveAgents()
            }
        },
        "$i18n.locale": function () {
            this.headers = [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: '', key: 'data-table-expand' },
                { title: this.$t('datatable.header["Nom du groupe"]'), key: 'label' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ];
        },
    },

    data() {
        return {
            cardWidth: 700,
            isGroup: true,
            loading: false,
            loadingSubmit: false,
            search: null,
            selectedAgent: null,
            selectedGroup: null,
            selectedParentGroup: null,
            availableGroups: [],
            availableAgents: [],
            sector: this.authStore.organisation.sector,

            headers: [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: '', key: 'data-table-expand' },
                { title: this.$t('datatable.header["Nom du groupe"]'), key: 'label' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ],
            expanded: [],
            singleExpand: false,
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
        eventBus.$on("forwardItem", this.forwardItem)
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 700;
        },

        toggleSelected(val) {
            this.isGroup = val;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        getTitleString(item) {
            if(item.userJob != null) return `${item.lastname} ${item.firstname} -  ${item.userJob.label}`
            return `${item.lastname} ${item.firstname}`
        },

        recursiveGroupTree(data, parentGroup) {
            parentGroup.forEach(element => {
                let child = data.filter(child => child.parent != null && child.parent.id == element.id)
                element.showChildren = false;
                element.children = child;
                this.recursiveGroupTree(data, child)
            });
        },

        forwardItem(item) {
            this.selectedGroup = item
            this.transfertHandler();
        },

        async retrieveGroups() {
            this.loading = true;
            try {
                const request = await apiService.get(`/backoffice/groups`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                const parentGroup = response.data.filter(el => el.parent == null);

                parentGroup.forEach(element => {
                    let firstChild = response.data.filter(child => child.parent != null && child.parent.id == element.id)
                    element.showChildren = true;
                    element.children = firstChild;
                    this.recursiveGroupTree(response.data, firstChild)
                });

                this.availableGroups = parentGroup;
            } catch (error) {
                this.availableGroups = [];
            } finally {
                this.loading = false;
            }
        },

        async retrieveAgents() {
            this.loading = true;
            try {
                const request = await apiService.get("/agents?forward=1");
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableAgents = response.data;
            } catch (error) {
                this.availableAgents = [];
            } finally {
                this.loading = false;
            }
        },

        async transfertHandler() {
            this.loadingSubmit = true;
            try {
                const request = await apiService.post(`/tickets/${this.$route.params.id}/transfert`, {
                    entityId: this.isGroup ? this.selectedGroup.id : this.selectedAgent.id,
                    transfertType: this.isGroup,
                });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false })
                this.$router.push({ name: 'tickets', replace: true });
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingSubmit = false;
            }
        },
    },
};