<template>
  <v-form ref="form">
    <div>
      <label for="code" class="font-weight-bold">
        {{ $t("form.label['Code de réinitialisation']") }}
      </label>
      <v-text-field
        id="code"
        v-model="code"
        required
        variant="outlined"
        density="compact"
        class="my-2"
        :rules="[rules.required]"
      ></v-text-field>
    </div>
    <div>
      <label for="password" class="font-weight-bold">
        {{ $t("form.label['Mot de passe']") }}
      </label>
      <v-text-field
        id="password"
        v-model="password"
        required
        variant="outlined"
        density="compact"
        class="my-2"
        :rules="[rules.required]"
        :type="showPassword ? 'text' : 'password'"
        :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append-inner="showPassword = !showPassword"
        @paste.prevent
      ></v-text-field>
    </div>
    <div v-if="password != null && password != ''">
      <PasswordScore
        :value="password"
        @passwordScore="passwordScoreHandler"
        class="mb-3"
      />
    </div>
    <div>
      <label for="passwordConfirm" class="font-weight-bold">
        {{ $t("form.label['Confirmation du mot de passe']") }}
      </label>
      <v-text-field
        id="passwordConfirm"
        v-model="passwordConfirm"
        required
        variant="outlined"
        density="compact"
        class="my-2"
        :rules="[rules.required]"
        :type="showPasswordConfirm ? 'text' : 'password'"
        :append-inner-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append-inner="showPasswordConfirm = !showPasswordConfirm"
      ></v-text-field>
    </div>
    <div class="px-2 mt-5">
      <v-btn
        block
        color="teal-darken-1"
        :loading="loading"
        :disabled="loading"
        @click="submitHandler"
      >
        {{ $t("form.btn['Valider']") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script src="./js/passwordResetActionForm.js"></script>