import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        motifs: {
            default: () => {
                return [];
            }
        }
    },

    watch: {
        dialog(val) {
            if (val) {
                this.retrieveMotifs(null);
            }
        },
        search(val) {
            if(val != null && val.length > 2) this.retrieveMotifs(val)
            else this.retrieveMotifs(null);
        }
    },

    data() {
        return {
            search: null,
            cardWidth: 600,
            loading: false,
            loadindAdd: false,
            availableMotifs: [],
            availableBackup: [],
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        selectMotif(motif) {
            motif.selected = !motif.selected
        },

        actionHandler() {
            this.$emit("updateField", { fieldName: "motifs", value: this.availableMotifs.filter(el => el.selected) })
            this.$emit('update:dialog', false)
        },

        async retrieveMotifs(searchVal = null) {
            this.loading = true
            try {
                const url = searchVal != null ? `/backoffice/motifs?search=${searchVal}` : `/backoffice/motifs`
                const request = await apiService.get(url);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                response.data.forEach(element => {
                    element.selected = false;
                });

                this.motifs.forEach(element => {
                    let updateElement = response.data.find(el => el.id == element.id);
                    if (updateElement) updateElement.selected = true;
                });

                this.availableMotifs = response.data;
            } catch (error) {
                this.availableMotifs = [];
            } finally {
                this.loading = false
            }
        },

        async addMotif() {
            this.loadindAdd = true
            try {
                const request = await apiService.post("/motifs", { label: this.search });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                response.infos.selected = true;
                this.availableMotifs.push(response.infos);

                this.$emit("updateField", { fieldName: "motifs", value: this.availableMotifs.filter(el => el.selected) })
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false });
                this.retrieveMotifs();
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadindAdd = false
            }
        }
    },
};