<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="pt-1">
              {{ $t('admin["L\'ordre des champs"]') }}
            </h2>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="35" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div class="px-5">
        <v-tabs v-model="tab" density="compact" class="mb-3">
          <v-tab value="general" class="px-0">
            {{ $t('admin["Générales"]') }}
          </v-tab>
          <v-tab value="annexe" class="px-0">
            {{ $t('admin["Annexes"]') }}
          </v-tab>
        </v-tabs>

        <v-window v-model="tab">
          <v-window-item
            value="general"
            style="height: 320px; overflow: scroll"
          >
            <v-row
              no-gutters
              v-for="(field, i) in demandeFormEdit"
              :key="i"
              class="my-1"
            >
              <v-col cols="12" lg="7" md="7" sm="7">
                <v-chip
                  class="ma-1"
                  :color="field.visible ? 'teal-darken-1' : ''"
                >
                  {{ field.label }}
                </v-chip>
              </v-col>
              <v-col>
                <v-btn
                  size="x-small"
                  color="teal-lighten-2"
                  class="mx-1"
                  v-if="i != 0"
                   :disabled="isDisabled"
                  @click="decreasePosition(demandeFormEdit, field, i)"
                >
                  <v-icon size="20">mdi-menu-up</v-icon>
                </v-btn>
                <v-btn
                  size="x-small"
                  color="teal-darken-4"
                  class="mx-1"
                  v-if="i != demandeFormEdit.length - 1"
                   :disabled="isDisabled"
                  @click="increasePosition(demandeFormEdit, field, i)"
                >
                  <v-icon size="20">mdi-menu-down</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item value="annexe" style="height: 320px; overflow: scroll">
            <v-row
              no-gutters
              v-for="(field, i) in annexeFormEdit"
              :key="i"
              class="my-1"
            >
              <v-col cols="12" lg="7" md="7" sm="7">
                <v-chip
                  class="ma-1"
                  :color="field.visible ? 'teal-darken-1' : ''"
                >
                  {{ field.label }}
                </v-chip>
              </v-col>
              <v-col>
                <v-btn
                  size="x-small"
                  color="teal-lighten-2"
                  class="mx-1"
                  v-if="i != 0"
                  :disabled="isDisabled"
                  @click="decreasePosition(annexeFormEdit, field, i)"
                >
                  <v-icon size="20">mdi-menu-up</v-icon>
                </v-btn>
                <v-btn
                  size="x-small"
                  color="teal-darken-4"
                  class="mx-1"
                  v-if="i != annexeFormEdit.length - 1"
                  :disabled="isDisabled"
                  @click="increasePosition(annexeFormEdit, field, i)"
                >
                  <v-icon size="20">mdi-menu-down</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </div>

      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="default"
          class="mr-4 px-5"
          color="grey"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="default"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          :disabled="isDisabled"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/ticketTemplateFieldOrderDialog.js"></script>