export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        isDisabled: {
            default: false
        },
        demandeForm: {
            default: () => {
                return []
            }
        },
        annexeForm: {
            default: () => {
                return []
            }
        }
    },

    watch: {
        dialog(val) {
            if(val) {
                this.demandeFormEdit    = this.demandeForm;
                this.annexeFormEdit     = this.annexeForm;
            }
        }
    },

    data() {
        return {
            cardWidth: 500,
            tab: null,
            demandeFormEdit: [],
            annexeFormEdit: [],
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });

    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 500;
        },

        close() {
            this.$emit("cancel")
            this.$emit('update:dialog', false)
        },

        toggleVisibility(field, value) {
            field.visible = value
        },

        decreasePosition(valuesArray, field, i) {
            let decreaseElement = valuesArray[i - 1];
            if(decreaseElement) {
                valuesArray.splice(i, 1, decreaseElement)
                valuesArray.splice(i - 1, 1, field)
            }
        },

        increasePosition(valuesArray, field, i) {
            let increaseElement = valuesArray[i + 1];
            if(increaseElement) {
                valuesArray.splice(i, 1, increaseElement)
                valuesArray.splice(i + 1, 1, field)
            }
        },

        async actionHandler() {
            this.$emit("updateField", {
                demandeFormEdit: this.demandeFormEdit,
                annexeFormEdit: this.annexeFormEdit,
            })
            this.$emit('update:dialog', false)
        },
    },
};