<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="pt-1">
              {{ $t('dialog["Gestion de la fonction"]') }}
            </h2>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="35" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>
      <div class="px-5">
        <p class="text-caption font-weight-bold mb-2">
          {{ $t('form.label["Fonction de l\'agent"]') }}
        </p>
        <v-autocomplete
          v-model="userJobEdit"
          v-model:search="search"
          :items="availableJobs"
          item-title="label"
          item-value="id"
          return-object
          variant="outlined"
          density="compact"
          :loading="loading"
          hide-details=""
          :disabled="isDisabled"
        >
        </v-autocomplete>
      </div>
      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          :disabled="loading"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          :loading="loading"
          :disabled="isDisabled"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script src="./js/userManagementJobAssignmentDialog.js"></script>