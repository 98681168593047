<template>
  <div class="variant_width_height d-flex justify-center align-center">
    <div>
      <v-card class="pa-5" width="400">
        <h2 class="text-center mb-4">{{ $t("passwordReset.title") }}</h2>

        <v-divider class="mb-5"></v-divider>

        <p
          v-if="!displayPasswordResetAction"
          class="mb-3 text-caption text-justify"
        >
          {{ $t("passwordReset.description") }}
        </p>

        <PasswordResetAskForm v-if="!displayPasswordResetAction" />
        <PasswordResetActionForm v-else />

        <div class="d-flex justify-center mt-4">
          <v-btn
            variant="text"
            size="small"
            color="grey"
            @click="toggleDisplayPasswordResetAction"
          >
            {{
              displayPasswordResetAction
                ? $t('form.btn["Je n\'ai pas de code"]')
                : $t('form.btn["J\'ai déjà un code"]')
            }}
          </v-btn>
        </div>
      </v-card>

      <div class="d-flex justify-center mt-8">
        <v-btn variant="text" size="small" @click="goToLogin">
          {{ $t("form.btn['Se connecter']") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script src="./js/passwordResetView.js"></script>