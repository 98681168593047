<template>
  <div class="py-lg-6 px-lg-8 py-md-4 px-md-5 pa-4">
    <TicketColumSchedulingDialog
      v-model:dialog="dialogScheduling"
      @confimUpdateTicketColumns="updateTicketColumns"
    />
    <TicketColumsHideShowDialog
      v-model:dialog="dialogColumnHideShow"
      @confimUpdateTicketColumns="updateTicketColumns"
    />

    <v-row>
      <v-col cols="5" class="d-flex align-center">
        <h2 class="font-weight-regular mr-2">
          {{ $t('ticket["Vos tickets ouverts"]') }}
        </h2>
      </v-col>
      <v-col>
        <div class="d-flex justify-lg-end">
          <v-btn
            v-for="(item, i) in items"
            :key="i"
            @click="actionHandler(item.type, item.value)"
            size="small"
            class="mx-2 text-caption"
            color="teal-darken-1"
          >
            {{ item.title }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" lg="6" md="7">
        <v-text-field
          v-model="search"
          :placeholder="$t(`ticket[&quot;Rechercher un ticket&quot;]`)"
          :hint="
            $t(
              `ticket[&quot;Rechercher un ticket à partir de son numéro de ticket, nom, prénom du demandeur ou des mots clés&quot;]`
            )
          "
          variant="underlined"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          color="teal-darken-2"
          clearable
        ></v-text-field>

        <div class="mt-1 px-1 d-flex flex-wrap">
          <v-chip
            :color="filterStringArray.length <= 0 ? 'teal-darken-1' : ''"
            class="ma-1"
            @click="filterHandler(null)"
          >
            <v-icon size="18" class="mr-1" v-if="filterStringArray.length <= 0">
              mdi-check
            </v-icon>
            {{ $t('ticket["Total"]') }}: {{ totalTickets }}
          </v-chip>

          <v-chip
            :color="filterPriority != null ? 'teal-darken-1' : ''"
            class="ma-1"
            @click="filterHandler('high')"
          >
            <v-icon size="18" class="mr-1" v-if="filterPriority != null">
              mdi-check
            </v-icon>
            {{ $t('ticket["Prioritaires"]') }}: {{ totalHightPriorityTickets }}
          </v-chip>

          <v-chip
            :color="filterDate != null ? 'teal-darken-1' : ''"
            class="ma-1"
            @click="filterHandler('today')"
          >
            <v-icon size="18" class="mr-1" v-if="filterDate != null">
              mdi-check
            </v-icon>
            {{ $t('ticket["Ouvert aujourd\'hui"]') }}: {{ totalTodayTickets }}
          </v-chip>

          <v-chip
            :color="filterStatus == 'waiting' ? 'teal-darken-1' : ''"
            class="ma-1"
            @click="filterHandler('waiting')"
          >
            <v-icon size="18" class="mr-1" v-if="filterStatus == 'waiting'">
              mdi-check
            </v-icon>
            {{ $t('ticket["En attente"]') }}: {{ waitingTicketList.length }}
          </v-chip>

          <v-chip
            :color="filterStatus == 'in progress' ? 'teal-darken-1' : ''"
            class="ma-1"
            @click="filterHandler('in progress')"
          >
            <v-icon size="18" class="mr-1" v-if="filterStatus == 'in progress'">
              mdi-check
            </v-icon>
            {{ $t('ticket["En cours"]') }}: {{ inProgressTicketList.length }}
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <v-data-table
      v-if="!loading"
      :search="search"
      :headers="headers"
      :items="tickets"
      item-value="id"
      item-class="red"
      class="elevation-1 mt-5 pb-4"
      :custom-filter="datatableFilterWithSubject"
    >
      <template v-slot:[`item`]="{ item, columns }">
        <tr>
          <td
            v-for="(column, i) in columns"
            :key="i"
            :class="getClassRow(item.raw)"
          >
            <div v-if="item.raw[`${column.key}`]">
              <div v-if="column.key == 'ticketStatus'">
                {{ $t(`ticket["${item.raw[column.key]}"]`) }}
              </div>
              <div v-if="column.key == 'priority'">
                {{ $t(`priority["${item.raw[column.key]}"]`) }}
              </div>
              <div v-if="column.key != 'ticketStatus' && column.key != 'priority'">
                {{ item.raw[`${column.key}`] }}
              </div>
            </div>
            <div v-if="!item.raw[`${column.key}`]">
              <div v-if="column.key == 'actions'">
                <v-icon size="large" class="" @click="editItem(item.raw)">
                  mdi-eye
                </v-icon>
              </div>
              <div v-else>
                {{ objectToString(item.raw, column.key) }}
              </div>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div v-else class="d-flex justify-center align-center" style="height: 40vh">
      <v-progress-circular
        :size="45"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script src="./js/ticketOpenList.js"></script>