import apiService from "@/services/apiService";

export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        agent: {
            default: null
        },
        source: {
            default: null
        },
        complexity: {
            default: null
        },
        priority: {
            default: null
        }
    },

    watch: {
        dialog(val) {
            if (val) {
                this.retrieveAgents()
                this.retrieveSources()
                this.selectedAgent = this.agent
                this.selectedComplexity = this.complexity
                this.selectedPriority = this.priority
                this.selectedSource = this.source
            }
        },
    },

    data() {
        return {
            cardWidth: 600,
            loading: false,
            tab: null,

            availableAgents: [],
            availableSources: [],

            availableComplexities: [
                {
                    "label": "Forte",
                    "value": "high"
                },
                {
                    "label": "Moyen",
                    "value": "medium"
                },
                {
                    "label": "Modéré",
                    "value": "moderate"
                },
                {
                    "label": "Faible",
                    "value": "low"
                }
            ],
            availablePriorities: [
                {
                    "label": "Haute",
                    "value": "high"
                },
                {
                    "label": "Moyenne",
                    "value": "medium"
                },
                {
                    "label": "Basse",
                    "value": "low"
                },
                {
                    "label": "Faible",
                    "value": "very low"
                }
            ],

            selectedAgent: null,
            selectedComplexity: null,
            selectedPriority: null,
            selectedSource: null,
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        getAgentTitle(item) {
            return `${item.lastname} ${item.firstname}`
        },

        setModelValue(type, value) {
            switch (type) {
                case "source":
                    this.selectedSource = value;
                    break;
                case "priority":
                    this.selectedPriority = value;
                    break;
                case "complexity":
                    this.selectedComplexity = value;
                    break;

                default:
                    break;
            }
        },

        async retrieveSources() {
            this.loading = true;
            try {
                const request = await apiService.get(`/sources`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableSources = response.data;
            } catch (error) {
                this.availableSources = [];
            } finally {
                this.loading = false;
            }
        },

        async retrieveAgents() {
            this.loading = true;
            try {
                const request = await apiService.get("/agents");
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableAgents = response.data;
            } catch (error) {
                this.availableAgents = [];
            } finally {
                this.loading = false;
            }
        },

        close() {
            this.tab = null;
            this.$emit('update:dialog', false)
        },

        actionHandler() {
            this.tab = null;
            this.$emit("updateFilter", {
                agent: this.selectedAgent, 
                priority: this.selectedPriority, 
                complexity: this.selectedComplexity, 
                source: this.selectedSource, 
            })
            this.$emit('update:dialog', false)
        },
    },
};