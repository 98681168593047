import AverageStats from "@/components/online/dashboard/stats/AverageStats.vue"
import PieCharts from "@/components/online/dashboard/stats/PieCharts.vue"
import VerticalBarChart from "@/components/online/dashboard/stats/VerticalBarChart.vue"
import GroupStats from "@/components/online/dashboard/stats/GroupStats.vue"

export default {
    setup() {

    },

    components: {
        AverageStats,
        PieCharts,
        VerticalBarChart,
        GroupStats
    },

    props: {
        title: {},
        type: {},
        data: {},
        options: {},
    },

    watch: {

    },

    data() {
        return {

        };
    },

    created() {

    },

    mounted() { },

    methods: {

    },
};