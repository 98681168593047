export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        loading: {
            default: false,
        },
        title: {
            default: ''
        },
        text: {
            default: ''
        }
    },

    watch: {
        dialog(val) {
            if(!val) this.loadingDelete = false;
        },
        loading(val) {
            this.loadingDelete = val;
        }
    },

    data() {
        return {
            cardWidth: 600,
            loadingDelete: false
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
        this.loadingDelete = this.loading;
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        actionHandler() {
            this.$emit("confirmDelete")
        }
    },
};