import apiService from "@/services/apiService";
import ImportGroupDialog from "@/components/online/manager/accessManagement/dialogs/ImportGroupDialog.vue";
import GroupNodeTree from "@/components/online/manager/accessManagement/list/GroupNodeTree.vue"
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        ImportGroupDialog,
        GroupNodeTree
    },

    props: {
        refreshList: {
            defaul: false,
        }
    },

    watch: {
        "$i18n.locale": function() {
            this.headers = [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: '', key: 'data-table-expand' },
                { title: this.$t('datatable.header["Nom du groupe"]'), key: 'label' },
                { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ];
        },
        
        search(val) {
            if (val != null && val.trim() != "") {
                this.$router.push({ name: this.$route.name, query: { search: val } })
            } else {
                this.$router.push({ name: this.$route.name })
            }
        },
        refreshList(val) {
            if (val) {
                this.retrieveData();
            }
        },
    },

    data() {
        return {
            loading: false,
            dialogImport: false,

            expanded: [],
            singleExpand: false,

            search: this.$route.query.search ?? null,
            headers: [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: '', key: 'data-table-expand' },
                { title: this.$t('datatable.header["Nom du groupe"]'), key: 'label' },
                { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ],
            groups: [],


            btns: [
                // { label: 'Importer', icon: 'mdi-inbox-arrow-down', type: 'import', value: true },
                // { label: 'Nouveau groupe', icon: 'mdi-plus', type: 'route', value: 'groupsManagementNew' },
            ]
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.retrieveData();
    },

    mounted() { },

    methods: {
        actionHandler(type, value) {
            switch (type) {
                case "route":
                    if (value == '') return;
                    this.$router.push({ name: value })
                    break;
                case "import":
                    this.dialogImport = value
                    break;
                default:
                    break;
            }
        },

        editItem(item) {
            this.$router.push({ name: "groupsManagementDetail", params: { id: item.id } })
        },

        recursiveGroupTree(data, parentGroup) {
            parentGroup.forEach(element => {
                let child = data.filter(child => child.parent != null && child.parent.id == element.id)
                element.showChildren = false;
                element.children = child;
                this.recursiveGroupTree(data, child)
            });
        },

        async retrieveData() {
            this.loading = true;
            try {
                const url = `/backoffice/groups`
                const request = await apiService.get(url);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                const parentGroup = response.data.filter(el => el.parent == null);

                parentGroup.forEach(element => {
                    let firstChild = response.data.filter(child => child.parent != null && child.parent.id == element.id)
                    element.showChildren = true;
                    element.children = firstChild;
                    this.recursiveGroupTree(response.data, firstChild)
                });

                this.groups = parentGroup;
                this.$emit('stopRefresh');
            } catch (error) {
                this.groups = [];
            } finally {
                this.loading = false;
            }
        },
    },
};