<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="pt-1">
              {{ $t('dialog["Envoyer un email"]') }}
            </h2>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="35" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <v-form ref="form" class="pa-5">
        <label for="content" class="font-weight-medium text-caption">
          {{ $t('form.label["Votre message"]') }}
        </label>
        <v-textarea
          id="content"
          v-model="content"
          variant="outlined"
          density="compact"
          :placeholder='$t(`form.description["Que voulez-vous lui dire ?"]`)'
          hide-details=""
          class="mt-1"
          :rules="[rules.required]"
          :disabled="isDisabled"
        ></v-textarea>
      </v-form>

      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="default"
          class="mr-4 px-5"
          color="grey"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="default"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          :loading="loadingSubmit"
          :disabled="loadingSubmit"
          @click="submitHandler"
        >
          {{ $t('form.btn["Envoyer"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script src="./js/ticketMailDialog.js"></script>