import TicketView from "@/views/online/tickets/TicketView.vue"

const ticketRoutes = [
    {
        path: '/tickets',
        name: 'tickets',
        component: TicketView,
        meta: { requiredAuth: true, displayType: 'list' }
    },
    {
        path: '/tickets/new',
        name: 'ticketNew',
        component: TicketView,
        meta: { requiredAuth: true, displayType: 'form' }
    },
    {
        path: '/tickets/:id(\\d+)',
        name: 'ticketDetail',
        component: TicketView,
        meta: { requiredAuth: true, displayType: 'form' }
    },
    {
        path: '/tickets/open',
        name: 'ticketsOpen',
        component: TicketView,
        meta: { requiredAuth: true, displayType: 'opened' }
    },
    {
        path: '/tickets/finished',
        name: 'ticketsFinished',
        component: TicketView,
        meta: { requiredAuth: true, displayType: 'finished' }
    },
];

export default ticketRoutes;