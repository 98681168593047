export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        stats: {}
    },

    watch: {

    },

    data() {
        return {
            cardWidth: 800,
            structure: null,
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
        this.structure = this.stats;
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 800;
        },

        close() {
            this.$emit("cancel")
            this.$emit('update:dialog', false)
        },


        async actionHandler() {
            this.$emit("updateDashboard", {structure: this.structure})
            this.$emit('update:dialog', false)
        },
    },
};