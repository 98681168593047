import { logout } from "@/services/logoutService";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/AuthStore";
import { API_URL } from "@/utils/contants";

import SwitchLanguage from "@/components/general/language/SwitchLanguage.vue"

export default {
    setup() {
        const authStore = useAuthStore();
        const { avatar } = storeToRefs(authStore)
        return { authStore, avatar };
    },

    components: {
        SwitchLanguage
    },

    props: {},


    watch: {

    },

    data() {
        return {
            lastname: this.authStore.lastname,
            firstname: this.authStore.firstname,
            avatar: this.avatar,

            items: [],
        };
    },

    created() {
        if (this.authStore.role == "agent" || this.authStore.role == "manager") {
            this.items = [
                { title: 'Nouveau ticket', icon: 'mdi-plus', type: "route", value: "ticketNew" },
            ];
        }
    },

    mounted() { },

    methods: {
        getFilePath(file, isRemote = false) {
            if (file == null && isRemote) return `${API_URL}/images/default.jpg`
            if (file != null && isRemote) return `${API_URL}/images/${file}`
            return `${location.origin}/images/${file}`
        },

        logoutHandler() {
            logout();
        },

        actionHandler(type, value) {
            if (type == "route") {
                if (this.$route.name == value) return;
                this.$router.push({ name: value, replace: true });
                return;
            }
            return;
        }
    },
};