<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">
              {{ $t('dialog["Gestion des permissions"]') }}
            </h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>
      <div class="px-5">
        <h4> {{ $t('form.description["Les utilisateurs de ce groupe peuvent"]') }} :</h4>
        <v-divider class="mt-3"></v-divider>
        <div v-for="permission in permissionsEdit" :key="permission.label">
          <v-row no-gutters>
            <v-col class="d-flex align-center" cols="3">
              <p>{{ permission.label }}</p>
            </v-col>
            <v-col>
              <v-switch
                :disabled="isDisabled"
                v-model="permission.value"
                hide-details=""
                color="teal-darken-1"
              ></v-switch>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/groupManagementPermissionsDialog.js"></script>