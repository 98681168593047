import SettingAppsView from "@/views/online/admin/SettingAppsView.vue"
import SettingTimesView from "@/views/online/admin/SettingTimesView.vue"
import SettingTicketTemplateView from "@/views/online/admin/SettingTicketTemplateView.vue"
import ContactBookView from "@/views/online/admin/ContactBookView.vue"

const adminRoutes = [
    {
        path: '/contact-books',
        name: 'contactBooks',
        component: ContactBookView,
        meta: { requiredAuth: true, displayType: '', roles: ['admin', 'manager'] }
    },
    //---------------------- integrations-----------------------
    {
        path: '/settings/integrations',
        name: 'integrations',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'integrationList', roles: ['admin', 'manager'] }
    },
    //----------------------ticket colors-----------------------
    {
        path: '/settings/tickets',
        name: 'ticketSettings',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'ticketColors', roles: ['manager'] }
    },
    //----------------------Sources-----------------------
    {
        path: '/settings/sources',
        name: 'sources',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'sourcesList', roles: ['admin'] }
    },
    {
        path: '/settings/sources/new',
        name: 'sourceNew',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'sourceForm', roles: ['admin'] }
    },
    {
        path: '/settings/sources/:id(\\d+)',
        name: 'sourceDetail',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'sourceForm', roles: ['admin'] }
    },
    //---------------------Reasons---------------------
    {
        path: '/settings/reasons',
        name: 'reasons',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'reasonsList', roles: ['admin', 'manager'] }
    },
    {
        path: '/settings/reasons/new',
        name: 'reasonNew',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'reasonForm', roles: ['admin', 'manager'] }
    },
    {
        path: '/settings/reasons/:id(\\d+)',
        name: 'reasonDetail',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'reasonForm', roles: ['admin', 'manager'] }
    },
    //-------------------------Tags---------------------
    {
        path: '/settings/tags',
        name: 'tags',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'tagList', roles: ['admin'] }
    },
    {
        path: '/settings/tags/new',
        name: 'tagNew',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'tagForm', roles: ['admin'] }
    },
    {
        path: '/settings/tags/:id(\\d+)',
        name: 'tagDetail',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'tagForm', roles: ['admin'] }
    },
    //---------------------Grades-----------------------
    {
        path: '/settings/grades',
        name: 'grades',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'gradeList', roles: ['admin'] }
    },
    {
        path: '/settings/grades/new',
        name: 'gradeNew',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'gradeForm', roles: ['admin'] }
    },
    {
        path: '/settings/grades/:id(\\d+)',
        name: 'gradeDetail',
        component: SettingAppsView,
        meta: { requiredAuth: true, displayType: 'gradeForm', roles: ['admin'] }
    },
    //-------------------Processing-----------------------
    {
        path: '/settings/processing',
        name: 'processings',
        component: SettingTimesView,
        meta: { requiredAuth: true, displayType: 'processingList', roles: ['admin', "manager"] }
    },
    {
        path: '/settings/processing/new',
        name: 'processingNew',
        component: SettingTimesView,
        meta: { requiredAuth: true, displayType: 'processingForm', roles: ['admin', "manager"] }
    },
    {
        path: '/settings/processing/:id(\\d+)',
        name: 'processingDetail',
        component: SettingTimesView,
        meta: { requiredAuth: true, displayType: 'processingForm', roles: ['admin', "manager"] }
    },
    //------------------TicketNotifications---------------------
    {
        path: '/settings/notifications',
        name: 'notifications',
        component: SettingTimesView,
        meta: { requiredAuth: true, displayType: 'notificationList', roles: ['admin', "manager"] }
    },
    {
        path: '/settings/notifications/new',
        name: 'notificationNew',
        component: SettingTimesView,
        meta: { requiredAuth: true, displayType: 'notificationForm', roles: ['admin', "manager"] }
    },
    {
        path: '/settings/notifications/:id(\\d+)',
        name: 'notificationDetail',
        component: SettingTimesView,
        meta: { requiredAuth: true, displayType: 'notificationForm', roles: ['admin', "manager"] }
    },
    //------------------Ticket Template Builder---------------------
    {
        path: '/forms/templates',
        name: 'templates',
        component: SettingTicketTemplateView,
        meta: { requiredAuth: true, displayType: 'templateList', roles: ['admin'] }
    },
    {
        path: '/forms/templates/:id(\\d+)',
        name: 'templatesDetail',
        component: SettingTicketTemplateView,
        meta: { requiredAuth: true, displayType: 'templateForm', roles: ['admin'] }
    },
];

export default adminRoutes;