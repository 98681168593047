<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="pt-1">
              {{ $t('drawer["Tous les groupes"]') }}
            </h2>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="35" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div class="pa-5" style="height: 500px; overflow-y: scroll">
        <v-data-table
          v-if="!loading"
          v-model:expanded="expanded"
          :headers="headers"
          :items="availableGroups"
          show-expand
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="item.raw.children.length == 0"
              rounded=""
              size="small"
              class="text-caption px-10 py-0 my-0"
              color="teal"
              @click="selectGroupForNotif(item.raw)"
            >
              {{ $t('form.btn["Choisir"]') }}
            </v-btn>
            <span v-else>--</span>
          </template>
          <template v-slot:expanded-row="{ columns, item }">
            <tr>
              <td :colspan="columns.length">
                <GroupRecursiveTicketNotification
                  v-for="(child, index) in item.raw.children"
                  :key="index"
                  :node="child"
                  :show="item.raw.showChildren"
                  class="mb-2"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script src="./js/groupTicketNotificationDialog.js"></script>