<template>
  <div>
    <h5 class="mt-1 ml-1">
      {{ title == null ? "--" : $t(`stats["${title}"]`) }}
    </h5>

    <p class="text-center py-15 font-weight-bold text-h5">
      {{ data == null ? 0 : data}}
    </p>
  </div>
</template>

<script src="./js/averageStats.js"></script>