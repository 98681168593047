<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">
              {{ $t('admin["Nouveau code d\'intégration"]') }}
            </h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <v-form ref="form" class="px-5">
        <v-row class="mb-4" no-gutters>
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('form.label["Groupe"]') }}
            </label>
            <v-select
              v-model="selectedTemplateGroup"
              :items="availableTemplates"
              item-title="group.label"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              class="mt-1"
              :rules="[rules.required]"
              :disabled="isDisabled"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mb-4" no-gutters>
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('ticket["Source"]') }}
            </label>
            <v-select
              v-model="selectedSource"
              variant="outlined"
              density="compact"
              hide-details=""
              class="mt-1"
              :items="availableSources"
              item-title="label"
              item-value="id"
              return-object=""
              :disabled="isDisabled"
              :rules="[rules.required]"
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
            >
              <template v-slot:selection="{ item }">
                {{
                  $t(`fields[&quot;${item.title}&quot;]`).includes("fields")
                    ? item.title
                    : $t(`fields["${item.title}"]`)
                }}
              </template>
              <template v-slot:item="{ item }">
                <div>
                  <v-list-item
                    :title="
                      $t(`fields[&quot;${item.title}&quot;]`).includes('fields')
                        ? item.title
                        : $t(`fields[&quot;${item.title}&quot;]`)
                    "
                    density="compact"
                    @click="selectedSource = item.value"
                  >
                  </v-list-item>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-form>

      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          :disabled="loading"
          :loading="loading"
          @click="actionHandler"
        >
          {{ $t('form.btn["Générer"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/templateIntegrationDialog.js"></script>