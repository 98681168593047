import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { API_URL, OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {
        dialog(val) {
            if (val) {
                this.retrieveReasons();
                this.retrieveAgents()
                this.retrieveSources()
            }
        }
    },

    data() {
        return {
            cardWidth: 600,
            startDate: '2023-01-01',
            endDate: new Date().toISOString().split('T')[0],
            selectedReason: "Tous les motifs",
            availableReasons: [],

            loading: false,
            loadingBtn: false,
            tab: null,
            availableAgents: [],
            availableSources: [],
            availableComplexities: [
                {
                    "label": "Forte",
                    "value": "high"
                },
                {
                    "label": "Moyen",
                    "value": "medium"
                },
                {
                    "label": "Modéré",
                    "value": "moderate"
                },
                {
                    "label": "Faible",
                    "value": "low"
                }
            ],
            availablePriorities: [
                {
                    "label": "Haute",
                    "value": "high"
                },
                {
                    "label": "Moyenne",
                    "value": "medium"
                },
                {
                    "label": "Basse",
                    "value": "low"
                },
                {
                    "label": "Faible",
                    "value": "very low"
                }
            ],

            selectedAgent: null,
            selectedComplexity: null,
            selectedPriority: null,
            selectedSource: null,
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        getAgentTitle(item) {
            return `${item.lastname} ${item.firstname}`
        },

        setModelValue(type, value) {
            switch (type) {
                case "source":
                    this.selectedSource = value;
                    break;
                case "priority":
                    this.selectedPriority = value;
                    break;
                case "complexity":
                    this.selectedComplexity = value;
                    break;

                default:
                    break;
            }
        },

        close() {
            this.tab = null;
            this.$emit('update:dialog', false)
        },

        async retrieveSources() {
            this.loading = true;
            try {
                const request = await apiService.get(`/sources`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableSources = response.data;
            } catch (error) {
                this.availableSources = [];
            } finally {
                this.loading = false;
            }
        },

        async retrieveAgents() {
            this.loading = true;
            try {
                const request = await apiService.get("/agents");
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableAgents = response.data;
            } catch (error) {
                this.availableAgents = [];
            } finally {
                this.loading = false;
            }
        },

        async retrieveReasons() {
            try {
                const request = await apiService.get(`/backoffice/motifs`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                response.data.unshift({ label: "Tous les motifs" })
                this.availableReasons = response.data;
            } catch (error) {
                this.availableReasons = []
            }
        },

        async actionHandler() {
            this.loadingBtn = true;
            try {
                let reason = "";
                if (this.selectedReason == "Tous les motifs") reason = 0;
                else reason = this.selectedReason;

                const request = await fetch(`${API_URL}/dashboard/export`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                    body: JSON.stringify({
                        startDate: this.startDate,
                        endDate: this.endDate,
                        reason: reason,
                        agent: this.selectedAgent != null ? this.selectedAgent.id : null,
                        priority: this.selectedPriority != null ? this.selectedPriority.value : null,
                        complexity: this.selectedComplexity != null ? this.selectedComplexity.value : null,
                        source: this.selectedSource != null ? this.selectedSource.id : null,
                    })
                })
                if (!request.ok) throw new Error();

                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement('a');
                link.href = objUrl;
                link.download = `reporting_export_${this.startDate}_${this.endDate}_${new Date().getTime()}`;
                link.classList.add('d-none');
                link.click();
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); link.remove(); }, 250);

                this.selectedAgent      = null;
                this.selectedComplexity = null;
                this.selectedPriority   = null;
                this.selectedSource     = null;
                this.$emit('update:dialog', false)
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }finally {
                this.loadingBtn = false;
            }
        },
    },
};