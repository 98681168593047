export default {
    setup() {

    },

    components: {
   
    },

    props: {
        title: {
            default: ''
        },
        data: {
            default: 0
        },
    },

    watch: {

    },

    data() {
        return {

        };
    },

    created() {

    },

    mounted() { },

    methods: {

    },
};