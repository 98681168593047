import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {
        dialog(val) {
            if (val) {
                this.retrieveTemplates();
                this.retrieveSources();
            } else {
                this.selectedTemplateGroup = null;
                this.selectedSource = null;
            }
        },
        "$i18n.locale": function () {
            this.rules = { required: v => !!v || this.$t('form.validation.required') }
        }
    },

    data() {
        return {
            loading: false,
            cardWidth: 600,
            selectedTemplateGroup: null,
            selectedSource: null,
            availableTemplates: [],
            availableSources: [],
            rules: { required: v => !!v || this.$t('form.validation.required') }
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        async actionHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');

                const request = await apiService.post(`/integrations`, {
                    template: this.selectedTemplateGroup.id,
                    source: this.selectedSource.id,
                });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                this.$emit("refresh");
                this.$emit('update:dialog', false)
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }

        },

        async retrieveTemplates() {
            try {
                const request = await apiService.get(`/form-templates`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableTemplates = response.data;
            } catch (error) {
                this.availableTemplates = [];
            }
        },

        async retrieveSources() {
            try {
                const request = await apiService.get(`/sources`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableSources = response.data;
            } catch (error) {
                this.availableSources = [];
            }
        }
    },
};