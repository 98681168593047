<template>
  <v-navigation-drawer width="65" permanent color="teal-darken-3">
    <v-list
      color="transparent"
      class="d-flex justify-center align-center flex-column mt-5"
    >
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        class="my-2 d-flex justify-end"
        :active="item.active"
        active-color="teal-lighten-3"
      >
        <v-tooltip :text="$t(`tooltips.text[${item.i18nIndex}]`)" content-class="custom-tooltip">
          <template v-slot:activator="{ props }">
            <v-icon
              v-if="item.type == 'mdi'"
              v-bind="props"
              size="32"
              class="pr-2"
              @click="actionHandler(item.route)"
            >
              {{ item.icon }}
            </v-icon>
            <svg-icon
              size="32"
              v-else
              v-bind="props"
              type="mdi"
              :path="item.icon"
              style="cursor: pointer;"
              @click="actionHandler(item.route)"
            ></svg-icon>
          </template>
        </v-tooltip>
      </v-list-item>
    </v-list>

    <template v-slot:prepend>
      <div class="d-flex justify-center pt-3">
        <v-tooltip :text="$t('tooltips.text[0]')" content-class="custom-tooltip">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              size="40"
              @click="actionHandler(role == 'agent' ? 'tickets' : 'dashboard')"
            >
              mdi-view-dashboard
            </v-icon>
          </template>
        </v-tooltip>
      </div>
    </template>

    <!-- <template v-slot:append>
      <div class="pb-8 d-flex justify-center">
        <div>logo</div>
      </div>
    </template> -->
  </v-navigation-drawer>
</template>

<script src="./js/mainDrawer.js"></script>