import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { OPEN_SNACKBAR } from "@/utils/contants";

import DeleteDialog from "@/components/online/manager/accessManagement/dialogs/DeleteDialog.vue";
import TemplateIntegrationDialog from "@/components/online/admin/integration/dialog/TemplateIntegrationDialog.vue"
import TemplateIntegrationTuto from "@/components/online/admin/integration/dialog/TemplateIntegrationTuto.vue"

export default {
    setup() {
    },

    components: {
        TemplateIntegrationTuto,
        TemplateIntegrationDialog,
        DeleteDialog
    },

    props: {
        refreshList: {
            defaul: false,
        }
    },

    watch: {
        refreshList(val) {
            if (val) {
                this.retrieveEmbeds();
            }
        },
        "$i18n.locale": function() {
            this.headers = [
                {
                    title: this.$t('datatable.header["Groupe"]'),
                    align: 'start',
                    sortable: true,
                    key: 'template.group',
                },
                { title: this.$t('datatable.header["Source"]'), key: 'source.label' },
                { title: this.$t('datatable.header["Code d\'intégration"]'), key: 'code' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ];
        }
    },

    data() {
        return {
            loading: false,
            loadingDelete: false,
            dialogNew: false,
            dialogTuto: false,
            search: null,
            dialogDelete: false,
            templateIntegrations: [],
            embedToDelete: null,

            

            headers: [
                {
                    title: this.$t('datatable.header["Groupe"]'),
                    align: 'start',
                    sortable: true,
                    key: 'template.group',
                },
                { title: this.$t('datatable.header["Source"]'), key: 'source.label' },
                { title: this.$t('datatable.header["Code d\'intégration"]'), key: 'code' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ],

            btns: [
                { label: 'Nouveau', icon: 'mdi-plus', type: 'dialogNew', value: true },
            ]
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.retrieveEmbeds();
    },

    mounted() { },

    methods: {
        actionHandler(type, value) {
            if (type == "dialogNew") {
                this.dialogNew = value;
            }
        },

        actionDatatable(item, action) {
            if(action == "copy") {
                navigator.clipboard.writeText(item.code)
                eventBus.$emit(OPEN_SNACKBAR, { message: "Code d'intégration copié", isError: false, })
            } 

            if(action == "delete") {
                this.dialogDelete = true;
                this.embedToDelete = item;
            }
        },
        
        async retrieveEmbeds() {
            this.loading = true;
            try {
                const request = await apiService.get(`/integrations`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.templateIntegrations = response.data;
            } catch (error) {
                this.templateIntegrations = [];
            } finally {
                this.loading = false;
            }
        },

        async deleteHandler() {
            this.loadingDelete = true;
            try {
                const request = await apiService.delete(`/integrations/${this.embedToDelete.code}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.embedToDelete = null;
                this.dialogDelete = false;
                this.retrieveEmbeds();
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};