<template>
  <div>
    <h5 class="mt-1 ml-1">
      {{ title == null ? "--" : $t(`stats["${title}"]`) }}
    </h5>

    <v-row class="mt-2">
      <v-col cols="12" lg="6" md="7">
        <v-text-field
          v-model="search"
          :placeholder='$t(`text["Rechercher un groupe"]`)'
          :hint='$t(`text["Rechercher à partir des mots clés"]`)'
          variant="underlined"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          color="teal-darken-2"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-model:expanded="expanded"
      :search="search"
      :headers="headers"
      :items="groupStats"
      show-expand
    >
      <template v-slot:expanded-row="{ columns, item }">
        <tr>
          <td :colspan="columns.length">
            <GroupStateNodeTree
              v-for="(child, index) in item.raw.children"
              :key="index"
              :node="child"
              :show="item.raw.showChildren"
              class="mb-2"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script src="./js/groupStats.js"></script>