import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";
import { useAuthStore } from "@/stores/AuthStore";

import DeleteDialog from "@/components/online/manager/accessManagement/dialogs/DeleteDialog.vue";
import GroupManagementMotifsDialog from "@/components/online/manager/accessManagement/dialogs/GroupManagementMotifsDialog.vue";
import GroupManagementWorkDaysDialog from "@/components/online/manager/accessManagement/dialogs/GroupManagementWorkDaysDialog.vue";
import GroupManagementPermissionsDialog from "@/components/online/manager/accessManagement/dialogs/GroupManagementPermissionsDialog.vue";
import GroupManagementAssignUsersDialog from "@/components/online/manager/accessManagement/dialogs/GroupManagementAssignUsersDialog.vue";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        const authStore = useAuthStore();
        return { authStore };
    },

    components: {
        DeleteDialog,
        GroupManagementMotifsDialog,
        GroupManagementWorkDaysDialog,
        GroupManagementPermissionsDialog,
        GroupManagementAssignUsersDialog,
    },

    props: {},

    watch: {
        "$i18n.locale": function () {
            this.rules = { required: v => !!v || this.$t('form.validation.required'), }
            this.title = this.$route.params.id ? "" : this.$t('manager.userManagement["Ajout d\'un groupe"]');
            this.permissions[0].label = this.$t('manager.userManagement["Consulter"]')
            this.permissions[1].label = this.$t('manager.userManagement["Modifier"]')
            this.permissions[2].label = this.$t('manager.userManagement["Fermer"]')
            this.permissions[3].label = this.$t('manager.userManagement["Transférer"]')

            this.workInfos[0].label = this.$t('manager.userManagement["Lundi"]')
            this.workInfos[1].label = this.$t('manager.userManagement["Mardi"]')
            this.workInfos[2].label = this.$t('manager.userManagement["Mercredi"]')
            this.workInfos[3].label = this.$t('manager.userManagement["Jeudi"]')
            this.workInfos[4].label = this.$t('manager.userManagement["Vendredi"]')
            this.workInfos[5].label = this.$t('manager.userManagement["Samedi"]')
            this.workInfos[6].label = this.$t('manager.userManagement["Dimanche"]')
        },
        '$route'(to) {
            this.title = to.params.id ? "" : this.$t('manager.userManagement["Ajout d\'un groupe"]');
            this.groupIdentity = null;

            if (to.name == 'groupsManagementNew') {
                this.$refs.form.reset();
                this.isDisabled = false;
                this.isDisabledBtn = false;
            } else if (to.name == 'groupsManagementDetail') {
                this.isDisabled = true;
                this.isDisabledBtn = true;
                this.retrieveSingle();
            }
        },
        doesHaveParent(val) {
            if (val) this.retrieveGroups();
            else this.selectParent = null;
        }
    },

    data() {
        return {
            loading: false,
            isDisabled: false,
            isDisabledBtn: false,
            loadingDelete: false,
            loadingGroups: false,

            dialogDelete: false,
            dialogMotifs: false,
            dialogWorkDays: false,
            dialogPermissions: false,
            dialogUsers: false,
            doesHaveParent: false,
            isParentAssignable: true,
            isForwardable: true,

            groupIdentity: null,
            title: this.$route.params.id ? "" : this.$t('manager.userManagement["Ajout d\'un groupe"]'),

            label: null,

            motifs: [],
            agents: [],

            selectParent: null,
            searchParent: null,
            availableGroups: [],

            permissions: [
                { label: this.$t('manager.userManagement["Consulter"]'), value: true },
                { label: this.$t('manager.userManagement["Modifier"]'), value: true },
                { label: this.$t('manager.userManagement["Fermer"]'), value: true },
                { label: this.$t('manager.userManagement["Transférer"]'), value: true },
            ],

            workInfos: [
                { label: this.$t('manager.userManagement["Lundi"]'), value: null, value2: null },
                { label: this.$t('manager.userManagement["Mardi"]'), value: null, value2: null },
                { label: this.$t('manager.userManagement["Mercredi"]'), value: null, value2: null },
                { label: this.$t('manager.userManagement["Jeudi"]'), value: null, value2: null },
                { label: this.$t('manager.userManagement["Vendredi"]'), value: null, value2: null },
                { label: this.$t('manager.userManagement["Samedi"]'), value: null, value2: null },
                { label: this.$t('manager.userManagement["Dimanche"]'), value: null, value2: null },
            ],

            rules: { required: v => !!v || this.$t('form.validation.required'), }
        };
    },

    created() {
        if (!authTokenService.getToken()) return;

        if (!this.$route.params.id) {
            this.isDisabledBtn = false;
        }

        if (this.$route.params.id) {
            this.isDisabled = true;
            this.isDisabledBtn = true;
            this.retrieveSingle();
        }
    },

    mounted() {

    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },

        enableEdition() {
            this.isDisabled = !this.isDisabled;
            this.isDisabledBtn = !this.isDisabledBtn;
        },

        updateField({ fieldName, value }) {
            switch (fieldName) {
                case "motifs":
                    this.motifs = value
                    break;
                case "permissions":
                    this.permissions = value
                    break;
                case "workInfos":
                    this.workInfos = value
                    break;
                default:
                    break;
            }
        },


        titleString(item) {
            return `${item.id} - ${item.label}`
        },

        async retrieveSingle() {
            this.loading = true;
            try {
                const request = await apiService.get(`/groups/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.groupIdentity = response.data.label;
                this.label = response.data.label;
                this.permissions = response.data.permissions
                this.workInfos = response.data.workInfos
                this.isParentAssignable = response.data.isParentAssignable == 1
                this.isForwardable = response.data.isForwardable == 1

                if (response.data.parent != null) {
                    this.selectParent = response.data.parent;
                    this.doesHaveParent = true;
                }

                this.motifs = [];
                response.data.motifs.forEach(el => {
                    el.motif.selected = true
                    this.motifs.push(el.motif)
                })

                this.agents = []
                response.data.agents.forEach(el => {
                    if (el.agent.email != this.authStore.emai) {
                        this.agents.push(el.agent)
                    }
                })
            } catch (error) {
                this.$router.push({ name: "groupsManagement", replace: true });
            } finally {
                this.loading = false;
            }
        },

        async retrieveGroups() {
            this.loadingGroups = true;
            try {
                const request = await apiService.get(`/backoffice/groups`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableGroups = response.data.filter(el => el.id != this.$route.params.id);
            } catch (error) {
                this.availableGroups = []
            } finally {
                this.loadingGroups = false;
            }
        },

        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');

                let motifToSend = [];

                this.motifs.forEach(element => {
                    motifToSend.push(element.id)
                });

                let dataSend = {
                    label: this.label,
                    isParentAssignable: this.isParentAssignable,
                    isForwardable: this.isForwardable,
                    motifs: motifToSend,
                    workInfos: JSON.stringify(this.workInfos),
                    permissions: JSON.stringify(this.permissions),
                };

                if (this.$route.params.id && this.doesHaveParent && this.selectParent != null) {
                    dataSend.parentID = this.selectParent.id;
                }

                const url = this.$route.params.id ? `/groups/${this.$route.params.id}` : `/groups`;
                const request = await apiService.post(url, dataSend);
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$router.push({ name: 'groupsManagementDetail', params: { id: response.infos.id }, replace: true })
                } else {
                    this.groupIdentity = "";
                    this.retrieveSingle();
                }
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
                if (this.$route.params.id) {
                    this.isDisabledBtn = true;
                    this.isDisabled = true;
                } else {
                    this.isDisabledBtn = false;
                }
            }
        },

        async deleteHandler() {
            this.loadingDelete = true;
            try {
                const request = await apiService.delete(`/groups/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.$router.go(-1)
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};