import LoginForm from "@/components/offline/login/LoginForm.vue"
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        
    },
    
    components: {
        LoginForm,
    },

    props: {},

    watch: {

    },

    data() {
        return {

        };
    },

    created() {
        if(authTokenService.getToken()) {
            return this.$router.push({name: 'dashboard', replace: true})
        }
    },

    mounted() { },

    methods: {
        goToPasswordReset() {
            this.$router.push({name: 'passwordReset'})
        }
    },
};