<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="pt-1">
              {{ $t('dialog["Transférer le ticket"]') }}
            </h2>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="35" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>
      <div class="d-flex align-center flex-wrap px-5">
        <p class="ma-1">
          {{ $t('form.description["Transférer à un"]') }}
        </p>
        <v-chip
          class="ma-1"
          @click="toggleSelected(true)"
          :color="isGroup ? 'teal-darken-1' : ''"
        >
          <v-icon v-if="isGroup" size="15" class="mr-1">mdi-check</v-icon>
          {{ $t('form.description["autre service"]') }}
        </v-chip>
        <v-chip
          class="ma-1"
          @click="toggleSelected(false)"
          :color="!isGroup ? 'teal-darken-1' : ''"
        >
          <v-icon v-if="!isGroup" size="15" class="mr-1">mdi-check</v-icon>
          {{ $t('form.description["autre agent"]') }}
        </v-chip>
      </div>

      <div
        class="pa-5"
        :style="isGroup ? 'height: 400px; overflow-y: scroll' : ''"
      >
        <div v-if="isGroup">
          <div
            v-if="loading"
            style="height: 300px"
            class="d-flex justify-center align-center"
          >
            <v-progress-circular
              :size="50"
              :width="4"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </div>

          <v-data-table
            v-if="!loading"
            v-model:expanded="expanded"
            :headers="headers"
            :items="availableGroups"
            :loading="loading"
            show-expand
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="item.raw.children.length == 0 && item.raw.isForwardable == 1"
                rounded=""
                size="small"
                class="text-caption px-10 py-0 my-0"
                color="teal"
                @click="forwardItem(item.raw)"
              >
                {{ $t('text["Transférer"]') }}
              </v-btn>
            </template>
            <template v-slot:expanded-row="{ columns, item }">
              <tr>
                <td :colspan="columns.length">
                  <TransfertGroupRecursive
                    v-for="(child, index) in item.raw.children"
                    :key="index"
                    :node="child"
                    :show="item.raw.showChildren"
                    class="mb-2"
                    @forwardItem="forwardItem"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>

        <v-autocomplete
          v-if="!isGroup"
          v-model="selectedAgent"
          v-model:search="search"
          :items="availableAgents"
          :item-title="getTitleString"
          item-value="id"
          return-object
          variant="outlined"
          density="compact"
          hide-details=""
          :loading="loading"
        >
        </v-autocomplete>
      </div>

      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="default"
          class="mr-4 px-5"
          color="grey"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="default"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          :loading="loadingSubmit"
          :disabled="loadingSubmit"
          @click="transfertHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script src="./js/ticketTransfertDialog.js"></script>