export default {
    setup() {

    },

    components: {

    },

    props: {},

    watch: {

    },

    data() {
        return {

        };
    },

    created() {

    },

    mounted() { },

    methods: {

    },
};