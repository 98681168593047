<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">{{ $t(`text["Plus de filtres"]`) }}</h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div class="px-5">
        <v-tabs v-model="tab" density="compact" class="mb-3">
          <v-tab value="agents">
            {{ $t(`text["Les agents"]`) }}
          </v-tab>
          <v-tab value="ticket">
            {{ $t(`text["Hiérarchie du ticket"]`) }}
          </v-tab>
        </v-tabs>

        <v-window v-model="tab">
          <v-window-item value="agents" class="pt-3">
            <div v-if="loading" class="d-flex justify-center align-center">
              <v-progress-circular
                :size="45"
                :width="2"
                color="grey"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <label class="font-weight-medium text-caption">
                {{ $t('form.label["Les agents"]') }}
              </label>
              <v-select
                v-model="selectedAgent"
                :items="availableAgents"
                :item-title="getAgentTitle"
                item-value="value"
                return-object
                variant="outlined"
                density="compact"
                hide-details=""
                class="mb-5"
                clearable
              ></v-select>
            </div>
          </v-window-item>

          <v-window-item
            value="ticket"
            class="pt-3"
            style="height: 280px; overflow: scroll"
          >
            <div
              v-if="loading"
              class="d-flex justify-center align-center"
              style="height: 100px"
            >
              <v-progress-circular
                :size="45"
                :width="2"
                color="grey"
                indeterminate
              ></v-progress-circular>
            </div>

            <div v-else>
              <label class="font-weight-medium text-caption">
                {{ $t('ticket["Source"]') }}
              </label>

              <v-select
                v-model="selectedSource"
                :items="availableSources"
                variant="outlined"
                density="compact"
                hide-details=""
                class="mt-1 mb-4"
                item-title="label"
                item-value="id"
                return-object
                clearable
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
              >
                <template v-slot:selection="{ item }">
                  {{
                    $t(`fields[&quot;${item.title}&quot;]`).includes("fields")
                      ? item.title
                      : $t(`fields["${item.title}"]`)
                  }}
                </template>
                <template v-slot:item="{ item }">
                  <div>
                    <v-list-item
                      :title="$t(`fields[&quot;${item.title}&quot;]`)"
                      density="compact"
                      @click="setModelValue('source', item.value)"
                    >
                    </v-list-item>
                  </div>
                </template>
              </v-select>

              <label class="font-weight-medium text-caption">
                {{ $t('ticket["Priorité"]') }}
              </label>

              <v-select
                v-model="selectedPriority"
                :items="availablePriorities"
                variant="outlined"
                density="compact"
                hide-details=""
                class="mt-1 mb-4"
                item-title="label"
                item-value="id"
                return-object
                clearable
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
              >
                <template v-slot:selection="{ item }">
                  {{
                    $t(`fields[&quot;${item.title}&quot;]`).includes("fields")
                      ? item.title
                      : $t(`fields["${item.title}"]`)
                  }}
                </template>
                <template v-slot:item="{ item }">
                  <div>
                    <v-list-item
                      :title="$t(`fields[&quot;${item.title}&quot;]`)"
                      density="compact"
                      @click="setModelValue('priority', item.value)"
                    >
                    </v-list-item>
                  </div>
                </template>
              </v-select>

              <label class="font-weight-medium text-caption">
                {{ $t('ticket["Complexité"]') }}
              </label>

              <v-select
                v-model="selectedComplexity"
                :items="availableComplexities"
                variant="outlined"
                density="compact"
                hide-details=""
                class="mt-1"
                item-title="label"
                item-value="id"
                return-object
                clearable
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
              >
                <template v-slot:selection="{ item }">
                  {{
                    $t(`fields[&quot;${item.title}&quot;]`).includes("fields")
                      ? item.title
                      : $t(`fields["${item.title}"]`)
                  }}
                </template>
                <template v-slot:item="{ item }">
                  <div>
                    <v-list-item
                      :title="$t(`fields[&quot;${item.title}&quot;]`)"
                      density="compact"
                      @click="setModelValue('complexity', item.value)"
                    >
                    </v-list-item>
                  </div>
                </template>
              </v-select>
            </div>
          </v-window-item>
        </v-window>
      </div>

      <v-divider class="mt-5"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t(`form.btn["Annuler"]`) }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          @click="actionHandler"
        >
          {{ $t(`form.btn["Valider"]`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/moreFiltersDialog.js"></script>