import apiService from "@/services/apiService";

export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        isDisabled: {
            default: true
        },
        actions: {
            default: () => {
                return [];
            }
        }
    },

    watch: {
        dialog(val) {
            if(val && !this.isDisabled) {
                this.retrieveGroups();
                this.retrieveUsers();
            }
        },
         selectedGroup(val) {
            this.availableUsers = [];
            val.agents.forEach(element => {
                this.availableUsers.push(element.agent);
            });
        }
    },

    data() {
        return {
            tab: null,
            loading: false,
            selectedGroup: null,
            availableGroups: [],
            availableUsers: [],
            users: [],
            cardWidth: 600,
            actionEdit: [],
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
        this.actionEdit = this.actions;
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        getTitleString(item) {
            if (item.parent != null) return `${item.label} | Parent - ${item.parent.label} (ID: ${item.parent.id})`
            return item.label;
        },

        getTitleUser(item) {
            return `${item.lastname} ${item.firstname} - ${item.email}`
        },

        close() {
            if(!this.isDisabled) this.$emit("refresh")
            this.$emit('update:dialog', false)
        },

        actionHandler() {
            this.$emit('updateActions', this.actionEdit)
            this.$emit('update:dialog', false)
        },

        async retrieveGroups() {
            this.loading = true;
            try {
                const request = await apiService.get(`/backoffice/groups`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableGroups = response.data;
                this.groupEdit = this.group;
            } catch (error) {
                this.availableGroups = []
            } finally {
                this.loading = false;
            }
        },

        async retrieveUsers() {
            try {
                const url = `/contact-books` ;
                const request = await apiService.get(url);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.users = response.data;
            } catch (error) {
                this.users = []
            }
        },
    },
};