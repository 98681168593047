import MainDrawer from "@/components/navigations/MainDrawer.vue";
import AppBarComponent from "@/components/navigations/AppBarComponent.vue";

export default {
    components: {
        MainDrawer,
        AppBarComponent,
    },

    props: {},

    watch: {

    },
    
    data() {
        return {};
    },

    created() { },

    mounted() { },

    methods: {},
};