<template>
  <div>
    <SettingTemplateDrawer @refreshList="refreshList" />

    <TicketTemplateBuilderList
      v-if="displayType == 'templateList'"
      :refreshList="refreshTemplates"
      @stopRefresh="stopRefresh"
    />

    <TicketTemplateBuilder v-if="displayType == 'templateForm'" />
  </div>
</template>

<script src="./js/settingTicketTemplateView.js"></script>