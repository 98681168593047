import ExampleError from "@/views/error/ExampleError.vue"

const errorRoutes = [
  {
    path: "/:pathMatch(.*)",
    name: 'error',
    component: ExampleError,
  },
]

export default errorRoutes;