<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">
              {{ $t('dialog["Gestion des jours et horaires de travail"]') }}
            </h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>
      <div class="px-5">
        <div v-for="(item, index) in workInfosEdit" :key="index">
          <label for="" class="font-weight-medium text-caption">{{
            item.label
          }}</label>

          <v-row>
            <v-col>
              <v-text-field
                v-model="item.value"
                placeholder="9h-12h"
                variant="outlined"
                density="compact"
                hide-details=""
                class="my-1"
                :disabled="isDisabled"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.value2"
                placeholder="13h-18h"
                variant="outlined"
                density="compact"
                hide-details=""
                class="my-1"
                :disabled="isDisabled"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script src="./js/groupManagementWorkDaysDialog.js"></script>