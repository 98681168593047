<template>
  <v-main class="full_width_height">
    <div class="d-flex full_width_height">
      <MainDrawer />

      <div class="full_width_height">
        <AppBarComponent />
        <router-view class="mt-3"></router-view>
      </div>
    </div>
  </v-main>
</template>

<script src="./js/onlineLayout.js"></script>