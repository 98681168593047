<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">
              {{ $t('text["Afficher/cacher des reportings"]') }}
            </h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div class="px-5">
        <div v-for="(stat, index) in stats" :key="index" class="px-1">
          <v-row class="px-1 my-1">
            <v-col
              v-for="(row1, rowIndex1) in stat.row"
              :key="rowIndex1"
              cols="12"
              lg=""
              :md="rowIndex1 == 1 ? '12' : ''"
              :class="
                row1.column[0].row || row1.column.length > 1
                  ? ''
                  : 'border ma-1'
              "
            >
              <template v-if="!row1.column[0].row">
                <template v-if="row1.column.length > 1">
                  <v-row class="px-1">
                    <v-col
                      cols="12"
                      lg=""
                      md="12"
                      class="border my-1"
                      v-for="(row2, rowIndex2) in row1.column"
                      :key="rowIndex2"
                      :class="rowIndex2 % 2 ? 'mx-lg-1' : ''"
                    >
                      <div class="d-flex justify-end">
                        <v-icon @click="row2.show = !row2.show">
                          {{ row2.show ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                        >
                      </div>
                      {{ $t(`stats["${row2.title}"]`) }}
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <div class="d-flex justify-end">
                    <v-icon @click="row1.column[0].show = !row1.column[0].show">
                      {{
                        row1.column[0].show ? "mdi-eye" : "mdi-eye-off"
                      }}</v-icon
                    >
                  </div>
                  {{ $t(`stats["${row1.column[0].title}"]`) }}
                </template>
              </template>

              <v-row
                v-else
                v-for="(row3, rowIndex3) in row1.column"
                :key="rowIndex3"
              >
                <v-col
                  cols="12"
                  lg=""
                  md=""
                  class="border ma-1"
                  v-for="(row4, rowIndex4) in row3.row"
                  :key="rowIndex4"
                >
                  <div class="d-flex justify-end">
                    <v-icon @click="row4.show = !row4.show">
                      {{ row4.show ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                    >
                  </div>
                  {{ $t(`stats["${row4.title}"]`) }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-divider class="mt-5"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
             {{ $t(`form.btn["Annuler"]`) }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          @click="actionHandler"
        >
          {{ $t(`form.btn["Valider"]`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/hideShowReportingDialog.js"></script>