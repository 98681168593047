<template>
  <v-form ref="form">
    <v-row class="pb-3" no-gutters>
      <v-col class="px-5 d-flex align-center" cols="12" lg="5" md="7">
        <div
          style="position: relative; cursor: pointer"
          @click="createDynamicInputFile"
        >
          <v-avatar class="mr-4 mt-2" size="80" color="grey">
            <v-img v-if="uploadImage != null" :src="preview()" cover></v-img>
            <v-img
              v-else
              :src="getFilePath(avatar, true)"
              alt="avatar"
              cover
            ></v-img>
          </v-avatar>
          <v-icon
            color="black"
            style="position: absolute; bottom: -8px; z-index: 800; left: 30px"
            >mdi-camera</v-icon
          >
        </div>
        <div class="pt-2">
          <h3>{{ lastname }} {{ firstname }}</h3>
        </div>
      </v-col>
      <v-col class="d-flex justify-end align-center pr-lg-10 pr-5 mt-2">
        <v-btn
          size="default"
          color="teal-darken-1"
          :loading="loading"
          :disabled="loading"
          @click="submitHandler"
        >
          <v-icon size="25" class="mr-2">mdi-content-save</v-icon>
          {{ $t(`form.btn["Sauvegarder"]`) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-2"></v-divider>

    <div class="mt-5 px-5">
      <v-tabs v-model="tab" density="compact" class="px-0 mx-0">
        <v-tab value="1" class="mx-0 px-2 text-body-1 font-weight-medium">
          {{ $t(`profil.text["Mes informations"]`) }}
        </v-tab>
        <v-tab value="2" class="mx-2 px-2 text-body-1 font-weight-medium">
          {{ $t(`profil.text["Paramètres de sécurité"]`) }}
        </v-tab>
      </v-tabs>
      
      <v-divider class="mt-4"></v-divider>

      <v-window
        v-model="tab"
        class="py-4"
        style="max-height: 670px !important; overflow-y: scroll"
      >
        <v-window-item value="1">
          <h3 class="font-weight-medium text-body-1 mt-3">
            {{ $t(`profil.text["Mes informations"]`) }}
          </h3>
          <p class="text-caption mb-4 mt-1">
            {{ $t(`profil.text["Gérez vos informations générales"]`) }}
          </p>
          <v-row no-gutters>
            <v-col cols="12" lg="6" md="8">
              <label for="lastname" class="font-weight-medium text-caption">
                {{ $t(`form.label["Nom de famille"]`) }}
              </label>
              <v-text-field
                id="lastname"
                v-model="lastname"
                variant="outlined"
                density="compact"
                hide-details=""
                class="mt-1"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="my-2">
            <v-col cols="12" lg="6" md="8">
              <label for="firstname" class="font-weight-medium text-caption">
                {{ $t(`form.label["Prénom"]`) }}
              </label>
              <v-text-field
                id="firstname"
                v-model="firstname"
                variant="outlined"
                density="compact"
                hide-details=""
                class="mt-1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item value="2">
          <h3 class="font-weight-medium text-body-1 mt-3">
            {{ $t(`profil.text["Votre mot de passe"]`) }}
          </h3>
          <p class="text-caption mb-4 mt-1">
            {{
              $t(
                `profil.text["Lorsque vous enregistrez votre nouveau mot de passe, vous serez déconnecté."]`
              )
            }}
          </p>
          <v-row no-gutters>
            <v-col cols="12" lg="6" md="8">
              <label for="oldPassword" class="font-weight-medium text-caption">
                {{ $t(`form.label["Mot de passe actuel"]`) }}
              </label>
              <v-text-field
                id="oldPassword"
                v-model="oldPassword"
                variant="outlined"
                density="compact"
                hide-details=""
                class="mt-1"
                :type="show1 ? 'text' : 'password'"
                :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="show1 = !show1"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="my-2">
            <v-col cols="12" lg="6" md="8">
              <label for="password" class="font-weight-medium text-caption">
                {{ $t(`form.label["Nouveau mot de passe"]`) }}
              </label>
              <v-text-field
                id="password"
                v-model="password"
                variant="outlined"
                density="compact"
                hide-details=""
                class="mt-1"
                :type="show2 ? 'text' : 'password'"
                :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="show2 = !show2"
              ></v-text-field>
              <div v-if="password != null && password != ''">
                <PasswordScore
                  :value="password"
                  @passwordScore="passwordScoreHandler"
                  class="mt-3"
                />
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" lg="6" md="8">
              <label
                for="passwordConfirm"
                class="font-weight-medium text-caption"
              >
                {{ $t(`form.label["Confirmation du mot de passe"]`) }}
              </label>
              <v-text-field
                id="passwordConfirm"
                v-model="passwordConfirm"
                variant="outlined"
                density="compact"
                hide-details=""
                class="mt-1"
                :type="show3 ? 'text' : 'password'"
                :append-inner-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="show3 = !show3"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </div>
  </v-form>
</template>

<script src="./js/profilForm.js"></script>