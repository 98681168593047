export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        colors: {
            default: () => []
        },
        type: {
            default: null
        },
        selectedColor: {
            default: null
        }
    },

    watch: {
        dialog(val) {
            this.availableColors = []
            if (val) {
                this.colors.forEach(element => {
                    this.availableColors.push({
                        label: element,
                        selected: false,
                    })
                });

                let current = this.availableColors.find(element => element.label == this.selectedColor);
                if(current) current.selected = true;
            } else {
                this.availableColors.forEach(element => {
                    element.selected = false;
                });
            }
        }
    },

    data() {
        return {
            cardWidth: 500,
            availableColors: [],
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 500;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        toggleSelected(item) {
            if (item.selected) {
                item.selected = !item.selected;
                return;
            }
            this.availableColors.forEach(element => {
                element.selected = false;
            });

            item.selected = !item.selected;
        },

        actionHandler() {
            let selected = this.availableColors.find(element => element.selected);
            this.$emit("updateColor", { priority: this.type, color: selected ? selected.label : "" })
            this.$emit('update:dialog', false)
        }
    },
};