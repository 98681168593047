export default {
    components: {},

    props: {
        rail: {
            default: false
        },
        histories: {
            default: () => []
        }
    },

    watch: {

    },

    data() {
        return {
            drawer: true,
        };
    },

    created() {

    },

    mounted() { },

    methods: {
        close() {
            this.$emit('close')
        }
    },
};