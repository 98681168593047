import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { DASHBOARD_STRUCTURE, OPEN_SNACKBAR } from "@/utils/contants";
import eventBus from "@/eventBus";

import DisplayStatType from "@/components/online/dashboard/stats/DisplayStatType.vue"
import LoadingComponent from "@/components/general/loading/LoadingComponent.vue"
import HideShowReportingDialog from "@/components/online/dashboard/dialog/HideShowReportingDialog.vue"
import ExportReportingDialog from "@/components/online/dashboard/dialog/ExportReportingDialog.vue"
import MoreFiltersDialog from "@/components/online/dashboard/dialog/MoreFiltersDialog.vue"

export default {
    setup() {

    },

    components: {
        DisplayStatType,
        LoadingComponent,
        HideShowReportingDialog,
        ExportReportingDialog,
        MoreFiltersDialog
    },

    props: {},

    watch: {

    },

    data() {
        return {
            loading: true,
            btnActions: [
                { title: 'Afficher/cacher des reportings', type: 'hideShow', value: true },
                { title: 'Exporter', type: 'export', value: true },
            ],
            startDate: '2023-01-01',
            endDate: new Date().toISOString().split('T')[0],
            selectedReason: "Tous les motifs",
            availableReasons: [],

            stats: [],
            hideAlert: false,
            dialogHideShow: false,
            dialogExport: false,
            generalProcessing: null,

            dialogMoreFilters: false,
            
            alertProcessing: {
                label: '',
                color: '',
                icon: '',
            },

            agentFilter: null,
            priorityFilter: null,
            complexityFilter: null,
            sourceFilter: null,
        };
    },

    created() {
        if (!authTokenService.getToken()) return;
        this.retrieveReasons();
        this.retrieveDashboard();
    },

    mounted() { },

    methods: {
        actionHandler(type, value) {
            if (type == "hideShow") {
                this.dialogHideShow = value
            }
            if (type == "export") {
                this.dialogExport = value
            }
        },

        setClasses(rowInfo) {
            if (rowInfo.column[0].title && rowInfo.column[0].title == "Tickets lus et non lus") {
                return rowInfo.column[0].show ? 'border ma-1' : 'd-none'
            }
            return rowInfo.column[0].row || rowInfo.column.length > 1 ? '' : 'border ma-1'
        },

        updateFilter({agent, priority, complexity,  source}) {
            this.agentFilter        = agent;
            this.priorityFilter     = priority;
            this.complexityFilter   = complexity;
            this.sourceFilter       = source;
        },

        async retrieveDashboard() {
            try {
                const request = await apiService.get(`/dashboard`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.stats = response.data.structure;
                await this.retrieveAllStats();
            } catch (error) {
                this.stats = DASHBOARD_STRUCTURE;
            }
        },

        async updateDashboard({ structure }) {
            this.loading = true;
            try {
                const request = await apiService.post(`/dashboard`, { structure: JSON.stringify(structure) });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                });
                this.retrieveDashboard();
            } finally {
                this.loading = false;
            }
        },

        async retrieveReasons() {
            try {
                const request = await apiService.get(`/backoffice/motifs`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                response.data.unshift({ label: "Tous les motifs" })
                this.availableReasons = response.data;
            } catch (error) {
                this.availableReasons = []
            }
        },

        async retrieveAllStats() {
            this.loading = true;
            try {
                let reason = "";
                if (this.selectedReason == "Tous les motifs") reason = 0;
                else reason = this.selectedReason;

                let params = `startDate=${this.startDate}&endDate=${this.endDate}&reason=${reason}`
                if(this.agentFilter != null) params += `&agent${this.agentFilter.id}`
                if(this.priorityFilter != null) params += `&priority${this.priorityFilter.value}`
                if(this.complexityFilter != null) params += `&complexity${this.complexityFilter.value}`
                if(this.sourceFilter != null) params += `&source=${this.sourceFilter.id}`

                const requestToday = await apiService.get(`/stats?target=today&${params}`);
                const requestPriority = await apiService.get(`/stats?target=priority&${params}`);
                const requestWaiting = await apiService.get(`/stats?target=waiting&${params}`);
                const requestInProgress = await apiService.get(`/stats?target=inProgress&${params}`);
                const requestFinished = await apiService.get(`/stats?target=finished&${params}`);
                const requestProcessing = await apiService.get(`/stats?target=processing&${params}`);
                const requestReadNotRead = await apiService.get(`/stats?target=readAndNotRead&${params}`);
                const requestPriorities = await apiService.get(`/stats?target=priorities&${params}`);
                const requestComplexities = await apiService.get(`/stats?target=complexities&${params}`);
                const requestSources = await apiService.get(`/stats?target=sources&${params}`);
                const requestGroups = await apiService.get(`/stats?target=groups&${params}`);
                const request = await apiService.get(`/organizations`);

                if (!request.ok) {
                    this.generalProcessing = null;
                } else {
                    const response = await request.json();
                    this.generalProcessing = response.data.processingTime * 1440;
                }

                if (!requestToday.ok) {
                    this.stats[0].row[0].column[0].row[1].data = null;
                } else {
                    const responseToday = await requestToday.json();
                    this.stats[0].row[0].column[0].row[1].data = responseToday.data
                }

                if (!requestPriority.ok) {
                    this.stats[0].row[0].column[0].row[2].data = null;
                } else {
                    const responsePriority = await requestPriority.json();
                    this.stats[0].row[0].column[0].row[2].data = responsePriority.data
                }

                if (!requestWaiting.ok) {
                    this.stats[0].row[0].column[1].row[0].data = null;
                } else {
                    const responseWaiting = await requestWaiting.json();
                    this.stats[0].row[0].column[1].row[0].data = responseWaiting.data
                }

                if (!requestInProgress.ok) {
                    this.stats[0].row[0].column[1].row[1].data = null;
                } else {
                    const responseInProgress = await requestInProgress.json();
                    this.stats[0].row[0].column[1].row[1].data = responseInProgress.data
                }

                if (!requestFinished.ok) {
                    this.stats[0].row[0].column[1].row[2].data = null
                } else {
                    const responseFinished = await requestFinished.json();
                    this.stats[0].row[0].column[1].row[2].data = responseFinished.data
                }

                if (!requestProcessing.ok) {
                    this.stats[0].row[0].column[0].row[0].data = null
                } else {
                    const responseProcessing = await requestProcessing.json();
                    this.stats[0].row[0].column[0].row[0].data = responseProcessing.data

                    if(this.generalProcessing < responseProcessing.data) {
                        this.alertProcessing = {
                            label: 'Le temps de traitement moyen est élevé',
                            color: 'red',
                            icon: 'mdi-alert-circle',
                        }
                    } else {
                        this.alertProcessing = {
                            label: 'Vos statistiques sont positives',
                            color: 'green',
                            icon: 'mdi-party-popper',
                        }
                    }
                }

                if (!requestReadNotRead.ok) {
                    this.stats[0].row[1].column[0].data = {
                        labels: [],
                        datasets: [
                            {
                                backgroundColor: [],
                                data: [],
                            }
                        ]
                    };
                } else {
                    const responseReadNotRead = await requestReadNotRead.json();
                    this.stats[0].row[1].column[0].data = responseReadNotRead.data
                }

                if (!requestPriorities.ok) {
                    this.stats[1].row[0].column[0].data = {
                        labels: [],
                        datasets: [
                            {
                                backgroundColor: [],
                                data: [],
                            }
                        ]
                    };
                } else {
                    const responsePriorities = await requestPriorities.json();
                    this.stats[1].row[0].column[0].data = responsePriorities.data
                }

                if (!requestComplexities.ok) {
                    this.stats[1].row[0].column[1].data = {
                        labels: [],
                        datasets: [
                            {
                                backgroundColor: [],
                                data: [],
                            }
                        ]
                    };
                } else {
                    const responseComplexities = await requestComplexities.json();
                    this.stats[1].row[0].column[1].data = responseComplexities.data
                }

                if (!requestSources.ok) {
                    this.stats[1].row[0].column[2].data = {
                        labels: [],
                        datasets: [
                            {
                                backgroundColor: [],
                                data: [],
                            }
                        ]
                    };
                } else {
                    const responseSources = await requestSources.json();
                    this.stats[1].row[0].column[2].data = responseSources.data
                }

                if (!requestGroups.ok) {
                    this.stats[2].row[0].column[0].data = []
                } else {
                    const responseGroups = await requestGroups.json();
                    this.stats[2].row[0].column[0].data = responseGroups.data
                }

            } catch (error) {
                //
            } finally {
                this.loading = false;
            }
        }
    },
};