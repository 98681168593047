import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

import DeleteDialog from "@/components/online/manager/accessManagement/dialogs/DeleteDialog.vue";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        DeleteDialog,
    },

    props: {},

    watch: {
        '$route'(to) {
            this.title = to.params.id ? "" : "Ajout d'un motif";
            this.reasonInfo = null;

            if (to.name == 'reasonNew') {
                this.$refs.form.reset();
                this.isDisabled = false;
                this.isDisabledBtn = false;
            } else if (to.name == 'reasonDetail') {
                this.isDisabled = true;
                this.isDisabledBtn = true;
                this.retrieveSingle();
            }
        },
        doesHaveParent(val) {
            if (val) this.retrieveReasons();
            else this.selectParent = null;
        },
        "$i18n.locale": function() {
            this.rules = { required: v => !!v || this.$t('form.validation.required') }
        }
    },

    data() {
        return {
            loading: false,
            isDisabled: false,
            isDisabledBtn: false,
            loadingDelete: false,
            loadingMotifs: false,

            dialogDelete: false,
            doesHaveParent: false,

            reasonInfo: null,
            title: this.$route.params.id ? "" : "Ajout d'un motif",

            label: null,
            selectParent: null,
            searchParent: null,
            availableMotifs: [],

            rules: { required: v => !!v || this.$t('form.validation.required') }
        };
    },

    created() {
        if(!authTokenService.getToken()) return;

        if (!this.$route.params.id) {
            this.isDisabledBtn = false;
        }

        if (this.$route.params.id) {
            this.isDisabled = true;
            this.isDisabledBtn = true;
            this.retrieveSingle();
        }
    },

    mounted() {

    },

    methods: {
        goBack() {
            this.$router.push({ name: 'reasons', replace: true });
        },

        enableEdition() {
            this.isDisabled = !this.isDisabled;
            this.isDisabledBtn = !this.isDisabledBtn;
        },

        titleString(item) {
            return `${item.id} - ${item.label}`
        },

        async retrieveSingle() {
            this.loading = true;
            try {
                const request = await apiService.get(`/motifs/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.reasonInfo = response.data.label;
                this.label = response.data.label;

                if (response.data.parent != null) {
                    this.selectParent = response.data.parent;
                    this.doesHaveParent = true;
                }
            } catch (error) {
                this.$router.push({ name: "reasons", replace: true });
            } finally {
                this.loading = false;
            }
        },

        async retrieveReasons() {
            this.loadingMotifs = true;
            try {
                const request = await apiService.get(`/backoffice/motifs`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableMotifs = response.data.filter(el => el.id != this.$route.params.id);
            } catch (error) {
                this.availableMotifs = []
            } finally {
                this.loadingMotifs = false;
            }
        },

        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');

                let dataSend = { label: this.label };

                if (this.$route.params.id && this.doesHaveParent && this.selectParent != null) {
                    dataSend.parentID = this.selectParent.id;
                }

                const url = this.$route.params.id ? `/motifs/${this.$route.params.id}` : `/motifs`;
                const request = await apiService.post(url, dataSend);
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$router.push({ name: 'reasonDetail', params: { id: response.infos.id }, replace: true })
                } else {
                    this.reasonInfo = "";
                    this.retrieveSingle();
                }
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
                if (this.$route.params.id) {
                    this.isDisabledBtn = true;
                    this.isDisabled = true;
                } else {
                    this.isDisabledBtn = false;
                }
            }
        },

        async deleteHandler() {
            this.loadingDelete = true;
            try {
                const request = await apiService.delete(`/motifs/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.$router.push({ name: "reasons", replace: true });
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};