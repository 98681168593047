<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      :color="isError ? 'red' : 'green'"
      :timeout="timeout"
      location="bottom right"
    >
      <span class="font-weight-medium">
        {{ $t(`error["${text}"]`) }}
      </span>

      <template v-slot:actions>
        <v-btn color="white" variant="outlined" @click="snackbar = false">
          {{ $t('form.btn["Fermer"]') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>


<script src="./js/snackbarNotification.js"></script>