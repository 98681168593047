import ReasonsList from "@/components/online/admin/reasons/list/ReasonsList.vue";
import ReasonForm from "@/components/online/admin/reasons/form/ReasonForm.vue";
import SourcesList from "@/components/online/admin/sources/list/SourcesList.vue";
import SourceForm from "@/components/online/admin/sources/form/SourceForm.vue";
import GradeList from "@/components/online/admin/grades/list/GradeList.vue";
import GradeForm from "@/components/online/admin/grades/form/GradeForm.vue";
import TagList from "@/components/online/admin/tags/list/TagList.vue";
import TagForm from "@/components/online/admin/tags/form/TagForm.vue";
import TemplateIntegrationList from "@/components/online/admin/integration/list/TemplateIntegrationList.vue";
import SettingAppsDrawer from "@/components/online/admin/drawer/SettingAppsDrawer.vue";
import TicketRowColorsSettingForm from "@/components/online/manager/ticketsColors/form/TicketRowColorsSettingForm.vue";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        SettingAppsDrawer,
        ReasonsList,
        ReasonForm,
        SourcesList,
        SourceForm,
        TagList,
        TagForm,
        GradeList,
        GradeForm,
        TicketRowColorsSettingForm,
        TemplateIntegrationList
    },

    props: {},

    watch: {
        '$route'(to) {
            this.setCurrentView(to.meta)
        },
    },

    data() {
        return {
            displayType: 'reasonsList',
            refreshReasons: false,
            refreshSources: false,
            refreshTags: false,
            refreshGrades: false,
            refreshIntegration: false,
        };
    },

    created() {
        if (!authTokenService.getToken()) return;
        this.setCurrentView(this.$route.meta)
    },

    mounted() { },

    methods: {
        setCurrentView({ displayType }) {
            this.displayType = displayType
        },

        stopRefresh() {
            this.refreshReasons = false;
            this.refreshSources = false;
            this.refreshTags = false;
            this.refreshGrades = false;
            this.refreshIntegration = false;
        },

        refreshList(routeName) {
            switch (routeName) {
                case "reasons":
                    this.refreshReasons = true;
                    break;
                case "sources":
                    this.refreshSources = true;
                    break;
                case "tags":
                    this.refreshTags = true;
                    break;
                case "grades":
                    this.refreshGrades = true;
                    break;
                case "integrations":
                    this.refreshIntegration = true;
                    break;
                default:
                    break;
            }
        }
    },
};