<template>
  <div class="py-lg-6 px-lg-8 py-md-4 px-md-5 pa-4">
    <v-row>
      <v-col cols="5" class="d-flex align-center">
        <h2 class="font-weight-regular mr-2">
          {{ $t('admin["Les temps de traitement"]') }}
        </h2>
      </v-col>
      <v-col>
        <div class="d-flex justify-lg-end">
          <v-btn
            size="small"
            class="mx-2 text-caption"
            color="teal-darken-1"
            v-for="(btn, i) in btns"
            :key="i"
            @click="actionHandler(btn.type, btn.value)"
          >
            <v-icon size="18" v-if="btn.icon != ''" class="mr-1">
              {{ btn.icon }}
            </v-icon>
            {{ $t(`admin["${btn.label}"]`) }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" lg="6" md="7" class="d-flex align-center">
        <v-progress-circular
          v-if="loadingProcessing"
          :size="20"
          :width="4"
          color="grey"
          indeterminate
        ></v-progress-circular>

        <div v-if="!loadingProcessing" class="d-flex align-center">
          <div style="width: 250px">
            <v-text-field
              v-model="organizationProcessing"
              :label="$t(`form.label[&quot;Temps de traitement général&quot;]`)"
              variant="outlined"
              density="compact"
              color="teal-darken-2"
              hide-details=""
              type="numbers"
              min="1"
              :disabled="!enableEdit"
            ></v-text-field>
            <p class="text-caption text-grey">
              *{{ $t('admin["Temps de traitement en jours"]') }}
            </p>
          </div>

          <v-icon
            size="25"
            color="teal-darken-1"
            class="ml-3 mb-5"
            @click="updateProcessingTime"
          >
            {{ enableEdit ? "mdi-content-save" : "mdi-pencil" }}
          </v-icon>
          <v-icon
            v-if="enableEdit"
            size="25"
            color="red"
            class="ml-3 mb-5"
            @click="enableEdit = false"
          >
            mdi-close
          </v-icon>
        </div>
      </v-col>
    </v-row>

    <v-divider class="mt-5"></v-divider>
    <v-row class="mt-2">
      <v-col cols="12" lg="6" md="7">
        <v-text-field
          v-model="search"
          :placeholder="
            $t(`admin[&quot;Rechercher un temps de traitement&quot;]`)
          "
          :hint="
            $t(
              `admin[&quot;Rechercher un temps de traitement à partir des mots clés&quot;]`
            )
          "
          variant="underlined"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          color="teal-darken-2"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-if="!loading"
      :search="search"
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.processingTime`]="{ item }">
        {{ `${item.raw.processingTime} ${this.$t('admin["jours"]')}` }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon size="large" @click="editItem(item.raw)"> mdi-eye </v-icon>
      </template>
    </v-data-table>
    <div v-else class="d-flex justify-center align-center" style="height: 40vh">
      <v-progress-circular
        :size="45"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script src="./js/processingTimeList.js"></script>