export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        isDisabled: {
            default: false,
        },
        permissions: {
            default: () => {
                return [
                    { label: 'Consulter', value: true },
                    { label: 'Modifier', value: true },
                    { label: 'Fermer', value: true },
                    { label: 'Transférer', value: true },
                ];
            }
        }
    },

    watch: {
        "$i18n.locale": function () {
            this.permissionsEdit[0].label = this.$t('manager.userManagement["Consulter"]')
            this.permissionsEdit[1].label = this.$t('manager.userManagement["Modifier"]')
            this.permissionsEdit[2].label = this.$t('manager.userManagement["Fermer"]')
            this.permissionsEdit[3].label = this.$t('manager.userManagement["Transférer"]')
        },
        dialog(val) {
            if (val) {
                this.permissionsEdit = this.permissions;
            }
        }
    },

    data() {
        return {
            cardWidth: 600,
            permissionsEdit: [
                { label: 'Consulter', value: true },
                { label: 'Modifier', value: true },
                { label: 'Fermer', value: true },
                { label: 'Transférer', value: true },
            ],
        };
    },

    created() {
        this.setCardWidth()
        this.permissionsEdit = this.permissions;
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.$emit('update:dialog', false)
        },

        actionHandler() {
            this.$emit("updateField", { fieldName: "permissions", value: this.permissionsEdit })
            this.$emit('update:dialog', false)
        }
    },
};