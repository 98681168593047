import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    components: {},

    props: {
        refreshList: {
            defaul: false,
        }
    },

    watch: {
        search(val) {
            if (val != null && val.trim() != "") {
                this.$router.push({ name: this.$route.name, query: { search: val } })
            } else {
                this.$router.push({ name: this.$route.name })
            }
        },
        refreshList(val) {
            if (val) {
                this.retrieveData();
            }
        },
        "$i18n.locale": function () {
            this.headers = [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: this.$t('datatable.header["Statut"]'), key: 'status' },
                { title: this.$t('datatable.header["Libellé"]'), key: 'name' },
                { title: this.$t('datatable.header["Temps de traitement"]'), key: 'processingTime' },
                { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ]
            this.btns = [
                { label: this.$t('admin["Nouveau temps de traitement"]'), icon: 'mdi-plus', type: 'route', value: 'processingNew' },
            ]
            this.applicationCases = [
                { value: 0, label: this.$t('admin["Ticket plus vieux que"]') },
                { value: 1, label: this.$t('admin["Changement de statut du ticket plus ancien que"]'), },
                { value: 2, label: this.$t('admin["Ticket avec une échéance dans moins de"]'), },
                { value: 3, label: this.$t('admin["Ticket en retard de plus de"]'), },
            ];
        }
    },

    data() {
        return {
            loading: false,
            items: [],
            search: this.$route.query.search ?? null,

            headers: [
                {
                    title: 'ID',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                { title: this.$t('datatable.header["Statut"]'), key: 'status' },
                { title: this.$t('datatable.header["Libellé"]'), key: 'name' },
                { title: this.$t('datatable.header["Temps de traitement"]'), key: 'processingTime' },
                { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
            ],
            btns: [
                { label: this.$t('admin["Nouveau temps de traitement"]'), icon: 'mdi-plus', type: 'route', value: 'processingNew' },
            ],

            applicationCases: [
                { value: 0, label: this.$t('admin["Ticket plus vieux que"]') },
                { value: 1, label: this.$t('admin["Changement de statut du ticket plus ancien que"]'), },
                { value: 2, label: this.$t('admin["Ticket avec une échéance dans moins de"]'), },
                { value: 3, label: this.$t('admin["Ticket en retard de plus de"]'), },
            ],
            loadingProcessing: true,
            enableEdit: false,
            organizationProcessing: null
        };
    },

    created() {
        if (!authTokenService.getToken()) return;
        this.retrieveData();
        this.retrieveOrganization();
    },

    mounted() { },

    methods: {
        actionHandler(type, value) {
            switch (type) {
                case "route":
                    if (value == '') return;
                    this.$router.push({ name: value })
                    break;
                case "import":
                    this.dialogImport = value
                    break;
                default:
                    break;
            }
        },

        editItem(item) {
            this.$router.push({ name: "processingDetail", params: { id: item.id } })
        },

        convertToTimeToString(value) {
            // Calculate the number of days
            const days = Math.floor(value / 1440);
            // Calculate the number of hours
            const hours = Math.floor((value % 1440) / 60);
            // Calculate the number of minutes
            const remainingMinutes = value % 60;
            return `${days} ${this.$t('admin["jours"]')}, ${hours} ${this.$t('admin["heures"]')}, ${remainingMinutes} ${this.$t('admin["minutes"]')}`;
        },


        async updateProcessingTime() {
            if (this.enableEdit) {
                try {
                    if (this.organizationProcessing == null) throw new Error('Temps de traitement invalide');
                    this.loadingProcessing = true;
                    const request = await apiService.post(`/organizations/processing-times`, {
                        processingTime: this.organizationProcessing
                    });
                    const response = await request.json();
                    if (!request.ok) throw new Error(response.message);
                    eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                    this.enableEdit = false;
                } catch (error) {
                    eventBus.$emit(OPEN_SNACKBAR, {
                        message: error.message ?? "Veuillez réessayer ultérieurement",
                        isError: true,
                    })
                } finally {
                    this.loadingProcessing = false
                }
            } else {
                this.enableEdit = true
            }
        },

        async retrieveOrganization() {
            try {
                const request = await apiService.get(`/organizations`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.organizationProcessing = response.data.processingTime;
            } catch (error) {
                this.organizationProcessing = null
            } finally {
                this.loadingProcessing = false
            }
        },

        async retrieveData() {
            this.loading = true;
            try {
                const request = await apiService.get(`/processing-times`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                response.data.forEach(el => {
                    el.applicationCase = this.applicationCases.find(el => el.value == el.applicationCase)
                })
                this.items = response.data;
                this.$emit('stopRefresh');
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
    },
};