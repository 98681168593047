<template>
  <div>
    <div class="d-flex">
      <v-row no-gutters>
        <v-col
          cols="8"
          class="d-flex"
          :style="node.children.length > 0 ? 'font-weight: bold' : ''"
        >
          <v-row no-gutters>
            <v-col cols="" md="3" lg="3" class="d-flex justify-lg-end pr-lg-5">
              <p>{{ node.id }}</p>
            </v-col>

            <v-col cols="" class="d-flex">
              <p>{{ node.label }}</p>
              <v-icon
                v-if="node.children.length > 0"
                size="large"
                @click="clickHandler"
              >
                {{
                  currentNode.showChildren
                    ? "mdi-chevron-down"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-col>
            <v-col cols="">
              <p class="pr-4">{{ node.createdAt }}</p>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="1" class="d-flex align-end">
          <v-icon size="large" class="" @click="editItem(node)">
            mdi-eye
          </v-icon>
        </v-col>
      </v-row>
    </div>

    <div></div>

    <div
      v-if="node.children && node.children.length && currentNode.showChildren"
    >
      <GroupeNodeTree
        v-for="(child, index) in node.children"
        :key="index"
        :node="child"
        :v-show="currentNode.showChildren"
      ></GroupeNodeTree>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupeNodeTree",
  props: {
    node: Object,
    show: Boolean,
  },
  data() {
    return {
      currentNode: { showChildren: false },
    };
  },
  created() {
    this.currentNode = this.node;
  },
  methods: {
    clickHandler() {
      this.currentNode.showChildren = !this.currentNode.showChildren;
    },
    editItem(item) {
      this.$router.push({
        name: "groupsManagementDetail",
        params: { id: item.id },
      });
    },
  },
};
</script>
