import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    setup() {
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {
        "$i18n.locale": function () {
            this.rules = { required: v => !!v || this.$t('form.validation.required'), }
        }
    },

    data() {
        return {
            loading: false,
            cardWidth: 600,
            importFile: null,
            rules: { required: v => !!v || this.$t('form.validation.required'), }
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.$emit('update:dialog', false)
        },


        async actionHandler() {
            this.loading = true
            try {
                const {valid} = await this.$refs.form.validate();
                if(!valid) throw new Error("Le fichier d'import est manquant");

                let dataSend = new FormData();
                dataSend.append('importFile', this.importFile[0]);
                
                const request = await apiService.postFile(`/import?action=userJobs`, dataSend);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                await this.$refs.form.reset();
                this.$emit("refresh")
                this.$emit('update:dialog', false)
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },
    },
};