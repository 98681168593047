import { useTicketListConfigStore } from "@/stores/TicketListConfigStore";

export default {
    setup() {
        const ticketListConfigStore = useTicketListConfigStore();
        return { ticketListConfigStore };
    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {},

    data() {
        return {
            cardWidth: 500,
            columns: this.ticketListConfigStore.ticketListConfig
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 500;
        },

        toggleShow(item) {
            item.show = !item.show;
        },

        close() {
            this.ticketListConfigStore.ticketListConfig.forEach(element => {
                element.show = true;
            })
            this.$emit('update:dialog', false)
        },

        actionHandler() {
            this.$emit("confimUpdateTicketColumns", this.columns)
            this.$emit('update:dialog', false)
        }
    },
};