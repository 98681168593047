import LoadingComponent from "@/components/general/loading/LoadingComponent.vue"

export default {
    components: {
        LoadingComponent,
    },

    props: {},

    watch: {},
    
    data() {
        return {};
    },

    created() { },

    mounted() { },

    methods: {},
};