<template>
  <div>
    <div class="d-flex">
      <v-row no-gutters>
        <v-col
          cols="8"
          class="d-flex"
          :style="node.children.length > 0 ? 'font-weight: bold' : ''"
        >
          <v-row no-gutters>
            <v-col cols="" md="3" lg="3" class="d-flex justify-lg-end pr-lg-5">
              <p>{{ node.id }}</p>
            </v-col>
            <v-col cols="" class="d-flex">
              <p>{{ node.label }}</p>
              <v-icon
                v-if="node.children.length > 0"
                size="large"
                @click="clickHandler"
              >
                {{
                  currentNode.showChildren
                    ? "mdi-chevron-down"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-col>
            <v-col cols="">
              <p class="pr-4">{{ node.createdAt }}</p>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="1"
          class="d-flex align-end"
          v-if="node.children.length == 0"
        >
          <v-btn
          v-if="node.isForwardable == 1"
            rounded=""
            size="small"
            class="text-caption px-10 py-0 my-0"
            color="teal"
            @click="forwardItem(node)"
          >
            {{ $t('text["Transférer"]') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    
    <div
      v-if="node.children && node.children.length && currentNode.showChildren"
    >
      <GroupeNodeTree
        v-for="(child, index) in node.children"
        :key="index"
        :node="child"
        :v-show="currentNode.showChildren"
      ></GroupeNodeTree>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
export default {
  name: "GroupeNodeTree",
  props: {
    node: Object,
    show: Boolean,
  },
  data() {
    return {
      currentNode: { showChildren: false },
    };
  },
  created() {
    this.currentNode = this.node;
  },
  methods: {
    clickHandler() {
      this.currentNode.showChildren = !this.currentNode.showChildren;
    },
    forwardItem(item) {
      eventBus.$emit("forwardItem", item);
    },
  },
};
</script>
