<template>
  <div>
    <SettingAppsDrawer @refreshList="refreshList" />

    <ReasonsList
      v-if="displayType == 'reasonsList'"
      :refreshList="refreshReasons"
      @stopRefresh="stopRefresh"
    />

    <ReasonForm v-if="displayType == 'reasonForm'" />

    <SourcesList
      v-if="displayType == 'sourcesList'"
      :refreshList="refreshSources"
      @stopRefresh="stopRefresh"
    />

    <SourceForm v-if="displayType == 'sourceForm'" />

    <TagList
      v-if="displayType == 'tagList'"
      :refreshList="refreshTags"
      @stopRefresh="stopRefresh"
    />

    <TagForm v-if="displayType == 'tagForm'" />

    <GradeList
      v-if="displayType == 'gradeList'"
      :refreshList="refreshGrades"
      @stopRefresh="stopRefresh"
    />

    <GradeForm v-if="displayType == 'gradeForm'" />

    <TicketRowColorsSettingForm v-if="displayType == 'ticketColors'" />

    <TemplateIntegrationList
      v-if="displayType == 'integrationList'"
      :refreshList="refreshIntegration"
      @stopRefresh="stopRefresh"
    />
  </div>
</template>

<script src="./js/settingAppsView.js"></script>