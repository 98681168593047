import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

import DeleteDialog from "@/components/online/manager/accessManagement/dialogs/DeleteDialog.vue";
import LoadingComponent from "@/components/general/loading/LoadingComponent.vue"

import TicketTemplateFieldDialog from "@/components/online/admin/dialogs/TicketTemplateFieldDialog.vue"
import TicketTemplateGroupDialog from "@/components/online/admin/dialogs/TicketTemplateGroupDialog.vue"
import TicketTemplateHideShowDialog from "@/components/online/admin/dialogs/TicketTemplateHideShowDialog.vue"
import TicketTemplateFieldOrderDialog from "@/components/online/admin/dialogs/TicketTemplateFieldOrderDialog.vue"
import authTokenService from "@/services/authTokenService";

export default {
    components: {
        DeleteDialog,
        LoadingComponent,
        TicketTemplateFieldDialog,
        TicketTemplateGroupDialog,
        TicketTemplateHideShowDialog,
        TicketTemplateFieldOrderDialog
    },

    props: {

    },

    watch: {
        "$i18n.locale": function() {
            this.title = this.$route.params.id ? "" : this.$t('admin["Ajout d\'un template"]');
            this.rules = { required: v => !!v || this.$t("form.validation.required"), }
        },
        '$route'(to) {
            this.title = to.params.id ? "" : this.$t('admin["Ajout d\'un template"]');
            this.frontInfo = null;

            if (to.name == 'templatesDetail') {
                this.isDisabled = true;
                this.isDisabledBtn = true;
                this.retrieveSingle();
            }
        },
    },

    data() {
        return {
            loading: false,
            isDisabled: true,
            isDisabledBtn: false,
            loadingDelete: false,

            dialogDelete: false,
            dialogDetail: false,
            dialogGroup: false,
            dialogShowHide: false,
            dialogFieldOrder: false,

            title: this.$route.params.id ? "" : this.$t('admin["Ajout d\'un template"]'),
            frontInfo: null,

            label: null,
            group: null,
            demandeForm: [],
            annexeForm: [],

            selectedField: null,
            selectedFieldIndex: null,
            rules: { required: v => !!v || this.$t("form.validation.required"), }
        };
    },

    created() {
        if(!authTokenService.getToken()) return;

        if (!this.$route.params.id) {
            return this.$router.push({ name: 'templates', replace: true });
        }

        if (this.$route.params.id) {
            this.isDisabled = true;
            this.isDisabledBtn = true;
            this.retrieveSingle();
        }
    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.push({ name: 'templates', replace: true });
        },

        enableEdition() {
            this.isDisabledBtn = !this.isDisabledBtn;
        },

        showDetail(field, index) {
            this.selectedField      = field;
            this.selectedFieldIndex = index;
            this.dialogDetail       = true;
        },

        updateOneField(field) {
            this.demandeForm[this.selectedFieldIndex] = field;
        },

        async updateField({ demandeFormEdit, annexeFormEdit }) {
            this.demandeForm = demandeFormEdit;
            this.annexeForm = annexeFormEdit;
            await this.submitHandler()
            this.isDisabledBtn = false;
        },

        async cancel() {
            if (!this.isDisabledBtn) {
                await this.retrieveSingle();
                this.isDisabledBtn = false;
            }
        },

        async retrieveSingle() {
            this.loading = true;
            try {
                const request = await apiService.get(`/form-templates/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.frontInfo = response.data.label;
                this.label = response.data.label;
                this.group = response.data.group;
                this.demandeForm = response.data.structures;
                this.annexeForm = response.data.annexes;
            } catch (error) {
                this.$router.push({ name: "templates", replace: true });
            } finally {
                this.loading = false;
            }
        },

        async submitHandler() {
            this.loading = true;
            try {
                const url = `/form-templates/${this.$route.params.id}`;
                const request = await apiService.post(url, {
                    label: this.label,
                    group: this.group.id,
                    structures: JSON.stringify(this.demandeForm),
                    annexesStructure: JSON.stringify(this.annexeForm),
                });
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                this.frontInfo = "";
                this.retrieveSingle();
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
                if (this.$route.params.id) {
                    this.isDisabledBtn = true;
                    this.isDisabled = true;
                } else {
                    this.isDisabledBtn = false;
                }
            }
        },

        async deleteHandler() {
            this.loadingDelete = true;
            try {
                const request = await apiService.delete(`/form-templates/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.$router.push({ name: "templates", replace: true });
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};