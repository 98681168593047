<template>
  <div class="variant_width_height d-flex justify-center align-center">
    <v-card class="pa-5" width="400">
      <h2 class="text-center mb-2">{{ $t('confirmAccount.title') }}</h2>
      <p class="text-center text-caption mb-4">
        {{ $t('confirmAccount.description') }}
      </p>
      <v-divider class="mb-5"></v-divider>
      <ConfirmAccountForm />
      <div class="d-flex justify-center mt-4">
        <v-btn
          variant="text"
          size="small"
          color="grey"
          @click="goToLogin"
          >{{ $t('form.btn["Se connecter"]') }}</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script src="./js/confirmAccountView.js"></script>