import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { API_URL } from "@/utils/contants";

import ContactBooksGroup from "@/components/online/admin/contactBooks/ContactBooksGroup.vue"
import eventBus from "@/eventBus";

export default {
    setup() {

    },

    components: {
        ContactBooksGroup
    },

    props: {},

    watch: {
        '$route'() {
            this.retrieveData();
        },
        search(val) {
            let queries = {}
            this.tagFilters.forEach(element => {
                if (element.type == 'role') queries.role = element.label
                if (element.type == 'group') queries.group = element.id
                if (element.type == 'userJob') queries.userJob = element.id
            });

            if (val == null || val == "") {
                this.$router.push({ name: this.$route.name, query: { ...queries }, replace: true })
            } else {
                this.$router.push({ name: this.$route.name, query: { search: val, ...queries }, replace: true })
            }
        },
    },

    data() {
        return {
            cardWidth: 300,
            loading: true,
            search: this.$route.query.search ?? null,
            infos: [],
            tagFilters: []
        };
    },

    created() {
        if (!authTokenService.getToken()) return;
        this.retrieveData();
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });

        eventBus.$on('setTagFilter', ({ type, item }) => {
            this.setTagFilter(type, item)
        })

        if (localStorage.getItem('tagFilters')) {
            this.tagFilters = JSON.parse(localStorage.getItem("tagFilters"));
            this.setTagQueries();
        }
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = '60%';
            else this.cardWidth = 300;
        },

        getFilePath(file, isRemote = false) {
            if (file == null && isRemote) return `${API_URL}/images/default.jpg`
            if (file != null && isRemote) return `${API_URL}/images/${file}`
            return `${location.origin}/images/${file}`
        },

        removeTag(index) {
            this.tagFilters.splice(index, 1)
            localStorage.setItem('tagFilters', JSON.stringify(this.tagFilters))
            this.setTagQueries();
        },

        setTagFilter(type, item) {
            const typeExist = this.tagFilters.findIndex(el => el.type == type);

            if (typeExist != -1) {
                this.tagFilters[typeExist] = {
                    id: item.id,
                    label: item.label,
                    type: type
                }
            } else {
                this.tagFilters.push({
                    id: item.id,
                    label: item.label,
                    type: type
                })
            }

            localStorage.setItem('tagFilters', JSON.stringify(this.tagFilters))
            this.setTagQueries();
        },

        setTagQueries() {
            let queries = {}
            this.tagFilters.forEach(element => {
                if (element.type == 'role') queries.role = element.label
                if (element.type == 'group') queries.group = element.id
                if (element.type == 'userJob') queries.userJob = element.id
            });

            if (this.search != null) {
                this.$router.push({ name: this.$route.name, query: { search: this.search, ...queries }, replace: true })
            } else {
                this.$router.push({ name: this.$route.name, query: { ...queries }, replace: true })
            }
        },

        async retrieveData() {
            this.loading = true;
            try {
                let urlQueries = [];
                let query = this.$route.query
                for (const [key, value] of Object.entries(query)) {
                    urlQueries.push(`${key}=${value}`)
                }

                const url = `/contact-books?${urlQueries.join("&")}`;
                const request = await apiService.get(url);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.infos = response.data;

            } catch (error) {
                this.infos = []
            } finally {
                this.loading = false;
            }
        },
    },
};