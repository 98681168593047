import zxcvbn from "zxcvbn";

export default {
    props: {
        value: {
            type: String,
            required: true,
        },
    },

    components: {},

    watch: {
        value(val) {
            this.checkPasswordStrength(val)
        }
    },

    data() {
        return {
            currentScore: 0,
            defaultScore: { color: '', label: '', width: 0 }
        }
    },

    created() { },

    mounted() {

    },

    methods: {
        checkPasswordStrength(val) {
            let passwordInfo = zxcvbn(val);
            let score = passwordInfo.score;

            const result = {
                0: { color: 'red', label: 'Au moins 5 caractères et 1 chiffre', width: 20 },
                1: { color: 'yellow-lighten-1', label: 'Au moins 8 caractères, 2 chiffres, 1 majuscule', width: 40 },
                2: { color: 'orange-lighten-2', label: 'Au moins 10 caractères, 4 chiffres, 3 majuscules', width: 50 },
                3: { color: 'light-green-lighten-1', label: 'Au moins 12 caractères, des chiffres, des majuscules, des caractères spéciaux', width: 60 },
                4: { color: 'green-darken-2', label: 'Parfait', width: 100 },
            }

            this.defaultScore = result[score];
            this.currentScore = score;
            this.$emit("passwordScore", {score})
        }
    }
}