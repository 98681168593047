<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="pt-1">{{ title }}</h2>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="35" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>
      <div class="pa-5">
        <p class="text-center">{{ text }}</p>
        <p class="text-center">{{  $t('text["Cette action n\'est pas réversible"]')}}</p>
      </div>
      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="grey"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="red"
          variant="flat"
          :loading="loading"
          @click="actionHandler"
        >
            {{ $t('form.btn["Supprimer"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script src="./js/deleteDialog.js"></script>