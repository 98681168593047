<template>
  <div>
    <h5 class="mt-1 ml-1 mb-4">
      {{ title == null ? "--" : $t(`stats["${title}"]`) }}
    </h5>

    <div style="height: 250px">
      <Bar :data="dataChartEl" :options="options" />
    </div>
  </div>
</template>

<script src="./js/verticalBarChart.js"></script>