<template>
  <div style="position: relative; height: 320px">
    <h5 class="mt-1 ml-1 mb-4">
      {{ title == null ? "--" : $t(`stats["${title}"]`) }}
    </h5>

    <div v-if="!options.plugins.legend.display">
      <v-row
        class="ml-1"
        no-gutters
        v-for="(color, index) in dataChartEl.datasets[0].backgroundColor"
        :key="index"
      >
        <v-col class="d-flex justify-start align-center">
          <div
            :style="`background-color: ${color}; width: 20px; height: 20px;`"
            class="mr-1"
          ></div>
          <p>{{ dataChartEl.labels[index] }}</p>
        </v-col>
      </v-row>
    </div>

    <div
      style="
        height: 250px;
        position: absolute;
        top: 50%;
        left: 55%;
        transform: translate(-55%, -50%);
        z-index: 1000;
      "
    >
      <Pie v-if="!reload" :data="dataChartEl" :options="options" />
    </div>
  </div>
</template>

<script src="./js/pieCharts.js"></script>