import AccessManagementView from "@/views/online/admin/AccessManagementView.vue"

const accessManagementRoutes = [
    {
        path: '/management/users',
        name: 'usersManagement',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'userAccessList', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/users/new',
        name: 'usersManagementNew',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'userAccessForm', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/users/:id(\\d+)',
        name: 'usersManagementDetail',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'userAccessForm', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/groups/aggregated',
        name: 'groupsManagementAggregated',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'groupAccessListAggregated', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/groups',
        name: 'groupsManagement',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'groupAccessList', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/groups/new',
        name: 'groupsManagementNew',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'groupAccessForm', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/groups/:id(\\d+)',
        name: 'groupsManagementDetail',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'groupAccessForm', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/jobs',
        name: 'jobsManagement',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'jobAccessList', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/jobs/new',
        name: 'jobsManagementNew',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'jobAccessForm', roles: ['manager', 'admin'] }
    },
    {
        path: '/management/jobs/:id(\\d+)',
        name: 'jobsManagementDetail',
        component: AccessManagementView,
        meta: { requiredAuth: true, displayType: 'jobAccessForm', roles: ['manager', 'admin'] }
    },
];

export default accessManagementRoutes;