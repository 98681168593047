<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">
              {{ $t('dialog["Gestion des motifs"]') }}
            </h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div class="px-5 mb-5">
        <v-text-field
          v-model="search"
          variant="outlined"
          density="compact"
          hide-details=""
          prepend-inner-icon="mdi-magnify"
          clearable
        ></v-text-field>
      </div>
      <div class="px-5 d-flex flex-wrap">
        <v-card-text style="height: 300px; overflow: scroll">
          <div v-if="!loading">
            <v-row no-gutters v-if="availableMotifs.length > 0">
              <v-col
                v-for="(motif, index) in availableMotifs"
                :key="index"
                cols="6"
                class="pa-2"
              >
                <v-chip
                  :color="motif.selected ? 'teal-darken-1' : ''"
                  @click="selectMotif(motif)"
                >
                  <v-icon v-if="motif.selected" size="15" class="mr-1"
                    >mdi-check</v-icon
                  >
                  {{ motif.label }}
                </v-chip>
              </v-col>
            </v-row>
            <div
              v-if="availableMotifs.length == 0"
              class="d-flex justify-center"
            >
              <v-btn
                color="teal-lighten-2"
                size="small"
                @click="addMotif"
                :loading="loadindAdd"
                :disabled="loadindAdd"
              >
                <v-icon class="mr-1">mdi-plus</v-icon>
                {{ $t('form.btn["Ajouter un motif"]') }}
              </v-btn>
            </div>
          </div>
          <div
            class="d-flex justify-center align-center"
            style="height: 200px"
            v-else
          >
            <v-progress-circular
              :size="50"
              :width="4"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
      </div>
      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/groupManagementMotifsDialog.js"></script>