import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    setup() {

    },

    components: {
        Pie
    },

    props: {
        title: {
            default: ''
        },
        dataChart: {
            default: () => {
                return {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: [],
                            data: [],
                        }
                    ]
                }
            }
        },
    },

    watch: {
        "$i18n.locale": function () {
            this.translateDataChart();
        }
    },

    data() {
        return {
            reload: false,
            frLabels: [],
            dataChartEl: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: [],
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            }
        };
    },

    created() {
        this.dataChartEl = this.dataChart
        this.displayLegend()
        this.translateDataChart();
        window.addEventListener("resize", this.displayLegend, { passive: true });
    },

    mounted() { },

    methods: {
        translateDataChart() {
            if (this.$i18n.locale == "en") {
                if (this.frLabels.length == 0) {
                    this.frLabels = this.dataChart.labels;
                } else {
                    this.dataChartEl.labels = this.frLabels;
                }

                let translateLabel = [];

                this.dataChart.labels.forEach(label => {
                    translateLabel.push(this.$t(`stats["${label}"]`))
                })

                this.dataChartEl = {
                    labels: translateLabel,
                    datasets: this.dataChart.datasets
                }

            } else {
                this.dataChartEl = {
                    labels: this.dataChart.labels,
                    datasets: this.dataChart.datasets
                }
            }
        },

        displayLegend() {
            this.reload = true
            if (window.innerWidth < 960) {
                this.options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                        },
                    },
                };
            } else {
                this.options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                };
            }
            this.reload = false
        },
    },
};