import TicketDrawer from "@/components/online/tickets/drawer/TicketDrawer.vue";
import TicketForm from "@/components/online/tickets/forms/TicketForm.vue";
import TicketList from "@/components/online/tickets/lists/TicketList.vue";
import TicketOpenList from "@/components/online/tickets/lists/TicketOpenList.vue";
import TicketFinishedList from "@/components/online/tickets/lists/TicketFinishedList.vue";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        TicketDrawer,
        TicketForm,
        TicketList,
        TicketOpenList,
        TicketFinishedList,
    },

    props: {},

    watch: {
        '$route'(to) {
            this.setCurrentView(to.meta)
        },
    },

    data() {
        return {
            displayType: 'list',
            refreshOpenList: false,
            refreshFinishedList: false,
            refreshAllList: false,
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.setCurrentView(this.$route.meta)
    },

    mounted() { },

    methods: {
        setCurrentView({ displayType }) {
            this.displayType = displayType
        },
        refreshList(routeName) {
            switch (routeName) {
                case "ticketsOpen":
                    this.refreshOpenList = true;
                    break;
                case "ticketsFinished":
                    this.refreshFinishedList = true;
                    break;
                case "tickets":
                    this.refreshAllList = true;
                    break;
                default:
                    break;
            }
        },
        stopRefresh() {
            this.refreshOpenList = false;
            this.refreshFinishedList = false;
            this.refreshAllList = false;
        }
    },
};