<template>
  <div style="margin-top: -5px" class="">
    <DeleteDialog
      v-model:dialog="dialogDelete"
      :title='$t(`admin["Supprimer un motif"]`)'
      :text='$t(`admin["Voulez-vous vraiment supprimer ce motif ?"]`)'
      :loading="loadingDelete"
      @confirmDelete="deleteHandler"
    />

    <v-row class="bg-brown-lighten-5 pt-2 px-4">
      <v-col class="d-flex align-center flex-wrap">
        <v-icon size="large" class="mr-4" color="teal-darken-1" @click="goBack">
          mdi-backspace
        </v-icon>

        <div v-if="reasonInfo != null">
          <v-btn
            color="red"
            class="ma-1 ml-lg-3"
            size="small"
            @click="dialogDelete = !dialogDelete"
            :disabled="loading"
          >
            <v-icon class="mr-1">mdi-delete</v-icon>
            {{ $t('form.btn["Supprimer"]') }}
          </v-btn>
        </div>
      </v-col>
      <v-col class="d-flex justify-lg-end justify-md-end align-center">
        <v-btn
          v-if="reasonInfo && !loading"
          size="small"
          color="teal-darken-1"
          @click="enableEdition"
          class="mx-5"
        >
          <div v-if="isDisabledBtn">
            <v-icon size="20" class="mr-1">mdi-pencil </v-icon>
            {{ $t('form.btn["Modifier"]') }}
          </div>
          <div v-else>
            <v-icon size="20" class="mr-1">mdi-close </v-icon>
            {{ $t('form.btn["Annuler"]') }}
          </div>
        </v-btn>
        <v-btn
          size="small"
          color="teal-darken-1"
          :loading="loading"
          :disabled="isDisabledBtn"
          @click="submitHandler"
        >
          <v-icon size="25" class="mr-2">mdi-content-save</v-icon>
          {{ $t('form.btn["Sauvegarder"]') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-3"></v-divider>
    <div class="py-lg-4 px-lg-8 py-md-4 px-md-5 pa-4">
      <v-row no-gutters>
        <v-col cols="8" lg="3" md="5" sm="7" class="d-flex align-center pr-2">
          <h2 v-if="!loading">
            {{ reasonInfo == null ? $t(`admin["${title}"]`) : reasonInfo }}
          </h2>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>

    <v-form ref="form" class="ticket__form_height">
      <div class="py-lg-1 px-lg-8 py-md-2 px-md-5 pa-4">
        <v-row no-gutters>
          <v-col cols="12" lg="10" md="10" sm="12" class="my-2">
            <h3 class="font-weight-medium text-body-1 mt-1">
              {{ $t('form.description["Motif"]') }}
            </h3>
            <p class="text-caption mb-lg-6 mb-md-8 mb-3 mt-1">
              {{ $t('form.description["Saississez les informations du motif"]') }}
            </p>

            <div class="mr-lg-4 mr-md-4">
              <v-row class="my-0" no-gutters v-if="reasonInfo != null">
                <v-col cols="12" lg="10" md="12">
                  <v-row no-gutters>
                    <v-col cols="4" class="d-flex align-center">
                      {{ $t('form.description["A-t-il un motif parent ?"]') }}
                    </v-col>
                    <v-col>
                      <v-switch
                        v-model="doesHaveParent"
                        hide-details=""
                        color="teal-darken-1"
                        :label='doesHaveParent ? $t(`form.label["Oui"]`) : $t(`form.label["Non"]`)'
                        :disabled="isDisabled"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <div v-if="doesHaveParent">
                    <label for="" class="font-weight-medium text-caption">
                      {{ $t('form.label["Nom du motif parent"]') }}
                    </label>
                    <v-autocomplete
                      v-model="selectParent"
                      v-model:search="searchParent"
                      :loading="loadingMotifs"
                      :items="availableMotifs"
                      :item-title="titleString"
                      item-value="id"
                      density="compact"
                      hide-no-data
                      hide-details
                      variant="outlined"
                      return-object
                      class="mt-2"
                      :disabled="isDisabled"
                    ></v-autocomplete>
                  </div>
                </v-col>
              </v-row>

              <v-row class="my-3" no-gutters>
                <v-col cols="12" lg="10" md="12">
                  <label for="" class="font-weight-medium text-caption">
                    {{ $t('form.label["Libellé"]') }}
                  </label>
                  <v-text-field
                    v-model="label"
                    variant="outlined"
                    density="compact"
                    hide-details=""
                    class="mt-1"
                    :rules="[rules.required]"
                    :disabled="isDisabled"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>


<script src="./js/reasonForm.js"></script>