import apiService from "@/services/apiService";
import ImportUserDialog from "@/components/online/manager/accessManagement/dialogs/ImportUserDialog.vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/AuthStore";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        const authStore = useAuthStore();
        const { role } = storeToRefs(authStore)
        return { authStore, role };
    },

    components: {
        ImportUserDialog,
    },

    props: {
        refreshList: {
            defaul: false,
        }
    },

    watch: {
        "$i18n.locale": function () {
            this.setupDatatableHeaders();
        },

        search(val) {
            if (val != null && val.trim() != "") {
                this.$router.push({ name: this.$route.name, query: { search: val } })
            } else {
                this.$router.push({ name: this.$route.name })
            }
        },
        refreshList(val) {
            if (val) {
                this.retrieveData();
            }
        },
    },

    data() {
        return {
            loading: false,
            dialogImport: false,

            search: this.$route.query.search ?? null,
            headers: [],
            users: [],

            btns: [
                { label: 'Importer', icon: 'mdi-inbox-arrow-down', type: 'import', value: true },
                { label: 'Nouvel utilisateur', icon: 'mdi-plus', type: 'route', value: 'usersManagementNew' },
            ]
        };
    },

    created() {
        if (!authTokenService.getToken()) return;
        this.setupDatatableHeaders()
        this.retrieveData();
    },

    mounted() { },

    methods: {
        actionHandler(type, value) {
            switch (type) {
                case "route":
                    if (value == '') return;
                    this.$router.push({ name: value })
                    break;
                case "import":
                    this.dialogImport = value
                    break;
                default:
                    break;
            }
        },

        setupDatatableHeaders() {
            if (this.role == "manager") {
                this.headers = [
                    {
                        title: 'ID',
                        align: 'start',
                        sortable: true,
                        key: 'id',
                    },
                    { title: this.$t('datatable.header["Noms"]'), key: 'lastname', },
                    { title: this.$t('datatable.header["Prénoms"]'), key: 'firstname' },
                    { title: this.$t('datatable.header["Adresse e-mail"]'), key: 'email' },
                    { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                    { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
                ];
            } else {
                this.headers = [
                    {
                        title: 'ID',
                        align: 'start',
                        sortable: true,
                        key: 'id',
                    },
                    { title: this.$t('datatable.header["Noms"]'), key: 'lastname', },
                    { title: this.$t('datatable.header["Prénoms"]'), key: 'firstname' },
                    { title: this.$t('datatable.header["Adresse e-mail"]'), key: 'email' },
                    { title: this.$t('datatable.header["Role"]'), key: 'role' },
                    { title: this.$t('datatable.header["Créer le"]'), key: 'createdAt' },
                    { title: this.$t('datatable.header["Actions"]'), key: 'actions', sortable: false },
                ];
            }
        },

        editItem(item) {
            this.$router.push({ name: "usersManagementDetail", params: { id: item.id } })
        },

        async retrieveData() {
            this.loading = true;
            try {
                const request = await apiService.get(`/agents`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.users = response.data;
                this.$emit('stopRefresh');
            } catch (error) {
                this.users = [];
            } finally {
                this.loading = false;
            }
        },
    },
};