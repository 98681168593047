import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";
import PasswordScore from "@/components/notifications/PasswordScore.vue";

export default {
    setup() {

    },

    components: {
        PasswordScore,
    },

    props: {},

    watch: {
        "$i18n.locale": function() {
            this.rules = { required: v => !!v || this.$t('form.validation.required') }
        }
    },

    data() {
        return {
            loading: false,
            password: null,
            passwordConfirm: null,
            showPassword: false,
            showPasswordConfirm: false,
            passwordScoreValid: false,
            rules: { required: v => !!v || this.$t('form.validation.required') }
        };
    },

    created() {

    },

    mounted() { },

    methods: {
        passwordScoreHandler({ score }) {
            if (score < 2) this.passwordScoreValid = false;
            else this.passwordScoreValid = true;
        },

        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');
                if(!this.passwordScoreValid) throw new Error('Votre mot de passe n\'est pas assez robuste')

                const request = await apiService.post("/confirmation", {
                    token: this.$route.params.token,
                    password: this.password,
                    passwordConfirm: this.passwordConfirm,
                });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                await this.$refs.form.reset();
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false });
                this.$router.push({name: "home", replace: true});
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
};