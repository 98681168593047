import PasswordResetAskForm from "@/components/offline/passwordReset/PasswordResetAskForm.vue"
import PasswordResetActionForm from "@/components/offline/passwordReset/PasswordResetActionForm.vue"
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        
    },
    
    components: {
        PasswordResetAskForm,
        PasswordResetActionForm,
    },

    props: {},

    watch: {

    },

    data() {
        return {
            displayPasswordResetAction: false,
        };
    },

    created() {
        if(authTokenService.getToken()) {
            return this.$router.push({name: 'dashboard', replace: true})
        }
    },

    mounted() { },

    methods: {
        toggleDisplayPasswordResetAction() {
            this.displayPasswordResetAction = !this.displayPasswordResetAction;
        },
        goToLogin() {
            this.$router.push({name: 'home', replace: true})
        }
    },
};