<template>
  <div class="py-lg-6 px-lg-8 py-md-4 px-md-5 pa-4">
    <TemplateIntegrationTuto v-model:dialog="dialogTuto" />

    <TemplateIntegrationDialog
      v-model:dialog="dialogNew"
      @refresh="retrieveEmbeds"
    />

    <DeleteDialog
      v-model:dialog="dialogDelete"
      :title="$t(`admin[&quot;Supprimer un code d'intégration&quot;]`)"
      :loading="loadingDelete"
      :text="
        $t(
          `admin[&quot;Voulez-vous vraiment supprimer ce code d'intégration ?&quot;]`
        )
      "
      @confirmDelete="deleteHandler"
    />

    <v-row>
      <v-col cols="5" class="d-flex align-center">
        <h2 class="font-weight-regular mr-2">
          {{ $t('admin["Tous les codes d\'intégrations"]') }}
        </h2>
        <div>
          <v-btn
            size="small"
            class="mx-2 text-caption"
            color="teal-darken-1"
            @click="dialogTuto = true"
          >
            <v-icon size="18" class="mr-1"> mdi-help-circle-outline </v-icon>
            {{ $t(`admin["Tutoriel"]`) }}
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-lg-end">
          <v-btn
            size="small"
            class="mx-2 text-caption"
            color="teal-darken-1"
            v-for="(btn, i) in btns"
            :key="i"
            @click="actionHandler(btn.type, btn.value)"
          >
            <v-icon size="18" v-if="btn.icon != ''" class="mr-1">
              {{ btn.icon }}
            </v-icon>
            {{ $t(`admin["${btn.label}"]`) }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-5 mb-3">
      <v-col cols="12" lg="6" md="7">
        <v-text-field
          v-model="search"
          :placeholder="$t(`text[&quot;Rechercher&quot;]`)"
          :hint="$t(`text[&quot;Rechercher à partir des mots clés&quot;]`)"
          variant="underlined"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          color="teal-darken-2"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-if="!loading"
      :search="search"
      :headers="headers"
      :items="templateIntegrations"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="blue"
          size="large"
          @click="actionDatatable(item.raw, 'copy')"
          class="mr-5"
        >
          mdi-content-copy
        </v-icon>
        <v-icon
          color="red"
          size="large"
          @click="actionDatatable(item.raw, 'delete')"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div v-else class="d-flex justify-center align-center" style="height: 40vh">
      <v-progress-circular
        :size="45"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script src="./js/templateIntegrationList.js"></script>