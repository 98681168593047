import jwtDecode from "jwt-decode";
import eventBus from "@/eventBus";

import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/AuthStore";
import authTokenService from "@/services/authTokenService";
import { logout } from "@/services/logoutService";

import OfflineLayout from "../OfflineLayout.vue";
import OnlineLayout from "../OnlineLayout.vue";
import LoadingLayout from "../LoadingLayout.vue";

import SnackbarNotification from "@/components/notifications/SnackbarNotification.vue";

export default {
    setup() {
        const authStore = useAuthStore();
        const { role } = storeToRefs(authStore)
        return { authStore, role };
    },
    
    components: {
        'offline-layout': OfflineLayout,
        'online-layout': OnlineLayout,
        'loading-layout': LoadingLayout,
        SnackbarNotification,
    },

    props: {},

    watch: {
        "$i18n.locale": function(val) {
            this.$vuetify.locale.current = val;
        }
    },

    data() {
        return {
            layout: 'offline-layout',
            timerAuth: null,
        };
    },

    created() {
        if(localStorage.getItem('lang')) {
            this.$i18n.locale = localStorage.getItem('lang');
            this.$vuetify.locale.current = localStorage.getItem('lang');
        }

        this.setLayout();
        eventBus.$on('updateLayout', this.setLayout)
    },

    mounted() { },

    methods: {
        setLayout(targetLayout = null) {
            if (targetLayout != null && targetLayout == "loading-connection") {
                this.layout = 'loading-layout';
                setTimeout(() => {
                    this.$router.push({name: 'dashboard', replace: true});
                    this.setLayout(null);
                }, 2000);
                return;
            }

            if (targetLayout != null && targetLayout == "loading-disconnection") {
                this.layout = 'loading-layout';
                setTimeout(() => {
                    authTokenService.clearAuth();
                    this.$router.push({path: '/', replace: true});
                    this.setLayout(null)
                }, 3000);
                return;
            }

            if (!authTokenService.getToken()) {
                this.layout = "offline-layout";
            } else {
                this.autoLogout();
                this.layout = "online-layout";
            }
        },

        async autoLogout() {
            try {
                const token = authTokenService.getToken();
                if (!token) return;

                const decoded = jwtDecode(token);
                const date = decoded.exp * 1000;
                const dateNow = Date.now();
                if (this.timerAuth != null) clearTimeout(this.timerAuth);
                this.timerAuth = setTimeout(logout, date - dateNow);
            } catch (error) {
                logout();
            }
        }
    },
};